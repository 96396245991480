<template>
  <div class="invoice-summary__card">
    <h3 class="invoice-summary__card-title">معلومات الباقة</h3>
    <div class="invoice-card__details">
      <div class="invoice-card__row">
        <div class="invoice-card__row-key">اسم الباقة</div>
        <div class="invoice-card__row-val" v-if="selectedPlan.Name">
          {{ selectedPlan.Name.ArabicValue }}
        </div>
      </div>
      <div class="invoice-card__row">
        <div class="invoice-card__row-key">السعر</div>
        <div
          class="invoice-card__row-val"
          v-if="selectedPlan.BillingCycleOptions.length == 0"
        >
          {{
            selectedPlan.MonthlyPrice != 0
              ? selectedPlan.MonthlyPrice * 12
              : "مجانية"
          }}
          {{
            selectedPlan.MonthlyPrice != 0
              ? getCurrency(selectedPlan.Currency)
              : ""
          }}
        </div>
        <template v-else>
          <div class="flex-col" style="width: 30%; min-width: 15rem">
            <div
              class="invoice-card__row-val my-1 flex-between"
              v-for="option in selectedPlan.BillingCycleOptions"
              :key="option.BillingCycleType"
            >
              <template v-if="option.BillingCycleType <= 12">
                <span style="">
                  {{ getBillingCycleType(option.BillingCycleType) }}/
                  <template v-if="option.Discount"> - </template>
                  {{ option.Discount }}%:
                </span>
                <span style="">
                  {{ option.BillingCycleType * selectedPlan.MonthlyPrice }}
                  {{ getCurrency(selectedPlan.Currency) }}
                </span>
                <!-- <span v-if="option.Discount"> {{ option.Discount }}% </span> -->
              </template>
            </div>
          </div>
        </template>
      </div>
      <div class="invoice-card__row">
        <div class="invoice-card__row-key">الباقة تجريبية</div>
        <div class="invoice-card__row-val">
          {{ selectedPlan.TrialPlan ? "نعم" : "لا" }}
        </div>
      </div>
      <!-- <template v-if="selectedPlan.HasDiscountCoupon">
        <div class="field my-2">
          <label class="checkbox-label flex">
            <div class="custom-checkbox">
              <input type="checkbox" v-model="showDiscountField" />
              <i class="far fa-check"></i>
            </div>
            <span> إضافة كوبون خصم </span>
          </label>
        </div>
        <div class="invoice-card__row" v-if="showDiscountField">
          <div class="key" style="white-space: nowrap; margin-left: 20px">
            قسيمة خصم!
          </div>
          <div class="val flex">
      <div class="loader inline-loader" v-if="loading"></div>
      <select v-model="discountCode">
        <option v-for="dc in discountCoupons" :key="dc.Id" :value="dc.Code">
          {{ dc.Code }}
        </option>
      </select>
      <button
        class="btn btn-info"
        type="button"
        :disabled="!discountCode.length"
        style="width: auto; padding: 1rem 1.5rem; font-size: 1.5rem"
        @click="$emit('applyCoupon', discountCode)"
      >
        أضف
      </button>
    </div>
  </div>
</template> -->
    </div>
    <!-- <div class="invoice-card__row total-price">
      <div class="key">المطلوب دفعه</div>
      <div class="val">
        <span class="price">{{ totalAmount }}</span>
        ر.س
      </div>
    </div> -->

    <!-- <div class="flex-center">المزايا</div> -->
    <table
      style="font-size: 1.3rem; width: calc(100% + 6rem); margin-right: -3rem"
    >
      <tbody v-if="features">
        <template v-for="feature in quotasFeatures" :key="feature.id">
          <tr>
            <td>
              {{ feature.Name.ArabicValue }}
            </td>
            <template v-if="feature.InitialQuotaPack == 0">
              <td>لا يوجد وحدات، يمكنك الإضافة</td>
            </template>
            <template v-else>
              <td>
                {{ feature.InitialQuotaPack }} وحدات متاحة - قابلة للزيادة
              </td>
            </template>
            <td>
              {{
                feature.BillingFrequency == 0
                  ? feature.QuotaPackPrice
                  : feature.QuotaPackPrice * 12
              }}
              {{ getCurrency(selectedPlan.Currency) }}
            </td>
          </tr>
        </template>
        <template v-for="feature in nonApplicationFeatures" :key="feature.id">
          <tr>
            <td>
              {{ feature.Name.ArabicValue }}
            </td>
            <td>
              <i class="fas fa-check-circle c-success mx-1"></i
              >{{ feature.InitialQuotaPack == 0 ? "خيارية" : "متوفرة" }}
            </td>
            <td v-if="feature.InitialQuotaPack == 0">
              {{
                feature.BillingFrequency == 0
                  ? feature.QuotaPackPrice
                  : feature.QuotaPackPrice * 12
              }}
              {{ getCurrency(selectedPlan.Currency) }}
            </td>
            <td v-else></td>
          </tr>
        </template>
        <template v-for="feature in moduleFeatures" :key="feature.id">
          <tr v-if="feature.InitialQuotaPack == 0">
            <td>
              {{ feature.Name.ArabicValue }}
            </td>
            <td>
              <i class="fas fa-check-circle c-success mx-1"></i>
              خيارية
            </td>
            <td>
              {{
                feature.BillingFrequency == 0
                  ? feature.QuotaPackPrice
                  : feature.QuotaPackPrice * 12
              }}
              {{ getCurrency(selectedPlan.Currency) }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div class="loader loader-page-layout" v-if="loading"></div>
</template>

<script>
import { Currencies } from "@/util/utilities";
import SubscriptionService from "@/services/SubscriptionService";
export default {
  props: {
    selectedPlan: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      features: null,
      nonApplicationFeatures: null,
      quotasFeatures: null,
      moduleFeatures: null,
      discountCode: "",
      showDiscountField: false,
      discountCoupons: null,
      loading: false,
    };
  },
  watch: {
    selectedPlan(to) {
      this.setFeatures(to.PlanFeatures);
    },
  },
  mounted() {
    this.setFeatures(this.selectedPlan.PlanFeatures);
  },
  methods: {
    async getDiscountCoupons() {
      this.loading = true;
      try {
        const response = await SubscriptionService.getDiscountCoupons(
          this.selectedPlan.Id
        );
        if (response && response.status == 200) {
          this.loading = false;
          this.discountCoupons = response.data;
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    getCurrency(num) {
      return Currencies[num].ar;
    },
    getBillingCycleType(type) {
      switch (type) {
        case 1:
          return "شهري";
        case 3:
          return "ربع سنوي";
        case 6:
          return "نصف سنوي";
        case 12:
          return "سنوي";
        case 24:
          return "عامين";
      }
    },
    setFeatures(features) {
      this.features = features.filter((f) => {
        return f.OrderTime !== 2;
      });
      this.moduleFeatures = this.getAppFeatureType(features, 0);
      this.nonApplicationFeatures = this.getAppFeatureType(features, 2);
      this.quotasFeatures = [
        ...this.getAppFeatureType(features, 4),
        ...this.getAppFeatureType(features, 3),
      ].sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    getAppFeatureType(arr, type) {
      return arr.filter(
        (item) => item.AppFeatureType == type && item.OrderTime !== 2
      );
    },
  },
};
</script>