import http from "./httpService";

const plans = [
  {
    Id: 1,
    Name: { ArabicValue: "ERP Basic" },
    Description: {
      ArabicValue:
        "باقة لرجال الاعمال وشركات رواد الاعمال الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة",
    },
    FreePlan: true,
    Published: true,
    Subscribeable: false,
  },
  {
    Id: 12,
    Name: { ArabicValue: "ERP Basic" },
    Description: {
      ArabicValue:
        "باقة لرجال الاعمال وشركات رواد الاعمال الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة",
    },
    FreePlan: true,
    Published: true,
    Subscribeable: false,
  },
  {
    Id: 123,
    Name: { ArabicValue: "ERP Basic" },
    Description: {
      ArabicValue:
        "باقة لرجال الاعمال وشركات رواد الاعمال الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة",
    },
    FreePlan: true,
    Published: true,
    Subscribeable: false,
  },
  {
    Id: 1234,
    Name: { ArabicValue: "ERP Basic" },
    Description: {
      ArabicValue:
        "باقة لرجال الاعمال وشركات رواد الاعمال الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة",
    },
    FreePlan: true,
    Published: true,
    Subscribeable: false,
  },
];

const getAllPlans = async () => {
  // const {data: plans} = await http.get('/plans');
  return plans;
};

const getPlanDetails = async (planId) => {
  // const {data: plan} = await http.get(`/plans/${planId}`);
  // return plan
  return plans.find((plan) => planId == plan.Id);
};

export default {
  getAllPlans,
  getPlanDetails,
};
