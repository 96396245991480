<template>
  <div class="plan-card">
    <header class="plan-card__header">
      <div class="plan-card__title">{{ plan.Name.ArabicValue }}</div>
      <div class="plan-card__actions">
        <button class="btn btn-success" @click="editPlan(plan.Id)">
          <i class="fas fa-edit"></i>
        </button>
        <button class="btn btn-dark" @click="clonePlan(plan.Id)">
          <i class="fas fa-copy"></i>
        </button>
        <button class="btn btn-danger" @click="removePlan(plan.Id)">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </header>
    <section class="plan-card__body">
      <p>{{ plan.Description.ArabicValue }}</p>
    </section>
    <footer class="plan-card__footer">
      <ActivateSwitcher
        :active="plan.Subscribeable"
        @activate="(status) => activatePlan(plan.Id, status)"
      />
    </footer>
  </div>
</template>

<script>
import ActivateSwitcher from "./ActivateSwitcher.vue";
export default {
  components: { ActivateSwitcher },
  props: {
    plan: Object,
  },
  methods: {
    editPlan(planId) {
      this.$router.push(`/edit-plan/${planId}`);
    },
    clonePlan(planId) {},
    removePlan(planId) {},
    activatePlan(planId, status) {},
  },
};
</script>

<style lang="scss">
.plan-card {
  background-color: #f6f6f6;
  box-shadow: 0px 1px 6px #00000017;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  height: 25rem;
  &:nth-child(2n + 1) {
    margin-left: 1rem;
  }
  margin-bottom: 1rem;
  &__header {
    background-color: #d2d2d2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
  }
  &__title {
    color: #313131;
    font-size: 1.8rem;
    font-weight: bold;
  }
  &__body {
    padding: 3rem 1.5rem;
    height: 55%;
    p {
      font-size: 1.5rem;
      line-height: 2rem;
      max-height: 8rem;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden !important;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
    }
  }
  &__actions {
    .btn {
      padding: 0;
      width: 3.5rem;
      height: 3.5rem;
      margin: 0.5rem;
    }
  }
  &__footer {
    padding: 0 2rem;
    text-align: left;
  }
}
</style>