<template>
  <div class="container flex-center flex-col">
    <h2>Oops!</h2>
    <h4>عفواً لا يكمنك الدخول لتلك الصفحة!</h4>
    <router-link to="/" class="link">الرئيسية</router-link> -
    <a @click="$router.back()" class="link">العودة</a>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style>
</style>