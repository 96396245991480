<template>
  <div class="create-partner container mb-6">
    <div class="flex-between mb-4">
      <h2>إضافة شريك</h2>
      <div>
        <button
          class="btn btn-success"
          @click="() => handleFormSubmit(submitPartnerForm)"
        >
          حفظ الشريك
        </button>
      </div>
    </div>
    <create-partner-form
      :hasSubmitBtn="false"
      :loading="loading"
      :partnerData="partnerData"
      @handleSubmit="(formSubmitCB) => (submitPartnerForm = formSubmitCB)"
    />
    <div class="plans mt-5">
      <h3 class="sub-heading">جدول الباقات ونسب الخصم</h3>
      <table>
        <thead>
          <tr>
            <th style="width: 15%; padding-right: 1rem">نوع الباقة</th>
            <th>سعر الباقة/سنوياً</th>
            <th>مبلغ الخصم</th>
            <th>نسبة الخصم %</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="{
              Id,
              Name,
              MonthlyPrice,
              BillingCycleOptions,
            } in servicePlans"
            :key="Id"
          >
            <td style="width: 15%; padding-right: 1rem" v-if="Name">
              {{ Name.EnglishValue }}
            </td>
            <td>
              {{
                (
                  MonthlyPrice * 12 -
                  (MonthlyPrice *
                    12 *
                    BillingCycleOptions.find((o) => o.BillingCycleType == 12)
                      .Discount) /
                    100
                ).toFixed()
              }}
            </td>
            <td>
              <input
                v-if="servicePlansData[Id]"
                type="number"
                class=""
                style="appearance: none"
                placeholder="Enter Value"
                v-model="servicePlansData[Id].commission"
              />
            </td>
            <td>
              <input
                v-if="servicePlansData[Id]"
                type="number"
                class=""
                style="appearance: none"
                placeholder="Enter %"
                v-model="servicePlansData[Id].commissionPrecent"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="plans mt-5">
      <h3 class="sub-heading">استخراج الكود الخاص بالشريك</h3>
      <div class="coupon">
        <button
          class="btn btn-icon btn-outline btn-outline--success"
          @click="generateCode"
        >
          <i class="fas fa-fingerprint"></i>
          تحميل الكود
        </button>
        <div class="coupon-field">
          <input type="text" v-model="promoCode" ref="promoCode" />
          <button class="btn btn-icon" @click="copyCode">
            <i class="fad fa-copy"></i>
            نسخ الكود
          </button>
        </div>
        <div class="loader mx-2" v-if="loadingCode"></div>
      </div>
    </div>
  </div>
  <div class="loader loader-page-layout" v-if="creatingLoading"></div>
</template>

<script>
import CreatePartnerForm from "@/components/partners/CreatePartnerForm.vue";
import SubscriptionService from "@/services/SubscriptionService";
import PartnerService from "@/services/PartnerService";
import { form } from "@formkit/inputs";
export default {
  components: { CreatePartnerForm },
  data() {
    return {
      submitPartnerForm: null,
      promoCode: null,
      loadingCode: false,
      creatingLoading: false,
      plans: [
        {
          Id: 1,
          name: "Go",
          price: 600,
        },
        {
          Id: 2,
          name: "Plus",
          price: 900,
        },
        {
          Id: 3,
          name: "Elite",
          price: 1200,
        },
      ],
      partnerData: {
        // Name: "mohamed moniem",
        // CompanyName: "moniem",
        // Email: "m.abdelmoniem@dafater.sa",
        // Phone: "01111798451",
        // from: new Date().toISOString().split("T")[0],
        // to: new Date().toISOString().split("T")[0],
      },
      promoData: {
        Code: "TEST-uudfghd4",
        Description: {
          ArabicValue: "TEST 7345",
          EnglishValue: "TEST 7345",
        },
        ExpiryDate: "2030-10-28T00:00:00",
        ExpiryDateFrom: "2015-10-28T00:00:00",
        Status: 0,
        PartnerName: {
          ArabicValue: "Abdelrahman",
          EnglishValue: "Abdelrahman",
        },
        CompanyName: {
          ArabicValue: "XYZ",
          EnglishValue: "XYZ",
        },
        Email: "a.kinani@dafater.sa",
        Address: "Hello",
        Phone: 54545555,
      },
      servicePlansData: {},
      allPlans: [],
      servicePlans: [],
    };
  },
  mounted() {
    this.getServicePlans();
  },
  methods: {
    copyCode() {
      const element = this.$refs.promoCode;
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
    },

    async getServicePlans() {
      const response = await SubscriptionService.getAllServicePlans();
      if (response && response.status == 200) {
        this.loading = false;
        this.allPlans = response.data;
        this.servicePlans = this.allPlans.filter(
          (c) => c.Status == 1 && !c.TrialPlan
        );
        this.servicePlans.map((s) => {
          this.servicePlansData[s.Id] = {
            commission: "",
            commissionPrecent: "",
          };
        });
      } else {
        this.$toast.error("حدث خطأ ما أثناء العثور على الباقات!");
      }
    },
    handleFormSubmit(cb) {
      const { valid, formData } = cb();
      if (!this.promoCode || !valid) {
        this.$toast.error("هناك مشكلة راجع البيانات المفقودة");
      }
      const promoData = {
        Code: this.promoCode,
        Description: {
          ArabicValue: formData.Description,
          EnglishValue: formData.Description,
        },
        ExpiryDate: formData.to,
        ExpiryDateFrom: formData.from,
        Status: 0,
        PartnerName: {
          ArabicValue: formData.PartnerName,
          EnglishValue: formData.PartnerName,
        },
        CompanyName: {
          ArabicValue: formData.CompanyName,
          EnglishValue: formData.CompanyName,
        },
        Email: formData.Email,
        Address: "Hello",
        Phone: formData.Phone,
      };
      this.createPromoCode(promoData);
    },
    async generateCode() {
      this.loadingCode = true;
      const codeResponse = await PartnerService.generatePromoCode();
      if (codeResponse && codeResponse.status == 200) {
        this.promoCode = codeResponse.data;
        this.loadingCode = false;
        this.$refs.promoCode.readOnly = true;
      } else {
        this.loadingCode = false;
      }
    },
    async createPromoCode(data) {
      this.creatingLoading = true;
      const createResponse = await PartnerService.createPartner(data);
      if (createResponse && createResponse.status == 200) {
        Object.keys(this.servicePlansData).map(async (k, i) => {
          const updatePromoPlansResponse =
            await PartnerService.updatePromoPlanCommision({
              PromotionCode_Id: createResponse.data.Id,
              ServicePlan_Id: k,
              PartnerDiscountPercentage:
                this.servicePlansData[k].commissionPrecent,
              PartnerDiscountAmount: this.servicePlansData[k].commissionPrecent,
            });
          if (
            updatePromoPlansResponse &&
            updatePromoPlansResponse.status == 200
          ) {
            if (i == Object.keys(this.servicePlansData).length - 1) {
              this.creatingLoading = false;
              this.$router.replace("/partners");
            }
          }
        });
      } else {
        this.creatingLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>