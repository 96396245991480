import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//msal browser
import { EventType } from "@azure/msal-browser";
import { msalInstance, loginRequest } from "@/authConfig";
import { msalPlugin } from "@/plugins/msalPlugin";

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_FAILURE &&
    event.error.errorMessage.includes("forgotten")
  ) {
    msalInstance.loginRedirect();
  }
  if (
    event.eventType === EventType.LOGIN_FAILURE &&
    event.error.errorMessage.includes("cancelled")
  ) {
    msalInstance.loginRedirect(loginRequest);
  }
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// tools & packages
import VueCollapsiblePanel from "@dafcoe/vue-collapsible-panel";
import VueEllipseProgress from "vue-ellipse-progress";
import VueHtmlToPaper from "/src/util/printHtml";
import { Tabs, Tab } from "vue3-tabs-component";
import Toaster from "@meforma/vue-toaster";
import VueExcelXlsx from "vue-excel-xlsx";
// import { ValidationProvider } from "vee-validate";
import { ar } from "@formkit/i18n";
import { plugin, defaultConfig } from "@formkit/vue";

//styles
import "./assets/styles/main.scss";
import "./assets/fonts/all.min.css";
import "skeleton-elements/css";

// register components
const app = createApp(App);

app.use(VueCollapsiblePanel);
app.use(VueEllipseProgress, "radial-progress");
app.use(VueHtmlToPaper);
app.use(Toaster);

app.use(msalPlugin, msalInstance); ///msal browser///

//components
// app.component("ValidationProvider", ValidationProvider);
app.use(
  plugin,
  defaultConfig({
    // Define additional locales
    locales: { ar },
    // Define the active locale
    locale: "ar",
  })
);

// app.use()

app.component("tabs-component", Tabs).component("tab-component", Tab);
app.use(VueExcelXlsx);
// start the app
app.use(router);
app.use(store);
app.mount("#app");
