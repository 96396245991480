<template>
  <section class="package-specs__section">
    <package-spec-card-loading />
    <package-spec-card-loading />
    <package-spec-card-loading />
  </section>
</template>

<script>
import PackageSpecCardLoading from "./PackageSpecCardLoading.vue";

export default {
  components: {
    PackageSpecCardLoading,
  },
};
</script>

<style>
</style>