<template>
  <div class="subscriptions-table audit-table">
    <template v-if="!isLoading">
      <table-lite
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :total="totalRecordCount"
        :is-static-mode="true"
        :sortable="sortable"
        :pageOptions="[
          { value: 50, text: 50 },
          { value: 75, text: 75 },
          { value: 100, text: 100 },
        ]"
        @do-search="$emit('doSearch')"
        @row-clicked="(row) => $emit('client-clicked', row)"
        @is-finished="tableLoadingFinish"
        @return-checked-rows="checkRow"
        v-show="!isLoading"
        :messages="messages"
        :class="totalRecordCount > 10 ? 'has-pagination' : ''"
      >
      </table-lite>
    </template>
    <audit-table-loading v-else />
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import AuditTableLoading from "./AuditTableLoading.vue";

export default {
  components: {
    TableLite,
    AuditTableLoading,
  },
  props: {
    audits: Array,
    dataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: true,
      columns: [
        {
          label: "الاسم",
          field: "TenantName",
          sortable: true,
        },
        {
          label: "الوصف",
          field: "Description",
          sortable: true,
          width: "30%",
        },
        {
          label: "الزمن",
          field: "TimeStamp",
          sortable: true,
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "Date",
        sort: "asc",
      },
      messages: {
        pagingInfo: "عرض {0}-{1} من {2}",
        pageSizeChangeLabel: "عدد الصفوف:",
        gotoPageLabel: "الذهاب للصفحة:",
        noDataAvailable: "لا يوجد اي بيانات",
      },
    };
  },
  watch: {
    dataLoading(newVal) {
      this.isLoading = newVal;
    },
    audits(newVal) {
      this.setRows(newVal);
    },
  },
  mounted() {
    this.setRows(this.audits);
  },
  methods: {
    setRows(rows) {
      this.rows = rows;
      this.totalRecordCount = rows.length;
      this.isLoading = false;
    },
    checkRow(row) {
      // do action on checking row
    },

    tableLoadingFinish(elements) {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.audit-table .vtl-tbody-td span {
  max-width: 55rem;
}
</style>