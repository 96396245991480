<template>
  <FormKit
    type="form"
    id="clientForm"
    ref="clientForm"
    :actions="false"
    v-model="formData"
    #default="{ state }"
  >
    <h3 class="c-danger" v-if="!state.valid && submited">
      من فضلك املاء البيانات بشكل صحيح
    </h3>
    <div class="form-section">
      <h3 class="form-section__title">بيانات العميل</h3>
      <div class="grid grid-1x2">
        <div class="form-group">
          <FormKit
            name="email"
            v-model="client.email"
            type="email"
            label="البريد الإلكتروني"
            validation="required|email"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="name"
            v-model="client.name"
            type="text"
            label="الاسم"
            validation="required"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            v-model="client.mobileNumber"
            name="mobileNumber"
            type="tel"
            label="الجوال"
            validation="required|matches:/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/"
            :validation-messages="{
              matches: 'من فضلك ادخل رقم جوال صحيح',
            }"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            v-model="client.phone"
            name="phone"
            type="tel"
            label="الهاتف"
            validation="required|matches:/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/"
            :validation-messages="{
              matches: 'من فضلك ادخل رقم جوال صحيح',
            }"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            type="select"
            label="البلد"
            name="countryCode"
            placeholder="اختر البلد"
            v-model="client.countryCode"
            :options="countries"
            @change="changeCities"
          />
        </div>
        <div class="form-group">
          <FormKit
            type="select"
            label="المدينة"
            name="city"
            placeholder="اختر المدينة"
            v-model="client.city"
            :options="cities"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="storeName"
            v-model="client.storeName"
            type="text"
            label="اسم الشركة"
            validation="required"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="storeActivity"
            v-model="client.storeActivity"
            type="text"
            label="نشاط الشركة"
            validation="required"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="storeAddress"
            v-model="client.storeAddress"
            type="text"
            label="عنوان الشركة"
            validation="required"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="tiNumber"
            v-model="client.tiNumber"
            type="text"
            label="رقم السجل الضريبي"
            validation="required"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="crNumber"
            v-model="client.crNumber"
            type="text"
            label="رقم السجل التجاري"
            validation="required"
            outer-class="half-field"
          />
        </div>
      </div>
      <button
        v-if="hasSubmitBtn"
        class="btn btn-info"
        @click.prevent="$emit('handleSubmit', () => this.validateForm())"
      >
        إضافة عميل
      </button>
    </div>
  </FormKit>
  <div class="loader loader-page-layout" v-if="loadingSpinner"></div>
</template>

<script>
import ClientService from "@/services/ClientService";
export default {
  props: {
    hasSubmitBtn: Boolean,
    loading: Boolean,
  },
  emits: ["handleSubmit"],
  data() {
    return {
      client: {
        email: "",
        name: "",
        mobileNumber: "",
        phone: "",
        storeName: "",
        storeActivity: "",
        countryCode: "",
        tiNumber: "",
        crNumber: "",
        city: "",
        storeAddress: "",
      },
      countries: [],
      cities: [],
      formData: {},
      submited: false,
      loadingSpinner: false,
    };
  },
  mounted() {
    this.getCountries();
    if (!this.hasSubmitBtn) {
      this.$emit("handleSubmit", () => this.validateForm());
    }
  },
  watch: {
    loading(to) {
      this.loadingSpinner = to;
    },
  },
  methods: {
    async getCountries() {
      const { data: countries } = await ClientService.getAllCountries(true);
      this.countries = countries.map((country) => ({
        label: country.Name.ArabicValue,
        value: country.CountryCode,
        ChildRegions: country.ChildRegions,
      }));
      this.client.countryCode = this.countries[0].__original;
      this.changeCities();
    },
    async changeCities() {
      const cities = this.countries.find(
        (c) => c.__original == this.client.countryCode
      ).ChildRegions;
      this.cities = cities.map((city) => ({
        label: city.Name.ArabicValue,
        value: city.Id,
      }));
    },
    validateForm() {
      const valid = this.$refs["clientForm"].node.context.state.valid;
      this.submited = true;
      return {
        valid,
        formData: { ...this.formData },
      };
    },
  },
};
</script>

<style>
</style>