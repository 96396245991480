<template>
  <div class="subscriptions-table">
    <table>
      <thead>
        <tr>
          <th>الإسم</th>
          <th>الوصف</th>
          <th>الزمن</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><skeleton-text effect="wave">comapnyname</skeleton-text></td>
          <td>
            <skeleton-text effect="wave"
              >some description are here so you can read it</skeleton-text
            >
          </td>
          <td>
            <skeleton-text effect="wave">20/10/2022 12:20:01 AM</skeleton-text>
          </td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">comapnyname here</skeleton-text></td>
          <td>
            <skeleton-text effect="wave"
              >some description are here so you can read it</skeleton-text
            >
          </td>
          <td>
            <skeleton-text effect="wave">20/10/2022 12:20:01 AM</skeleton-text>
          </td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">comapny here</skeleton-text></td>
          <td>
            <skeleton-text effect="wave"
              >some description are here so you can read it</skeleton-text
            >
          </td>
          <td>
            <skeleton-text effect="wave">20/10/2022 12:20:01 AM</skeleton-text>
          </td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">comapnyname</skeleton-text></td>
          <td>
            <skeleton-text effect="wave"
              >some description are here so you can read it</skeleton-text
            >
          </td>
          <td>
            <skeleton-text effect="wave">20/10/2022 12:20:01 AM</skeleton-text>
          </td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">comapnyname here</skeleton-text></td>
          <td>
            <skeleton-text effect="wave"
              >some description are here so you can read it</skeleton-text
            >
          </td>
          <td>
            <skeleton-text effect="wave">20/10/2022 12:20:01 AM</skeleton-text>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    SkeletonText,
  },
};
</script>

<style lang="scss">
</style>