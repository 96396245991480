<template>
  <div class="subscriptions-table">
    <template v-if="!isLoading">
      <table-lite
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :total="totalRecordCount"
        :is-static-mode="true"
        :sortable="sortable"
        :pageOptions="[
          { value: 25, text: 25 },
          { value: 50, text: 50 },
          { value: 75, text: 75 },
        ]"
        @do-search="$emit('doSearch')"
        @row-clicked="(row) => $emit('subscription-clicked', row)"
        @is-finished="tableLoadingFinish"
        :messages="messages"
        :class="totalRecordCount > 10 ? 'has-pagination' : ''"
      >
      </table-lite>
    </template>
    <partners-report-table-loading v-else />
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import PartnersReportTableLoading from "./PartnersReportTableLoading.vue";

export default {
  components: {
    TableLite,
    PartnersReportTableLoading,
  },
  props: {
    partners: Array,
    dataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataId: "",
      isLoading: this.dataLoading,
      columns: [
        {
          label: "المعرف",
          field: "Id",
          sortable: true,
          isKey: true,
        },
        {
          label: "اسم الشريك",
          field: "PartnerName",
          sortable: true,
        },
        {
          label: "الكود الترويجي",
          field: "Code",
          sortable: true,
        },
        {
          label: "اسم الشركة",
          field: "CompanyName",
          sortable: true,
        },
        {
          label: "من تاريخ",
          field: "ExpiryDateFrom",
          sortable: true,
        },
        {
          label: "إلى تاريخ",
          field: "ExpiryDate",
          sortable: true,
        },
        {
          label: "نوع الباقة",
          field: "ServicePlan",
          sortable: true,
        },
        {
          label: "عدد المبيعات",
          field: "Sales",
          sortable: true,
        },
        {
          label: "العمولة",
          field: "Commission",
          sortable: true,
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "Number",
        sort: "desc",
      },
      messages: {
        pagingInfo: "عرض {0}-{1} من {2}",
        pageSizeChangeLabel: "عدد الصفوف:",
        gotoPageLabel: "الذهاب للصفحة:",
        noDataAvailable: "لا يوجد اي بيانات",
      },
    };
  },
  watch: {
    dataLoading(newVal) {
      this.isLoading = newVal;
    },
    partners(newVal) {
      this.setRows(newVal);
    },
  },
  mounted() {
    this.setRows(this.partners);
  },
  methods: {
    setRows(partners) {
      this.rows = partners;
      this.totalRecordCount = partners.length;
      this.isLoading = false;
    },
    tableLoadingFinish(elements) {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
</style>