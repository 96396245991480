<template>
  <nav class="nav flex-between">
    <div class="flex-between">
      <div class="nav-logo">
        <img src="@/assets/images/Dafater-ERP-x2.png" alt="manage dafater.sa" />
      </div>

      <ul class="list">
        <li>
          <router-link to="/"> الاشتراكات </router-link>
        </li>
        <li>
          <router-link to="/clients">العملاء</router-link>
        </li>
        <li>
          <a href="#">تقارير</a>
          <ul class="child-menu list-ver">
            <li>
              <router-link to="/audit-trail">تقرير سجل العمليات</router-link>
            </li>
            <li>
              <router-link to="/products-report">تقرير المنتجات</router-link>
            </li>
            <li>
              <router-link to="/partners-report">تقرير الشركاء</router-link>
            </li>
          </ul>
        </li>
        <li v-if="isAuthenticated">
          <a href="#">الخدمة</a>
          <ul class="child-menu list-ver">
            <li v-for="{ value, label } in services" :key="value">
              <a
                href="#"
                @click="switchService(value)"
                :class="
                  $store.state.serviceId == value
                    ? 'router-link-exact-active'
                    : ''
                "
                >{{ label }}</a
              >
            </li>
          </ul>
        </li>
        <li>
          <router-link to="/partners">الشركاء</router-link>
        </li>
        <!-- <li>
          <router-link to="/indications">المؤشرات</router-link>
        </li>
        <li>
          <router-link to="/plans">الباقات</router-link>
        </li> -->
      </ul>
    </div>
    <router-link to="/profile" class="fw-bl" v-if="isAuthenticated">{{
      accounts[0].name
    }}</router-link>
    <a class="pointer" v-else @click="login">Log in</a>
    <div class="below"></div>
  </nav>
</template>

<script>
import { useMsal } from "@/composition-api/useMsal";
import { loginRequest } from "@/authConfig";
import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
import SubscriptionService from "@/services/SubscriptionService";

import UserService from "@/services/UserService";
export default {
  setup() {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    return {
      instance,
      accounts,
      inProgress,
      isAuthenticated,
    };
  },
  data() {
    return {
      services: null,
    };
  },
  mounted() {
    this.getServices();
  },
  watch: {
    accounts(to) {
      this.getServices();
    },
  },
  methods: {
    logout() {
      this.instance.logoutRedirect();
    },
    login() {
      this.instance.loginRedirect(loginRequest);
    },
    switchService(serviceId) {
      this.$store.commit("switchService", serviceId);
      // this.$forceUpdate();
      sessionStorage.setItem("activeServiceId", serviceId);
      this.$router.replace("/home");
    },
    async getServices() {
      if (this.accounts[0]) {
        const profileResponse = await UserService.getProfile();
        if (profileResponse) {
          const servicesResponse = await SubscriptionService.getAllServices();
          this.services = servicesResponse.data.map((s) => ({
            label: s.Name.ArabicValue,
            value: s.Id,
          }));
          return this.services;
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>