<template>
  <div class="subscriptions">
    <div class="container">
      <div class="subscriptions__header flex-between">
        <div class="flex-between">
          <button
            class="btn btn-success"
            ref="ActiveSubscription"
            :disabled="dataLoading"
            @click="getSubscriptions('ActiveSubscription')"
          >
            المفعلة
          </button>
          <div class="subscriptions__header-connected-btns">
            <button
              class="btn btn-success"
              ref="NewSubscriptions"
              :disabled="dataLoading"
              @click="getSubscriptions('NewSubscriptions')"
            >
              الجديدة
            </button>
            <button
              class="btn btn-danger"
              ref="UnpaidSubscriptions"
              :disabled="dataLoading"
              @click="getSubscriptions('UnpaidSubscriptions')"
            >
              الغير مدفوعة
            </button>
            <button
              class="btn btn-warning"
              ref="TobeUnpaidSubscriptions"
              :disabled="dataLoading"
              @click="getSubscriptions('TobeUnpaidSubscriptions')"
            >
              شارفت على الإنتهاء
            </button>

            <button
              class="btn btn-success"
              ref="TrialActiveSubscriptions"
              :disabled="dataLoading"
              @click="getSubscriptions('TrialActiveSubscriptions')"
            >
              التجريبية
            </button>
            <button
              class="btn btn-dark"
              ref="SuspendedSusbcriptions"
              :disabled="dataLoading"
              @click="getSubscriptions('SuspendedSusbcriptions')"
            >
              الموقوفة
            </button>
          </div>

          <button
            class="btn btn-icon btn-info"
            ref="all"
            :disabled="dataLoading"
            @click="getSubscriptions()"
          >
            عرض الجميع
          </button>
        </div>
        <div class="flex-between">
          <router-link
            to="/create-subscription"
            v-if="
              ['Manager', 'CS_Onboarding_Specialist', 'Administrator'].includes(
                role
              )
            "
          >
            <button class="btn btn-icon btn-danger">
              <i class="fas fa-plus-circle"></i>
              اشتراك جديد
            </button>
          </router-link>

          <vue-excel-xlsx
            v-if="['Manager', 'Administrator'].includes(role)"
            class="btn btn-success"
            :data="subscriptions"
            :columns="columns"
            :file-name="'الاشتراكات'"
            :file-type="'xlsx'"
            :sheet-name="dataTitle"
          >
            <i class="far fa-file-times"></i>
          </vue-excel-xlsx>
        </div>
      </div>
      <div class="filter-field mb-2 mt-3">
        <h4>بحث:</h4>
        <input type="search" v-model="searchingParam" @keyup="handleSearch" />
      </div>
      <subscriptions-table
        :subscriptions="subscriptions"
        :dataLoading="dataLoading"
        @subscription-clicked="
          (row) => $router.push({ path: `/subscriptions/${row.Id}` })
        "
      />
    </div>
  </div>
</template>

<script>
import SubscriptionsTable from "@/components/subscriptions/SubscriptionsTable.vue";
import SubscriptionService from "@/services/SubscriptionService";
import { formatDate, subtractDates, getNowDate } from "@/util/date";
export default {
  components: {
    SubscriptionsTable,
  },
  props: {
    role: String,
  },
  data() {
    return {
      allSubscriptions: [],
      subscriptions: [],
      dataLoading: false,
      searchingParam: null,
      dataTitle: "",
      columns: [
        {
          label: "رقم الاشتراك",
          field: "Number",
        },
        {
          label: "معرف الاشتراك",
          field: "Id",
        },
        {
          label: "إسم الشركة",
          field: "SubscriberName",
        },
        {
          label: "إسم الباقة",
          field: "ServicePlanName",
        },
        {
          label: "تاريخ الإبتداء",
          field: "StartDate",
        },
        {
          label: "تاريخ الإنتهاء",
          field: "EndBillingDate",
        },
        {
          label: "الحالة",
          field: "Status",
        },
      ],
    };
  },
  mounted() {
    this.getSubscriptions("ActiveSubscription");
  },
  watch: {
    $route(to) {
      this.getSubscriptions("ActiveSubscription");
    },
  },
  methods: {
    async getSubscriptions(filter = "") {
      this.dataLoading = true;
      // const filters = [
      //   { pageIndex: 0 },
      //   { pageCount: 0 },
      //   { orderBy: "" },
      //   { ascending: true },
      // ];
      const ref = filter ? filter : "all";
      Object.keys(this.$refs).forEach(
        (btn) => (this.$refs[btn].style.boxShadow = "")
      );
      this.$refs[ref].style.boxShadow = "inset 0px 0px 7px 0px #00000090";
      this.dataTitle = this.$refs[ref].textContent;
      const subscriptions = await SubscriptionService.getAllSubscriptions(
        filter
      );
      // const subscriptions = [
      //   {
      //     Number: "SN220800055",
      //     Id: 46615,
      //     Subscriber: { SubscriberName: "moniem" },
      //     ServicePlan: {
      //       Name: { ArabicValue: "ERP Basic", EnglishValue: "ERP Basic" },
      //     },
      //     StartDate: "2022-08-21T13:02:34.84",
      //     EndBillingDate: "2023-08-16T13:02:34.84",
      //     Status: 2,
      //     PromoCode: null,
      //   },
      // ];
      this.allSubscriptions = subscriptions.map((subscription) => {
        return {
          ...subscription,
          ServicePlanName: subscription.ServicePlan["Name"]["ArabicValue"],
          SubscriberName: subscription.Subscriber["Name"],
          StartDate: formatDate(subscription.StartDate),
          EndBillingDate: formatDate(subscription.EndBillingDate),
          Status: SubscriptionService.getStatus(subscription.Status, "ar"),
        };
      });
      this.subscriptions = this.allSubscriptions;
      this.dataLoading = false;
    },
    // filterSubscriptions(status = "") {
    //   this.subscriptions = this.allSubscriptions.filter((sub) => {
    //     if (status == "newest") {
    //       const daysFromStart = subtractDates(sub.StartDate - getNowDate());
    //       return daysFromStart < 30 ? sub : false;
    //     } else if (status == "active") {
    //       return sub.Status == "Active";
    //     } else if (status == "semi-finished") {
    //       const daysToEnd = subtractDates(getNowDate() - sub.EndBillingDate);
    //       return daysToEnd < 30 ? sub : false;
    //     } else if (status == "unpaid") {
    //       // return sub.Status == "Unpaid"; مش عارف اعملها ايه
    //     } else if (status == "stoped") {
    //       // return sub.Status == "Stoped";
    //       const daysToEnd = subtractDates(getNowDate() - sub.EndBillingDate);
    //       return daysToEnd < 30 ? sub : false;
    //     } else if (status == "trial") {
    //       return sub.ServicePlan.TrialPlan;
    //     }
    //     // return sub;
    //   });
    // },
    handleSearch(e) {
      const searchParam = e.target.value.toLowerCase();
      if (searchParam) {
        this.subscriptions = this.allSubscriptions.filter((subscription) => {
          // console.log("tshi is id:>", subscription.Id);
          return (
            subscription.ServicePlanName.toLowerCase().includes(searchParam) ||
            subscription.SubscriberName.toLowerCase().includes(searchParam) ||
            subscription.Id.toString().toLowerCase().includes(searchParam) ||
            subscription.StartDate.toLowerCase().includes(searchParam) ||
            subscription.EndBillingDate.toLowerCase().includes(searchParam) ||
            subscription.Status.toLowerCase().includes(searchParam) ||
            subscription.Number.toLowerCase().includes(searchParam)
          );
        });
      } else {
        this.subscriptions = this.allSubscriptions;
      }
    },
  },
};
</script>

<style lang="scss">
.subscriptions,
.clients {
  width: 90%;
  margin: auto;
  &__header {
    .btn {
      margin: 0 1rem;
      margin-bottom: 3rem;
      &:last-child {
        margin-left: 0;
      }
    }
    &-connected-btns {
      display: flex;
      align-items: flex-start;
      .btn {
        margin: 0;
        border-radius: 0;
        &:first-child {
          border-radius: 0 4px 4px 0;
        }
        &:last-child {
          border-radius: 4px 0 0 4px;
        }
      }
    }
    .btn {
      padding: 1rem 2rem;
      font-size: 1.5rem;
      border: 0.5px solid #04040420;
    }
  }
}
</style>