import http from "./httpService";

const signUp = async (user) => {
  const { data: profile } = await http.post("/account?ServiceId=1", user);
  return profile;
};
const getProfile = async () => {
  const response = await http.get("/account");
  return response;
};
const getRole = (code) => {
  const enums = [
    "Manager",
    "Accountant",
    "Marketer",
    "Viewer",
    "Administrator",
    "Affiliate",
    "CS_Account_Manager",
    "CS_Onboarding_Specialist",
  ];
  return enums[code];
};
export default {
  getRole,
  signUp,
  getProfile,
};
