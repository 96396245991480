<template>
  <button :class="`switcher ${activate ? 'active' : ''}`" @click="toggle">
    <span v-if="activate"> مفعل </span>
    <span v-else> معلق </span>
    <i class="far fa-check"></i>
    <i class="far fa-times"></i>
  </button>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
  data() {
    return {
      activate: this.active,
    };
  },
  methods: {
    toggle() {
      this.activate = !this.activate;
      this.$emit("activatePlan", this.activate);
    },
  },
};
</script>

<style lang="scss">
.switcher {
  cursor: pointer;
  border-radius: 40px;
  border: 0;
  background-color: #ec5d59;
  width: 8rem;
  height: 2.8rem;
  padding: 1rem;
  position: relative;
  transition: 0.5s ease;
  span {
    position: absolute;
    right: 3.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
  i {
    background-color: #fff;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    position: absolute;
    top: 50%;
    // right: 0.5rem;
    transform: translateY(-50%) translateX(25%) rotate(360deg);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.8s ease;
    &:first-of-type {
      z-index: 99;
      opacity: 0;
    }
  }
  &.active {
    background-color: #91cd91;
    span {
      left: 3.5rem;
      right: auto;
    }
    i {
      right: 0.5rem;
      transform: translateY(-50%) translateX(-250%);
      &:first-of-type {
        opacity: 1;
      }
    }
  }
}
</style>