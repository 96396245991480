<template>
  <div class="subscriptions">
    <div class="">
      <div class="subscriptions__header flex-between mb-6">
        <div class="flex-between">
          <button
            class="btn btn-icon btn-info"
            ref="all"
            :disabled="dataLoading"
            @click="getSubscriptions()"
          >
            عرض الجميع
          </button>
          <div class="subscriptions__header-connected-btns ml-1">
            <drop-down-btn
              class="btn select-success dropdown-select"
              :disabled="dataLoading"
              :items="services"
              listType="success"
              v-model="partner"
            >
              اسم الشريك
            </drop-down-btn>
            <drop-down-btn
              class="btn select-success dropdown-select"
              :disabled="dataLoading"
              :items="[
                { label: 'تجريبية', value: true },
                { label: 'مدفوعة', value: false },
              ]"
              listType="success"
              v-model="isTrialPlan"
              @selected="(v) => filterPlan('planType', v)"
            >
              نوع الباقة
            </drop-down-btn>
          </div>
          <div
            class="subscriptions__header-connected-btns"
            style="position: relative"
          >
            <button
              class="btn btn-warning"
              :disabled="dataLoading"
              @click="() => showField('showDateFrom', showDateFrom)"
            >
              من
              {{ DateFrom }}
              <i class="fas fa-sort-down"></i>
            </button>
            <input
              type="date"
              class="packer-input"
              v-model="DateFrom"
              v-if="showDateFrom"
            />
            <button
              class="btn btn-warning"
              :disabled="dataLoading"
              @click="() => showField('showDateTo', showDateTo)"
            >
              إلى
              {{ DateTo }}
              <i class="fas fa-sort-down"></i>
            </button>
            <input
              type="date"
              class="packer-input"
              v-model="DateTo"
              v-if="showDateTo"
            />
          </div>
          <button
            class="btn btn-danger"
            :disabled="dataLoading"
            @click="applyFilters"
          >
            <!-- بحث -->
            <i class="fas fa-search"></i>
          </button>
        </div>
        <div class="flex-between">
          <vue-excel-xlsx
            v-if="['Manager', 'Administrator'].includes(role)"
            class="btn btn-success"
            :data="subscriptions"
            :columns="columns"
            :file-name="'الاشتراكات'"
            :file-type="'xlsx'"
            :sheet-name="dataTitle"
            title="export"
          >
            <i class="far fa-file-times"></i>
          </vue-excel-xlsx>
        </div>
      </div>
      <!-- <div class="filter-field mb-2 mt-3">
        <h4>بحث:</h4>
        <input type="search" v-model="searchingParam" @keyup="handleSearch" />
      </div> -->
      <partners-report-table
        :partners="partners"
        :dataLoading="dataLoading"
        @partner-clicked="
          (row) => $router.push({ path: `/partners/${row.Id}` })
        "
      />
    </div>
  </div>
</template>

<script>
import PartnersReportTable from "@/components/partners/PartnersReportTable.vue";
import SubscriptionService from "@/services/SubscriptionService";
import { formatDate, subtractDates, getNowDate } from "@/util/date";
import {
  Currencies,
  getBillingCycleType,
  getPaymentMethodType,
} from "@/util/utilities";
import ClientService from "@/services/ClientService";
import DropDownBtn from "@/components/common/DropDownBtn.vue";
// import Datepicker from '../src/datepicker/Datepicker.vue'
export default {
  components: {
    PartnersReportTable,
    DropDownBtn,
  },
  props: {
    role: String,
  },
  data() {
    return {
      allSubscriptions: [],
      subscriptions: [],
      dataLoading: false,
      searchingParam: null,
      dataTitle: "",
      DateFrom: null,
      DateTo: new Date().toISOString().substring(0, 10),
      activity: "",
      showActivity: false,
      showDateFrom: false,
      showDateTo: false,
      filters: {},
      columns: [
        {
          label: "المعرف",
          field: "Id",
        },
        {
          label: "اسم الشريك",
          field: "PartnerName",
        },
        {
          label: "الكود الترويجي",
          field: "Code",
        },
        {
          label: "اسم الشركة",
          field: "CompanyName",
        },
        {
          label: "من تاريخ",
          field: "ExpiryDateFrom",
        },
        {
          label: "إلى تاريخ",
          field: "ExpiryDate",
        },
        {
          label: "نوع الباقة",
          field: "ServicePlan",
        },
        {
          label: "عدد المبيعات",
          field: "Sales",
        },
        {
          label: "العمولة",
          field: "Commission",
        },
      ],
      timer: null,
      waitTime: 1000,
      product: this.$store.state.serviceId,
      isTrialPlan: null,
      billingCycleOption: null,
      services: null,
      serviceId: this.$store.state.serviceId,
    };
  },
  async mounted() {
    this.getPartners("all");
  },
  watch: {
    $route(to) {
      this.getSubscriptions("all");
    },
  },
  methods: {
    applyFilters() {
      this.showActivity = false;
      this.showDateFrom = false;
      this.showDateTo = false;
      if (this.billingCycleOption)
        this.filters["BillingCycleType"] = this.billingCycleOption;
      if (this.isTrialPlan !== null) {
        this.filters["showTrialPlanOnly"] = /true/.test(this.isTrialPlan);
        this.filters["showPaidPlanOnly"] = /false/.test(this.isTrialPlan);
        this.filters["filter"] =
          this.isTrialPlan == "true" ? "TrialSubscribers" : "null";
      }
      this.filters["registerationDateFrom"] = new Date(
        this.DateFrom
      ).toISOString();
      this.filters["registerationDateTo"] = new Date(this.DateTo).toISOString();
      if (this.activity) this.filters["businessDomainName"] = this.activity;
      this.filterSubscriptions();
      this.filters = {};
    },
    async getClientsFor(Id) {
      try {
        this.dataLoading = true;
        const clientsResponse = await ClientService.getClientsFor(Id);
        this.allSubscriptions = this.prepareSubscriptions(
          clientsResponse.data,
          Id
        );
        this.subscriptions = this.allSubscriptions;
        this.dataLoading = false;
      } catch (error) {
        this.$toast.error("");
        this.dataLoading = false;
      }
    },
    async filterPlan(key, value) {
      if (key == "planDuration") {
        this.filters["BillingCycleType"] = value;
      } else {
        switch (value) {
          case "trial":
            this.filters["showTrialPlanOnly"] = true;
            this.filters["showPaidPlanOnly"] = false;
            break;
          case "paid":
            this.filters["showPaidPlanOnly"] = true;
            this.filters["showTrialPlanOnly"] = false;
            break;
        }
      }
      this.filterSubscriptions();
    },
    async filterSubscriptions() {
      this.dataLoading = true;
      const filtersArr = Object.keys(this.filters).map((f) => ({
        [f]: this.filters[f],
      }));
      const clientsResponse = await ClientService.filterClients(filtersArr);
      this.allSubscriptions = this.prepareSubscriptions(clientsResponse.data);
      this.subscriptions = this.allSubscriptions;
      this.dataLoading = false;
    },
    async getSubscriptions() {
      this.dataLoading = true;
      this.DateFrom = null;
      this.isTrialPlan = null;
      this.billingCycleOption = null;
      this.activity = null;
      const clientsResponse = await ClientService.getAllClients();
      this.allSubscriptions = this.prepareSubscriptions(clientsResponse.data);
      this.subscriptions = this.allSubscriptions;
      this.dataLoading = false;
    },
    prepareSubscriptions(data, serviceId = this.$store.state.serviceId) {},
    handleSearch(e) {
      const searchParam = e.target.value.toLowerCase();
      if (searchParam) {
        this.subscriptions = this.allSubscriptions.filter((subscription) => {
          return (
            subscription.ServicePlanName.toLowerCase().includes(searchParam) ||
            subscription.SubscriberName.toLowerCase().includes(searchParam) ||
            subscription.Id.toString().toLowerCase().includes(searchParam) ||
            subscription.StartDate.toLowerCase().includes(searchParam) ||
            subscription.EndBillingDate.toLowerCase().includes(searchParam) ||
            subscription.Status.toLowerCase().includes(searchParam) ||
            subscription.Number.toLowerCase().includes(searchParam)
          );
        });
      } else {
        this.subscriptions = this.allSubscriptions;
      }
    },
    showField(field, isOpened) {
      this.showActivity = false;
      this.showDateFrom = false;
      this.showDateTo = false;
      this[field] = !isOpened;
    },
  },
};
</script>

<style lang="scss">
.packer-input {
  position: absolute;
  bottom: -14px;
  right: 0;
  min-width: 25rem;
  z-index: 99999;
}
</style>