<template>
  <div class="subscriptions-table">
    <table>
      <thead>
        <tr>
          <th>رقم الاشتراك</th>
          <th>معرف الاشتراك</th>
          <th>إسم الشركة</th>
          <th>إسم الباقة</th>
          <th>الرمز الترويجي</th>
          <th>تاريخ الإبتداء</th>
          <th>تاريخ الإنتهاء</th>
          <th>الحالة</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">id:0123423</skeleton-text></td>
          <td><skeleton-text effect="wave">company name</skeleton-text></td>
          <td><skeleton-text effect="wave">package name</skeleton-text></td>
          <td><skeleton-text effect="wave">code or number</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">active</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">id:23423</skeleton-text></td>
          <td><skeleton-text effect="wave">companame</skeleton-text></td>
          <td><skeleton-text effect="wave">packagname</skeleton-text></td>
          <td><skeleton-text effect="wave">copuon</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">suspended</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">id:0123423</skeleton-text></td>
          <td><skeleton-text effect="wave">company name</skeleton-text></td>
          <td><skeleton-text effect="wave">package name</skeleton-text></td>
          <td><skeleton-text effect="wave">code or number</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">active</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">id:23423</skeleton-text></td>
          <td><skeleton-text effect="wave">companame</skeleton-text></td>
          <td><skeleton-text effect="wave">packagname</skeleton-text></td>
          <td><skeleton-text effect="wave">copuon</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">suspended</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">id:0123423</skeleton-text></td>
          <td><skeleton-text effect="wave">company name</skeleton-text></td>
          <td><skeleton-text effect="wave">package name</skeleton-text></td>
          <td><skeleton-text effect="wave">code or number</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">active</skeleton-text></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    SkeletonText,
  },
};
</script>

<style lang="scss">
</style>