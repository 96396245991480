<template>
  <section class="package-specs__section">
    <template
      v-for="(
        {
          Id,
          InitialQuotaPack,
          AddionalQuotaPacks,
          StepName,
          QuotaPackPrice,
          AppFeatureType,
          BillingFrequency,
          ...otherProps
        },
        i
      ) in FeaturesGroup"
      :key="i"
    >
      <package-spec-card
        v-if="AppFeatureType !== 0 && AppFeatureType !== 2"
        :countNum="InitialQuotaPack + AddionalQuotaPacks"
        :initialQuota="InitialQuotaPack"
        :name="StepName.ArabicValue"
        :Id="Id"
        :additionalPrice="QuotaPackPrice"
        additionalName="وحدة"
        :loading="loadingUpdate"
        :currency="currency"
        :cycleType="cycleType"
        :AddionalQuotaPacks="AddionalQuotaPacks"
        :BillingFrequency="BillingFrequency"
        :otherProps="otherProps"
        @change="
          (counter) =>
            $emit('update', { type: AppFeatureType, Id, value: counter })
        "
      />
      <package-spec-card-toggling
        v-else
        :Id="Id"
        :AddionalQuotaPacks="AddionalQuotaPacks"
        :name="StepName.ArabicValue"
        :additionalPrice="QuotaPackPrice"
        :loading="loadingUpdate"
        :currency="currency"
        :cycleType="cycleType"
        :BillingFrequency="BillingFrequency"
        :otherProps="otherProps"
        @toggle="
          (isAdded) =>
            $emit('update', { type: AppFeatureType, Id, value: isAdded })
        "
      />
    </template>
  </section>
</template>

<script>
import PackageSpecCardToggling from "./PackageSpecCardToggling.vue";
import PackageSpecCard from "./PackageSpecCard.vue";

export default {
  components: {
    PackageSpecCard,
    PackageSpecCardToggling,
  },
  props: {
    features: Array,
    FeaturesGroup: Array,
    loadingUpdate: Boolean,
    currency: String,
    cycleType: Number,
  },
};
</script>

<style>
</style>