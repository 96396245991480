<template>
  <div class="subscriptions-table audit-trail-table">
    <template v-if="!isLoading">
      <table-lite
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :total="totalRecordCount"
        :is-static-mode="true"
        :sortable="sortable"
        :pageOptions="[
          { value: 25, text: 25 },
          { value: 50, text: 50 },
          { value: 75, text: 75 },
        ]"
        @do-search="$emit('doSearch')"
        @row-clicked="(row) => $emit('subscription-clicked', row)"
        @is-finished="tableLoadingFinish"
        :messages="messages"
        :class="totalRecordCount > 10 ? 'has-pagination' : ''"
      >
      </table-lite>
    </template>
    <audit-trail-table-loading v-else />
  </div>
</template>

<script>
import { formatDate } from "@/util/date";
import TableLite from "vue3-table-lite";
import AuditTrailTableLoading from "./AuditTrailTableLoading.vue";

export default {
  components: {
    TableLite,
    AuditTrailTableLoading,
  },
  props: {
    data: Array,
    dataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataId: "",
      isLoading: this.dataLoading,
      columns: [
        {
          label: "المستخدم",
          field: "UserName",
          sortable: true,
        },
        {
          label: "الوصف",
          field: "Description",
          sortable: true,
        },
        {
          label: "وقت العملية",
          field: "TimeStamp",
          isKey: true,
          sortable: true,
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "Number",
        sort: "asc",
      },
      messages: {
        pagingInfo: "عرض {0}-{1} من {2}",
        pageSizeChangeLabel: "عدد الصفوف:",
        gotoPageLabel: "الذهاب للصفحة:",
        noDataAvailable: "لا يوجد اي بيانات",
      },
    };
  },
  watch: {
    dataLoading(newVal) {
      this.isLoading = newVal;
    },
    data(newVal) {
      this.setRows(newVal);
    },
  },
  mounted() {
    this.setRows(this.data);
  },
  methods: {
    setRows(data) {
      this.rows = data;
      this.totalRecordCount = data.length;
      this.isLoading = false;
    },
    tableLoadingFinish(elements) {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
.audit-trail-table .vtl-tbody-td span {
  max-width: 50rem;
}
</style>