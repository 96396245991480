<template>
  <div class="plans-page">
    <div class="container">
      <header class="plans-page__header">
        <h3 class="plans-page__header-title">باقات دفاتر</h3>
        <div class="plans-page__header-action">
          <router-link to="/create-plan" class="btn btn-success btn-icon">
            <i class="fas fa-plus"></i>
            إنشاء باقة جديدة
          </router-link>
        </div>
      </header>
      <section class="plans-page__plans grid grid-1x2">
        <plan-card v-for="plan in plans" :plan="plan" :key="plan.Id" />
      </section>
    </div>
  </div>
</template>

<script>
import PlanCard from "@/components/plans/PlanCard.vue";
import PlansService from "@/services/PlansService";
export default {
  components: { PlanCard },
  data() {
    return {
      plans: null,
    };
  },
  mounted() {
    this.getAllPlans();
  },
  methods: {
    async getAllPlans() {
      this.plans = await PlansService.getAllPlans();
    },
  },
};
</script>

<style lang="scss">
.plans-page {
  .container {
    max-width: 70%;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    //title
    &-title {
      color: #0a5322;
      opacity: 0.75;
      font-size: 2.6rem;
      font-weight: bolder;
    }
    //button
    .btn {
      border-radius: 7px;
      margin: 0.5rem 0;
      width: 100%;
      padding: 1.5rem;

      &.btn-icon {
        position: relative;
        text-align: right;
        padding-right: 7rem;
        overflow: hidden;

        i {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 5rem;
          height: 100%;
          background-color: rgba(80, 80, 80, 0.4392156863);
          color: #505050;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>