import http from "./httpService";

const getPartners = async () => {
  return await http.get(
    `/promo-codes?pageIndex=0&pageCount=1000&orderBy=Id&ascending=true`
  );
};

const getPartnerDetails = async (partnerId) => {
  return await http.get(`/partners/${partnerId}`);
};

const createPartner = async (partner) => {
  //   {
  //     "Code": "TEST-uudfghd4",
  //     "Description": {
  //         "ArabicValue": "TEST 7345",
  //         "EnglishValue": "TEST 7345"
  //     },
  //     "ExpiryDate": "2030-10-28T00:00:00",
  //     "ExpiryDateFrom": "2015-10-28T00:00:00",
  //     "Status": 0,
  //     "PartnerName": {
  //         "ArabicValue": "Abdelrahman",
  //         "EnglishValue": "Abdelrahman"
  //     },
  //     "CompanyName": {
  //         "ArabicValue": "XYZ",
  //         "EnglishValue": "XYZ"
  //     },
  //     "Email": "a.kinani@dafater.sa",
  //     "Address": "Hello",
  //     "Phone": 54545555
  // }
  return await http.post(`/promo-codes`, partner);
};

const removePartner = async (partnerId) => {
  return await http.delete(`/promo-codes/${partnerId}/delete`);
};

const updatePartner = async (partner) => {
  return await http.put(`/partners/${partner.Id}`, partner);
};

const createPromoCode = async (partnerId, servicePlansDiscounts) => {
  return await http.post(
    `/partner/${partnerId}/promo-code`,
    servicePlansDiscounts
  );
};

const generatePromoCode = async () => {
  return await http.get(`/promo-codes/generate`);
};

const unAssignServicePlan = async (id, servicePlanId) => {
  return await http.put(
    `/promo-codes/${id}/unassign-service-plan/${servicePlanId}`
  );
};
const updatePromoPlanCommision = async (data) => {
  return await http.put(`/promo-codes/assign-service-plan`, data);
};
export default {
  getPartners,
  generatePromoCode,
  getPartnerDetails,
  createPartner,
  removePartner,
  updatePartner,
  createPromoCode,
  unAssignServicePlan,
  updatePromoPlanCommision,
};

const partners = [
  {
    Id: 1,
    Code: "DLP121",
    Description: {
      ArabicValue: "الأساسية مع تركيب مجاني",
      EnglishValue: "Basic with Free Setup",
    },
    ExpiryDate: null,
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 1,
        ServicePlan_Id: 4,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 2,
    Code: "DLP221",
    Description: {
      ArabicValue: "الأساسية بإشتراك سنوي مخفض",
      EnglishValue: "Basic with Discounted Yearly Subscription",
    },
    ExpiryDate: null,
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 2,
        ServicePlan_Id: 5,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 3,
    Code: "sadad_offer",
    Description: {
      ArabicValue: "الباقة الأساسية مخفضة مع تركيب وتدريب مجانيين",
      EnglishValue: "Discounted Basic Plan with Setup and Training",
    },
    ExpiryDate: null,
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 3,
        ServicePlan_Id: 6,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 4,
    Code: "SD01",
    Description: {
      ArabicValue: "Elite Promo Code",
      EnglishValue: "Elite Promo Code",
    },
    ExpiryDate: null,
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 4,
        ServicePlan_Id: 7,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 5,
    Code: "INC01",
    Description: {
      ArabicValue: "Evaluation Promo Code",
      EnglishValue: "Evaluation Promo Code",
    },
    ExpiryDate: null,
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 5,
        ServicePlan_Id: 8,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 7,
    Code: "GITR_STC",
    Description: {
      ArabicValue: "STC Promo Code",
      EnglishValue: "STC Promo Code",
    },
    ExpiryDate: null,
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 7,
        ServicePlan_Id: 10,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 8,
    Code: "sban5571",
    Description: {
      ArabicValue: "SBAN 5571",
      EnglishValue: "SBAN 5571",
    },
    ExpiryDate: "2015-10-28T00:00:00",
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 8,
        ServicePlan_Id: 11,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 9,
    Code: "sban2960",
    Description: {
      ArabicValue: "SBAN 2960",
      EnglishValue: "SBAN 2960",
    },
    ExpiryDate: "2015-10-28T00:00:00",
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 9,
        ServicePlan_Id: 11,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 10,
    Code: "sban6500",
    Description: {
      ArabicValue: "SBAN 6500",
      EnglishValue: "SBAN 6500",
    },
    ExpiryDate: "2015-10-28T00:00:00",
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 10,
        ServicePlan_Id: 11,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
  {
    Id: 11,
    Code: "sban1757",
    Description: {
      ArabicValue: "SBAN 1757",
      EnglishValue: "SBAN 1757",
    },
    ExpiryDate: "2015-10-28T00:00:00",
    ExpiryDateFrom: null,
    Status: 0,
    PartnerName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    CompanyName: {
      ArabicValue: null,
      EnglishValue: null,
    },
    Email: null,
    Address: null,
    Phone: null,
    PromotionCodeServicePlans: [
      {
        PromotionCode_Id: 11,
        ServicePlan_Id: 11,
        PartnerDiscountPercentage: null,
        PartnerDiscountAmount: null,
      },
    ],
  },
];
