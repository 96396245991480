<template>
  <div class="profile container">
    <FormKit
      type="form"
      id="form"
      ref="form"
      :actions="false"
      v-model="formData"
      :disabled="disabled"
    >
      <div class="grid grid-1x2" v-if="Object.keys(user).length">
        <div>
          <!-- column -->
          <div class="form-group">
            <FormKit
              name="Name"
              v-model="user.Name"
              type="text"
              label="الاسم"
              validation="required"
              outer-class="half-field"
            />
          </div>
          <div class="form-group">
            <FormKit
              name="Email"
              v-model="user.Email"
              type="email"
              label="البريد الإلكتروني"
              validation="required|email"
              outer-class="half-field"
            />
          </div>
          <!-- <div class="form-group formkit-wrapper">
            <label>الحالة</label>
            <div class="formkit-inner">
              <span>
                {{ user.Status }}
              </span>
            </div>
          </div>-->
          <div class="form-group" v-if="user.Tenant">
            <FormKit
              v-model="user.Tenant.Name"
              name="CompanyName"
              type="text"
              label="اسم الشركة"
              validation="required"
              outer-class="half-field"
            />
          </div>
          <div class="form-group formkit-wrapper">
            <label>الدور</label>
            <div class="formkit-inner">
              <span>{{ user.Roles }} </span>
            </div>
          </div>
          <!-- ..column -->
        </div>
        <div>
          <!-- column -->
          <div class="form-group">
            <FormKit
              type="select"
              name="region"
              label="المنطقة"
              :options="{
                0: 'المملكة العربية السعودية',
                1: 'الولايات المتحدة',
                2: 'المملكة المتحدة',
                3: 'ليبيا',
                4: 'قطر',
                5: 'الامارات العربية المتحدة',
              }"
            />
          </div>
          <div class="form-group">
            <FormKit
              type="select"
              name="state"
              label="المدينة"
              :options="{
                0: 'الدمام',
                1: 'الرياض',
                2: 'الطائف',
                3: 'مكة',
              }"
            />
          </div>
          <div class="form-group">
            <FormKit
              v-model="user.Address"
              name="strict"
              type="text"
              label="العنوان"
              validation=""
              outer-class="half-field"
            />
          </div>
          <div class="form-group">
            <FormKit
              v-model="user.MobileNumber"
              name="MobileNumber"
              type="text"
              label="الجوال"
              validation=""
            />
          </div>
          <!-- /column -->
          <div class="actions">
            <button
              class="btn btn-warning btn-icon"
              v-if="disabled"
              @click="disabled = false"
            >
              <i class="fas fa-edit"></i>
              تعديل
            </button>
            <template v-else>
              <button type="button" class="btn btn-success" @click="submitForm">
                حفظ
              </button>
              <button class="btn btn-success" @click="disabled = true">
                الغاء
              </button>
            </template>
          </div>
        </div>
      </div>
    </FormKit>
    <button class="btn btn-danger" @click="instance.logoutRedirect()">
      تسجيل خروج
    </button>
    <div style="position: absolute; bottom: 2rem; left: 3rem">
      Version {{ version }}
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import { useMsal } from "@/composition-api/useMsal";
export default {
  setup() {
    const { accounts, instance } = useMsal();
    return { accounts, instance };
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      disabled: true,
      user: {
        Name: "",
        Email: "",
        Status: "",
        CompanyName: "",
        role: "",
      },
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async getUser() {
      const { data: profile } = await UserService.getProfile();
      this.user = profile;
      this.user.Roles = UserService.getRole(profile.Roles[0].Name);
    },
    submitForm() {
      this.$formkit.submit("form");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 2rem;
}
</style>