import http from "./httpService";

const getAllAuditTrail = async (filters = "") => {
  return await http.get(`/account/audit-trail?pageCount=500&pageIndex=0`);
};
const searchingFor = async (searchParam) => {
  return await http.get(
    `/account/audit-trail?pageCount=100&pageIndex=1&searchString=${searchParam}`
  );
};

export default {
  getAllAuditTrail,
  searchingFor,
};
