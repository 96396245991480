import axios from "axios";
import { msalInstance } from "@/authConfig";
import router from "@/router";
import Api from "@meforma/vue-toaster/src/api";
import store from "@/store";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    common: {
      "Access-Control-Allow-Origin": "*",
    },
  },
});

const onDownloadProgress = (progressEvent) => {
  // let percentCompleted = Math.floor(progressEvent.loaded / progressEvent.total);
  // console.log("handling download progress event here:>", progressEvent);
  // console.log(progressEvent.currentTarget);
  // const total = parseFloat(
  //   progressEvent.currentTarget.getResponseHeader("Content-Length")
  // );
  // console.log(progressEvent.srcElement.getAllResponseHeaders());
  // const current = progressEvent.currentTarget.response.length;
  // let percentCompleted = Math.floor((current / total) * 100);
  // console.log("completed: ", percentCompleted);
};
const onUploadProgress = (progressEvent) => {
  // console.log("handling uplaod progress event here:>", progressEvent);
};

HTTP.interceptors.request.use(
  (config) => {
    config.headers["NameIdentifier"] =
      msalInstance.getAllAccounts()[0]?.localAccountId;
    // config.headers["NameIdentifier"] = "bd8e2106-8cd7-401a-a06c-1593ab9ae79e"; // will come from auth service
    // msalInstance.getAllAccounts()[0]?.localAccountId;
    config.headers["ServiceId"] = store.state.serviceId;
    config.headers["Access-Control-Allow-Origin"] = "*";
    return {
      ...config,
      onUploadProgress: config.onUploadProgress || onUploadProgress,
      onDownloadProgress: config.onDownloadProgress || onDownloadProgress,
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);
HTTP.interceptors.response.use(null, (error) => {
  //handling error => log it or what ever
  console.log(error.response);
  if (
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500
  ) {
    // Api().error(error.response.data.Message);
    if (error.response.status == 401) router.replace("/not-authed");
    if (error.response.status == 402)
      Api().error("لا يوجد رصيد لإتمام العملية!");
    else Api().error(error.response.data.Message);
    return Promise.reject(error);
  } else {
    console.log("Loggin the error", error);
    alert("An unexpected error occurred");
  }
});

export default {
  get: HTTP.get,
  post: HTTP.post,
  put: HTTP.put,
  delete: HTTP.delete,
  HTTP,
};

// try {
//   // success
// } catch (ex) {
//   if (ex.reponse && ex.reponse.status === 404) alert("not found");
//   else {
//     console.log("Loggin the error to enhance qaulity", ex);
//     alert("An unexpected error occurred");
//   }
// }

// public enum SubscriptionStatus
// {
// 1: Processing,
// 2: Provisioning,
// 3: Active,
// 4: Suspending,
// 5: Suspended,
// 6: Activating,
// 7: Deprovisioning,
// 8: Deprovisioned,
// 9: UnPaid,
// 10: UnPaidDeclined,
// 11: UnPaidFailed,
// 12: UnderChangingPlan,
// 13: OperationFailed,
// 14: Expired
// }
