<template>
  <FormKit
    type="form"
    id="partnerForm"
    ref="partnerForm"
    :actions="false"
    v-model="formData"
    #default="{ state }"
  >
    <h3 class="c-danger" v-if="!state.valid && submited">
      من فضلك املاء البيانات بشكل صحيح
    </h3>
    <div class="form-section mx-0">
      <h3 class="form-section__title">بيانات العميل</h3>
      <div class="grid grid-1x3">
        <div class="form-group">
          <FormKit
            name="PartnerName"
            type="text"
            label="اسم الشريك"
            validation="required"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="CompanyName"
            type="text"
            label="اسم الشركة"
            validation="required"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="Email"
            type="email"
            label="البريد الإلكتروني"
            validation="required|email"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="Phone"
            type="tel"
            label="الهاتف"
            validation="required|matches:/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/"
            :validation-messages="{
              matches: 'من فضلك ادخل رقم جوال صحيح',
            }"
            outer-class="half-field"
          />
        </div>
        <div class="form-group">
          <FormKit
            name="Description"
            type="text"
            label="وصف الكود الترويجي"
            validation="required"
            outer-class="half-field"
          />
        </div>
        <h3
          class="form-section__title"
          style="grid-column-start: 1; grid-column-end: 4"
        >
          حدد مدة الرمز الترويجي
        </h3>

        <div class="from-group">
          <FormKit
            type="date"
            label="من"
            name="from"
            validation="required"
            validation-visibility="live"
          />
        </div>
        <div class="from-group">
          <FormKit
            type="date"
            label="إلى"
            name="to"
            validation="required"
            validation-visibility="live"
          />
        </div>
      </div>
      <button
        v-if="hasSubmitBtn"
        class="btn btn-info"
        @click.prevent="$emit('handleSubmit', () => this.validateForm())"
      >
        إضافة شريك
      </button>
    </div>
  </FormKit>
  <div class="loader loader-page-layout" v-if="loadingSpinner"></div>
</template>

<script>
import ClientService from "@/services/ClientService";
export default {
  props: {
    hasSubmitBtn: Boolean,
    loading: Boolean,
    partnerData: Object,
  },
  emits: ["handleSubmit"],
  data() {
    return {
      partner: {
        name: "",
        companyName: "",
        email: "",
        phone: "",
        promoCode: "",
      },
      formData: {},
      submited: false,
      loadingSpinner: false,
    };
  },
  mounted() {
    if (!this.hasSubmitBtn) {
      this.$emit("handleSubmit", () => this.validateForm());
    }
    this.formData = this.partnerData;
  },
  watch: {
    loading(to) {
      this.loadingSpinner = to;
    },
  },
  methods: {
    validateForm() {
      const valid = this.$refs["partnerForm"].node.context.state.valid;
      this.submited = true;
      return {
        valid,
        formData: { ...this.formData },
      };
    },
  },
};
</script>

<style>
</style>