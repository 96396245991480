<template>
  <div class="invoice-summary__card">
    <h3>الملخص</h3>
    <div class="invoice-card__details">
      <div
        class="invoice-card__row"
        v-if="subscriptionType !== 'configuration'"
      >
        <div class="invoice-card__row-key">سعر الباقة</div>
        <div
          class="invoice-card__row-val"
          v-if="Object.keys(transaction).length > 0"
        >
          {{ transaction.ServicePlanTotalPrice }}
        </div>
        <div class="invoice-card__row-val" v-else>
          <skeleton-text effect="wave">24000</skeleton-text>
        </div>
      </div>
      <template v-for="feature in features" :key="feature.Id">
        <div
          class="invoice-card__row"
          v-if="feature.AddionalQuotaPackTotalPrice"
        >
          <div class="invoice-card__row-key">
            {{ feature.StepName.ArabicValue }}
            <template v-if="feature.AppFeatureType == 3">
              <span style="display: inline-flex">
                <span>x</span>
                {{ feature.AddionalQuotaPacks }}
              </span>
            </template>
          </div>
          <div class="invoice-card__row-val">
            {{ feature.AddionalQuotaPackTotalPrice }}
          </div>
        </div>
      </template>
      <div
        class="invoice-card__row"
        v-if="subscriptionType !== 'configuration'"
      >
        <div class="invoice-card__row-key">سعر الاشتراك</div>
        <div
          class="invoice-card__row-val"
          v-if="Object.keys(transaction).length > 0"
        >
          {{ transaction.NetTotalBeforeDiscount }}
        </div>
        <div class="invoice-card__row-val" v-else>
          <skeleton-text effect="wave">24002400</skeleton-text>
        </div>
      </div>
      <div
        class="invoice-card__row"
        v-if="transaction.NetTotal !== transaction.NetTotalBeforeDiscount"
      >
        <div class="invoice-card__row-key">قيمة الخصم</div>
        <div class="invoice-card__row-val">
          <template v-if="Object.keys(transaction).length > 0">
            {{ transaction.DiscountAmount }}
          </template>
          <template v-else>
            <skeleton-text effect="wave">3400</skeleton-text>
          </template>
        </div>
      </div>
      <div class="invoice-card__row">
        <div
          class="invoice-card__row-key"
          v-if="Object.keys(transaction).length > 0"
        >
          ضريبة القيمه المضافة {{ transaction.ServicePlan.Tax }}%
        </div>
        <div class="invoice-card__row-val">
          <template v-if="Object.keys(transaction).length > 0">
            {{ transaction.TaxAmount }}
          </template>
        </div>
      </div>
    </div>
    <!-- <div
      class="invoice-card__row"
      v-if="transaction.NetTotal !== transaction.NetTotalBeforeDiscount"
    >
      <div class="invoice-card__row-key">السعر قبل الخصم</div>
      <div class="invoice-card__row-val">
        <template v-if="Object.keys(transaction).length > 0">
          {{ transaction.NetTotalBeforeDiscount }}
        </template>
        <template v-else>
          <skeleton-text effect="wave">3400</skeleton-text>
        </template>
      </div>
    </div> -->
    <div class="invoice-card__row">
      <div class="invoice-card__row-key">الإجمالي</div>
      <div class="invoice-card__row-val">
        <template v-if="Object.keys(transaction).length > 0">
          {{ transaction.GrandTotal }}
        </template>
        <template v-else>
          <skeleton-text effect="wave">350000</skeleton-text>
        </template>
      </div>
    </div>

    <div class="invoice-card__row">
      <div class="invoice-card__row-key">الرصيد الحالي</div>
      <div class="invoice-card__row-val">
        <template v-if="Object.keys(transaction).length > 0">
          {{
            (transaction.GrandTotal - transaction.TransactionPaymentAmount) %
              1 !=
            0
              ? (
                  transaction.GrandTotal - transaction.TransactionPaymentAmount
                ).toFixed(2)
              : transaction.GrandTotal - transaction.TransactionPaymentAmount
          }}
        </template>
        <template v-else>
          <skeleton-text effect="wave">3400</skeleton-text>
        </template>
      </div>
    </div>

    <template v-if="transaction.ServicePlan">
      <template
        v-if="transaction.ServicePlan.HasDiscountCoupon && enableDicountInput"
      >
        <div class="field my-2">
          <label class="checkbox-label flex">
            <div class="custom-checkbox">
              <input type="checkbox" v-model="showDiscountField" />
              <i class="far fa-check"></i>
            </div>
            <span> إضافة كوبون خصم </span>
          </label>
        </div>
        <div class="invoice-card__row" v-if="showDiscountField">
          <div class="key" style="white-space: nowrap; margin-left: 20px">
            قسيمة خصم!
          </div>
          <div class="val flex">
            <!-- <input type="text" v-model="discountCode" /> -->
            <div class="loader inline-loader" v-if="loading"></div>
            <select v-model="discountCode" style="padding-left: 3.5rem">
              <option
                v-for="dc in discountCoupons"
                :key="dc.Id"
                :value="dc.Code"
              >
                {{ dc.Code }}
              </option>
            </select>
            <button
              class="btn btn-info"
              type="button"
              :disabled="!discountCode.length"
              style="width: auto; padding: 1rem 1.5rem; font-size: 1.5rem"
              @click="$emit('applyCoupon', discountCode)"
            >
              أضف
            </button>
          </div>
        </div>
      </template>
    </template>
    <!-- {{GrandTotal}} -->
    <div class="invoice-card__row total-price">
      <div class="key">المطلوب سداده</div>
      <div class="val">
        <div
          class="loader loader-page-layout"
          v-if="loadingUpdate && Object.keys(transaction).length > 0"
        ></div>
        <span class="price">
          <template v-if="Object.keys(transaction).length > 0">
            {{ transaction.TransactionPaymentAmount }}
          </template>
          <template v-else>
            <skeleton-text effect="wave">240000</skeleton-text>
          </template>
        </span>
        <!-- TransactionPaymentAmount -->
        {{ currency }}
      </div>
    </div>
    <!-- {{ transaction.ServicePlan }} -->
    <template v-if="Object.keys(transaction).length">
      <a
        @click="
          transaction.GrandTotal
            ? submitAction()
            : subscriptionType == 'creation'
            ? submitAction()
            : null
        "
        class="btn btn-success"
      >
        {{ subscriptionType == "creation" ? "اشترك" : "تعديل" }}</a
      >
    </template>
  </div>
  <manage-modal
    @modalStatus="(cb) => (addBalanceModalCB = cb())"
    btnSuccess="اضف الرصيد"
    class="add-balance-modal"
  >
    <template #header> أضف رصيد </template>
    <template #body>
      <div class="form-group">
        <label for="balance">القيمة</label>
        <div class="grid grid-3/1">
          <input type="text" id="balance" v-model="balance" />
          <select name="currency" id="currency">
            <option value="0">ريال</option>
            <option value="1">جنيه</option>
            <option value="2">دولار</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="payment_method">طريقة الدفع</label>
        <select v-model="paymentMethodType" id="payment_method">
          <option value="0">نقدا</option>
          <option value="1">شيك</option>
          <option value="2">حساب بنكي</option>
        </select>
      </div>
      <div
        class="form-group"
        v-if="paymentMethodType == 1 || paymentMethodType == 2"
      >
        <label for="transactionId">رقم العملية</label>
        <input type="text" v-model="transactionId" id="transactionId" />
      </div>
    </template>
  </manage-modal>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
import { getCurrency, Currencies } from "@/util/utilities";
import SubscriptionService from "@/services/SubscriptionService";
import ClientService from "@/services/ClientService";
import ManageModal from "@/components/common/ManageModal.vue";
import { generateId } from "@/util/utilities";
export default {
  components: { SkeletonText, ManageModal },
  props: {
    transaction: Object,
    features: Array,
    loadingUpdate: Boolean,
    disabled: Boolean,
    subscriptionType: String,
    enableDicountInput: Boolean,
  },
  data() {
    return {
      currency: null,
      displayedTransaction: null,
      discountCode: "",
      showDiscountField: false,
      discountCoupons: null,
      addBalanceModalCB: null,
      balance: null,
      paymentMethodType: null,
      transactionId: null,
      loading: false,
    };
  },
  mounted() {
    if (this.transaction) {
      this.handleDisplayingTransaction(this.transaction);
    }
  },
  watch: {
    transaction(newVal) {
      this.handleDisplayingTransaction(newVal);
    },
    loadingUpdate(newValue) {},
    disabled(newValue) {},
    balance(to) {
      if (to < this.transaction.TransactionPaymentAmount)
        this.balance = this.transaction.TransactionPaymentAmount;
    },
  },
  methods: {
    handleDisplayingTransaction(transaction) {
      this.displayedTransaction = transaction;
      if (!this.displayedTransaction.ServicePlan) {
        this.displayedTransaction.ServicePlan =
          this.displayedTransaction.Subscription.ServicePlan;
      }
      this.getCurrency();
      if (
        this.enableDicountInput &&
        this.transaction.ServicePlan.HasDiscountCoupon
      ) {
        this.getDiscountCoupons();
      }
    },
    async getDiscountCoupons() {
      this.loading = true;
      try {
        const response = await SubscriptionService.getDiscountCoupons(
          this.transaction.ServicePlan.Id
        );
        if (response && response.status == 200) {
          this.loading = false;
          this.discountCoupons = response.data;
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    pay() {
      if (this.disabled || this.displayedTransaction == null) return;
      this.$emit("pay");
    },
    getCurrency() {
      const { ServicePlan } = this.displayedTransaction;
      if (!ServicePlan) {
        this.displayedTransaction.ServicePlan =
          this.displayedTransaction.Subscription.ServicePlan;
      }
      this.currency = Currencies[ServicePlan.Currency].ar;
      // return getCurrency();
    },
    submitAction() {
      this.$emit("subscribe");
    },
    async addBalance(amount) {
      if (this.paymentMethodType == null || !amount) return;
      if (
        (this.paymentMethodType == 1 || this.paymentMethodType == 2) &&
        !this.transactionId
      )
        return;
      this.addBalanceModalCB(null, false);
      const TransactionId = this.transactionId
        ? this.transactionId
        : generateId();
      // add balance to client
      this.loading = true;
      try {
        const response = await ClientService.depositBalance(
          this.transaction.Subscriber.Id,
          amount,
          this.paymentMethodType,
          TransactionId
        );
        if (response.status == 200) {
          this.loading = false;
          this.$toast.success("تم إضافة الرصيد بنجاح");
          this.$emit("subscribe");
        } else {
          this.loading = false;
          this.$toast.error("نأسف هناك خطأ ما");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("نأسف هناك خطأ ما");
      }
    },
  },
};
</script>

<style>
</style>