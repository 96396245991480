<template>
  <div class="container">
    <div class="flex-between mb-4">
      <h2>شركاء دفاتر</h2>
      <router-link to="/create-partner">
        <button class="btn btn-danger btn-icon">
          <i class="fas fa-plus-circle"></i>
          إضافة شريك
        </button>
      </router-link>
    </div>
    <partners-table
      :partners="partners"
      :dataLoading="dataLoading"
      @partner-clicked="(row) => $router.push({ path: `/partners/${row.Id}` })"
    />
  </div>
</template>

<script>
import PartnersTable from "@/components/partners/PartnersTable.vue";
import PartnerService from "@/services/PartnerService";
export default {
  components: { PartnersTable },
  data() {
    return {
      partners: [],
      dataLoading: false,
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    async getPartners() {
      this.dataLoading = true;
      const partnersResonse = await PartnerService.getPartners();
      if (partnersResonse && partnersResonse.status == 200) {
        this.partners = partnersResonse.data.map((p) => ({
          ...p,
          PartnerName: p.PartnerName.ArabicValue
            ? p.PartnerName.ArabicValue
            : `partner ${p.Id}`,
          CompanyName: p.CompanyName.ArabicValue
            ? p.CompanyName.ArabicValue
            : `company name ${p.Id}`,
          Email: p.Email ? p.Email : "--",
          Phone: p.Phone ? p.Phone : "--",
        }));
        this.dataLoading = false;
      }
      // this.partners = [
      //   {
      //     Id: 1,
      //     Name: "mohamed moniem",
      //     CompanyName: "moniemawy",
      //     Email: "moniem@dev.com",
      //     Phone: "01111798451",
      //     PromoCode: "#ramdan_moniem",
      //   },
      // ];
      // setTimeout(() => {
      //   this.dataLoading = false;
      // }, 3000);
    },
  },
};
</script>

<style>
</style>