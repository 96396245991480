<template>
  <!-- <button @click="isModalVisible = true" class="btn btn-primary btn-icon">
    <i class="far fa-eye"></i>
    عرض
  </button> -->
  <m-modal
    v-show="isModalVisible"
    :hasHeader="false"
    @close="isModalVisible = false"
  >
    <!-- <template #header> {{ invoice.Id }} تفاصيل الفاتورة </template> -->
    <template #body>
      <div class="grid grid3-1">
        <div class="print-modal__action">
          <button class="btn btn-primary btn-icon" @click="generateReport()">
            <i class="far fa-print"></i>طباعة
          </button>
          <button class="btn btn-light btn-icon" @click="generateReport()">
            <i class="fal fa-file-pdf"></i>PDF
          </button>
          <div class="spacer-5"></div>
          <button class="btn btn-dark btn-icon" @click="isModalVisible = false">
            <i class="far fa-times-circle"></i>الغاء
          </button>
        </div>
        <div class="print-modal__print" id="printMe">
          <!-- header -->
          <div class="print-modal__print-header">
            <div class="logo">
              <img src="@/assets/images/logo.png" alt="Dafater Sa" />
            </div>
            <div class="print-modal__print-title">
              <h1 class="heading">فاتورة اشتراك</h1>
              <h2 class="number">#{{ invoice.InvoiceId }}</h2>
            </div>
          </div>
          <!-- .end header  -->
          <!-- company info -->
          <div class="company-info">
            <h3>دفاتر لحلول الاعمال</h3>
            <p>
              السعودية , الرياض<br />
              بناية 12 الدور السادس<br />
              التقسيم الأول
            </p>
          </div>
          <!-- .end company info -->
          <div class="invoice-dates">
            <!-- date item -->
            <div class="date-item">
              <div class="invoice-dates__key">
                <i class="fas fa-calendar-alt"></i>
                تاريخ دورة الفاتورة
              </div>

              <div class="invoice-dates__date">
                <template v-if="invoice.Date">{{ invoice.Date }}</template>
                <template v-else> -- </template>
              </div>
            </div>
            <!-- .end date item -->
            <!-- date item -->
            <div class="date-item">
              <div class="invoice-dates__key">
                <i class="fas fa-calendar-alt"></i>
                تاريخ التسديد
              </div>

              <div class="invoice-dates__date">
                <template v-if="invoice.DateTo">{{ invoice.DateTo }}</template>
                <template v-else> -- </template>
              </div>
            </div>
            <!-- .end date item -->
            <div class="break"></div>
            <!-- date item -->
            <div class="date-item">
              <div class="invoice-dates__key">
                <i class="fas fa-calendar-alt"></i>
                إلى
              </div>

              <div class="invoice-dates__date">
                <template v-if="invoice.DateTo">{{ invoice.DateTo }}</template>
                <template v-else> -- </template>
              </div>
            </div>
            <!-- .end date item -->
          </div>
          <!-- /.end invoice dates -->
          <!-- invoices tables -->
          <table>
            <thead>
              <tr>
                <th>الرقم</th>
                <th>اسم الخدمه</th>
                <th>العدد</th>
                <th>سعر الوحدة</th>
                <th>الاجمالي</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(row, i) in invoice.BillingRecordItems"
                :key="row.Id"
              >
                <tr v-if="row.Amount">
                  <td>
                    {{ i + 1 }}
                  </td>
                  <td class="fw-bl text-right">
                    {{ row.Name.ArabicValue }}
                  </td>
                  <td>---</td>
                  <td>---</td>
                  <td>{{ row.Amount }} <br />{{ currency }}</td>
                </tr>
              </template>
              <tr class="bg-white" v-if="invoice.BillingRecordItems">
                <td></td>
                <td></td>
                <td colspan="3">
                  <table>
                    <tr>
                      <td>المجموع الصافي</td>
                      <td>
                        {{
                          getTotalAmountWithoutTax(invoice.BillingRecordItems)
                        }}
                        <br />{{ currency }}
                      </td>
                    </tr>
                    <tr>
                      <td>ضريبة القيمة المضافة</td>
                      <td>
                        {{ getTaxAmount(invoice.BillingRecordItems) }} <br />{{
                          currency
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>المجموع الاجمالي المطلوب</td>
                      <td>{{ invoice.Amount }} <br />{{ currency }}</td>
                    </tr>
                    <tr>
                      <td>الرصيد المستحق</td>
                      <td>{{ invoice.Amount }} <br />{{ currency }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th class="px-1 text-right">ملاحظات</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th class="px-1 text-right">الشروط والاحكام</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
          <!-- ./end invoices tables -->
        </div>
      </div>
    </template>
  </m-modal>
  <vue3-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="true"
    :paginate-elements-by-height="1400"
    :filename="`فاتورة اشتراك ${invoice.InvoiceId}`"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="800px"
    @progress="onProgress($event)"
    @hasStartedGeneration="hasStartedGeneration()"
    @hasGenerated="hasGenerated($event)"
    @beforeDownload="beforeDownload($event)"
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <div class="print-modal__print" id="printMe">
        <!-- header -->
        <div class="print-modal__print-header">
          <div class="logo">
            <img src="@/assets/images/logo.png" alt="Dafater Sa" />
          </div>
          <div class="print-modal__print-title">
            <h1 class="heading">فاتورة اشتراك</h1>
            <h2 class="number">#{{ invoice.InvoiceId }}</h2>
          </div>
        </div>
        <!-- .end header  -->
        <!-- company info -->
        <div class="company-info">
          <h3>دفاتر لحلول الاعمال</h3>
          <p>
            السعودية , الرياض<br />
            بناية 12 الدور السادس<br />
            التقسيم الأول
          </p>
        </div>
        <!-- .end company info -->
        <div class="invoice-dates">
          <!-- date item -->
          <div class="date-item">
            <div class="invoice-dates__key">
              <i class="fas fa-calendar-alt"></i>
              تاريخ دورة الفاتورة
            </div>

            <div class="invoice-dates__date">
              <template v-if="invoice.Date">{{ invoice.Date }}</template>
              <template v-else> -- </template>
            </div>
          </div>
          <!-- .end date item -->
          <!-- date item -->
          <div class="date-item">
            <div class="invoice-dates__key">
              <i class="fas fa-calendar-alt"></i>
              تاريخ التسديد
            </div>

            <div class="invoice-dates__date">
              <template v-if="invoice.DateTo">{{ invoice.DateTo }}</template>
              <template v-else> -- </template>
            </div>
          </div>
          <!-- .end date item -->
          <div class="break"></div>
          <!-- date item -->
          <div class="date-item">
            <div class="invoice-dates__key">
              <i class="fas fa-calendar-alt"></i>
              إلى
            </div>

            <div class="invoice-dates__date">
              <template v-if="invoice.DateTo">{{ invoice.DateTo }}</template>
              <template v-else> -- </template>
            </div>
          </div>
          <!-- .end date item -->
        </div>
        <!-- /.end invoice dates -->
        <!-- invoices tables -->
        <table>
          <thead>
            <tr>
              <th>الرقم</th>
              <th>اسم الخدمه</th>
              <th>العدد</th>
              <th>سعر الوحدة</th>
              <th>الاجمالي</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(row, i) in invoice.BillingRecordItems"
              :key="row.Id"
            >
              <tr v-if="row.Amount">
                <td>
                  {{ i + 1 }}
                </td>
                <td class="fw-bl text-right">
                  {{ row.Name.ArabicValue }}
                </td>
                <td>---</td>
                <td>---</td>
                <td>
                  {{ row.Amount }}
                  <br />
                  {{ currency }}
                </td>
              </tr>
            </template>
            <tr class="bg-white" v-if="invoice.BillingRecordItems">
              <td></td>
              <td></td>
              <td colspan="3">
                <table>
                  <tr>
                    <td>المجموع الصافي</td>
                    <td>
                      {{ getTotalAmountWithoutTax(invoice.BillingRecordItems)
                      }}<br />{{ currency }}
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>ضريبة القيمة المضافة</td>
                    <td>
                      {{ getTaxAmount(invoice.BillingRecordItems) }}
                    </td>
                  </tr> -->
                  <tr>
                    <td>المجموع الاجمالي المطلوب</td>
                    <td>{{ invoice.Amount }}<br />{{ currency }}</td>
                  </tr>
                  <tr>
                    <td>الرصيد المستحق</td>
                    <td>{{ invoice.Amount }}<br />{{ currency }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th class="px-1 text-right">ملاحظات</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th class="px-1 text-right">الشروط والاحكام</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        <!-- ./end invoices tables -->
      </div>
    </template>
  </vue3-html2pdf>
</template>

<script>
// import Service from "@/services/InvoiceService";
import MModal from "@/components/common/MModal.vue";
import Vue3Html2pdf from "vue3-html2pdf";
export default {
  components: { MModal, Vue3Html2pdf },
  props: {
    dataId: String,
    currency: String,
  },
  mounted() {
    this.$emit("modalStatus", this.controlModalStatus);
  },
  data() {
    return {
      invoice: {},
      isModalVisible: false,
    };
  },
  watch: {},
  methods: {
    controlModalStatus() {
      return (invoice, isShow) => {
        this.invoice = invoice;
        this.isModalVisible = isShow;
      };
    },
    getTotalAmountWithoutTax(arr) {
      return arr.reduce(
        (ac, { Amount, Id }) => (Id !== 21450 ? ac + Amount : ac),
        0
      );
    },
    getTaxAmount(arr) {
      return arr.reduce(
        (ac, { Amount, Id }) => (Id == 21450 ? ac + Amount : ac),
        0
      );
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(e) {
      console.log("in progress:>>>....", e);
    },
    beforeDownload(e) {
      console.log("this is event of has generated:>", e);
    },
  },
};
</script>


<style lang="scss">
.spacer-5 {
  display: block;
  height: 5rem;
}
.print-modal {
  &__action {
    display: flex;
    flex-direction: column;
    order: 1;
    margin: 2rem;
    .btn {
      padding: 1.5rem 4.5rem;
      margin: 1rem 0;
    }
  }
  &__print {
    padding: 3rem;
    border: 1px solid #707070;
    box-shadow: 0px 3px 6px #00000029;
    &-header {
      display: flex;
      justify-content: space-between;
      .logo {
        width: 15rem;
        height: 5rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &-title {
      .heading {
        color: #000;
        font-size: 2.4rem;
        font-weight: normal;
      }
      .number {
        color: #a2aab5;
        font-size: 1.6rem;
      }
    }
    .company-info {
      h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 0.9rem;
        color: #a2aab5;
        line-height: 1.4;
      }
    }
    .invoice-dates {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .break {
        flex-basis: 100%;
        height: 0;
      }
      .date-item {
        display: flex;
        justify-content: space-between;
        min-width: 20%;
        margin: 2rem 0;
        font-size: 0.9rem;
      }
      &__key {
        color: #4e4e4e;
        opacity: 0.8;
        i {
          margin-right: 0.5rem;
        }
      }
      &__date {
        color: #0f0f0f;
      }
    }
    .bg-white {
      background-color: #fff;
    }
    table {
      thead tr {
        background-color: #525c6a;
        height: 2.6rem;
        th {
          font-size: 0.8rem;
          color: #dadada;
          &:nth-child(2) {
            text-align: right;
          }
        }
      }
      th,
      td {
      }
      tbody tr {
        border-bottom: 1px solid #f8f8f8;
        height: fit-content;
        td {
          font-size: 0.9rem;
          padding: 0.8rem 0;
        }
      }
    }
    tbody {
      min-height: 5rem;
      height: 5rem;
    }
  }
}
</style>