<template>
  <div class="package-spec__card">
    <!-- <div class="package-spec__select">
      <FormKit type="checkbox" :value="false" outer-class="$reset" />
    </div> -->
    <div class="w100">
      <div class="flex-between">
        <div class="package-spec__info">
          <h4 class="package-spec__name">
            <skeleton-text effect="wave">name of feature</skeleton-text>
          </h4>
          <p>
            <skeleton-text effect="wave"
              >some text here please don't blame me</skeleton-text
            >
          </p>
        </div>
        <div class="package-spec__counter">
          <!-- <a @click="inc" class="btn btn-primary">
            <i class="fas fa-plus"></i>
          </a>
          <span> <skeleton-text effect="wave">3</skeleton-text> </span>
          <a @click="dec" class="btn btn-primary">
            <i class="fas fa-minus"></i>
          </a> -->
          <skeleton-block height="2rem" width="8rem" effect="wave" />
        </div>
      </div>
      <!-- <p class="mt-2" v-if="moreInfo">
        <skeleton-text effect="wave">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero
          necessitatibus autem saepe
        </skeleton-text>
      </p> -->
    </div>
  </div>
</template>

<script>
import { SkeletonText, SkeletonBlock } from "skeleton-elements/vue";
export default {
  components: { SkeletonText, SkeletonBlock },
};
</script>

<style lang="scss" scoped>
.package-spec {
  &__card {
    border-radius: 10px;
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    padding: 4rem 3rem;
  }
  &__counter {
    display: flex;
    span,
    .btn {
      width: 4rem;
      height: 4rem;
      display: grid;
      align-content: center;
      padding: 0;
      text-align: center;
    }
    span {
      background-color: #fff;
      box-shadow: 0px 3px 6px #00000029;
      font-size: 2rem;
      font-weight: bold;
    }
    .btn {
      border-radius: 2px;
      font-size: 1.5rem;
    }
  }
}
</style>
