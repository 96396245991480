import http from "./httpService";
import { prepareQueryFilters } from "@/util/utilities";
// import { subscriptions, subscriptionDetails } from "./data";

const getAllSubscriptions = async (filter = "", filters = []) => {
  // /subscriptions?filter=""

  // /subscriptions?pageIndex = 0&pageCount = 0& orderBy = ""& ascending = true/false & filter = ""
  // const filters = [
  //   { pageIndex: 0 },
  //   { pageCount: 0 },
  //   { orderBy: "" },
  //   { ascending: true },
  // ];
  // /subscriptions/${id}?bool includeEntitledFeatures = false, bool includeSubscriber = false, bool includeBillingRecords = false, bool includeCloudInstance = false, bool includeSubscriptionHistory = false

  // /subscriptions/number/${subscriptionNo}?bool includeServicePlan = true

  // /subscriptions/number/${subscriptionNo}/history?subscriptionNo=""

  // const { data: subscriptions } = await http.get(`/subscriptions${queries}`);
  const allFilters = filter ? [...filters, { filter }] : filters;
  const queries = prepareQueryFilters(allFilters);
  const { data: subscriptions } = await http.get(`/subscriptions${queries}`);
  // return response;
  return subscriptions;
};

const getSubscriptionDetails = async (Id, filters = []) => {
  // const filters = [
  //   { includeServicePlan: true },
  //   { includeEntitledFeatures: true },
  //   { includeSubscriber: true },
  //   { includeBillingRecords: true },
  //   { includeCloudInstance: true },
  //   { includeSubscriptionHistory: true },
  // ];
  const queries = prepareQueryFilters(filters);
  const { data: subscriptionDetails } = await http.get(
    `/subscriptions/${Id}${queries}`
  );
  return subscriptionDetails;
};

const createSubscription = async (params, body) => {
  // const params = {
  //   subscriberId: ,
  //   transactionId: ,
  //   servicePlanId: ,
  //   promoCode: ,
  // }
  const queryParams = prepareQueryFilters(params);
  const response = await http.post(`/subscriptions${queryParams}`, body);
  return response;
};

const updateSubscription = async (params) => {
  const response = await http.put(
    `/subscriptions?subscriptionConfigurationTransactionId=${params[0].transactionId}`
  );
  return response;
};

const removeSubscription = async (id) => {
  return await http.delete(`/subscriptions/${id}`);
};

const createSubscriptionTransaction = async (
  params,
  subscriptionType = "creation"
) => {
  const queryParams = prepareQueryFilters(params);
  const response = await http.post(
    `/subscription-${subscriptionType}-transactions${queryParams}`
  );
  return response;
};

const getAllServicePlans = async () => {
  return await http.get(`/service-plans`);

  // return await http.get(`/services/{id}/service-plans`);
};
// getAllServicePlans();
const getAllServices = async () => {
  return await http.get("/services");
};
const getServicePlanDetails = async (ServicePlanId) => {
  return await http.get(`/service-plans/${ServicePlanId}`);
};
const getPlanFeatures = async (ServicePlanId) => {
  return await http.get(`/service-plans/${ServicePlanId}/plan-features`);
};
const postFreeDays = async (subscriptionId, value) => {
  const response = await http.post(
    `/subscriptions/${subscriptionId}/extend?days=${value}`
  );
  return response;
};
const changeBillingCycleType = async (transactionId, type) => {
  return await http.put(
    `subscription-creation-transactions/${transactionId}/billing-cycle?type=${type}`
  );
};
const changeSubscriptionStatus = async (subscriptionId, status) => {
  if (typeof status == "number") {
    const response = await http.put(
      `/subscriptions/${subscriptionId}/status?value=${status}`
    );
    return response;
  } else {
    const response = await http.post(
      `/subscriptions/${subscriptionId}/${status}`
    );
    return response;
  }

  // const subscription = await http.put(`/subscriptions/${subscriptionId}/status?value=${status}`);
  // const response = await http.post(`/subscriptions/${subscriptionId}/activate`);
  // console.log("this is the response:>", response);
};

const getStatus = (code, lang = "en") => {
  // if (lang == "ar" && code != null) {
  //   return statusEnums[code].ar;
  // }
  // if (code > 6) {
  //   return "Canceled";
  // } else if (code <= 3) {
  //   return "Active";
  // } else {
  //   return "Suspended";
  // }
  // console.log("this is code .:>", code);
  // status <= 3 || (status > 7 && status < 13);
  const enums = {
    0: { ar: "تحت التنفيذ", en: "Processing" },
    1: { ar: "تحت التجهيز", en: "Provisioning" },
    2: { ar: "مفعل", en: "Active" },
    3: { ar: "تحت التعليق", en: "Suspending" },
    4: { ar: "معلّق", en: "Suspended" },
    5: { ar: "تحت التفعيل", en: "Activating" },
    6: { ar: "تحت الإلغاء", en: "Deprovisioning" },
    7: { ar: "ملغى", en: "Deprovisioned" },
    8: { ar: "غير مدفوع", en: "Unpaid" },
    9: { ar: "غير مدفوع - رفض", en: "UnpaidDeclined" },
    10: { ar: "غير مدفوع - فشل", en: "UnpaidFailed" },
    11: { ar: "تغيير الباقة", en: "UnderChangingPlan" },
    12: { ar: "فشل في تنفيذ الإشتراك", en: "OperationFailed" },
    13: { ar: "منتهي", en: "Expired" },
    14: { ar: "الاشتراك يحتاج تحديث", en: "Need To Be Migrated" },
  };
  return enums[code][lang];
};
const stopSubscription = async (Id) => {
  // const response = await http.post(`/subscriptions/${Id}/stop`);
  // return response;
  console.log("stop this subscription:>", Id);
  return 200;
};

const statusEnums = [
  { ar: "جاري", en: "Processing" },
  { ar: "جاري", en: "Processing" },
  { ar: "تزويد", en: "Provisioning" },
  { ar: "مفعل", en: "Active" },
  { ar: "معلّق", en: "Suspending" },
  { ar: "معلّق", en: "Suspended" },
  { ar: "تفعيل", en: "Activating" },
  { ar: "إلغاء حق الوصول", en: "Deprovisioning" },
  { ar: "غير متوفر", en: "Deprovisioned" },
  { ar: "غير مدفوعة", en: "UnPaid" },
  { ar: "غير مدفوعة", en: "UnPaidDeclined" },
  { ar: "غير مدفوعة", en: "UnPaidFailed" },
  { ar: "تغيير خطة", en: "UnderChangingPlan" },
  { ar: "فشلت العملية", en: "OperationFailed" },
  { ar: "منتهي الصلاحية", en: "Expired" },
  { ar: "الاشتراك يحتاج تحديث", en: "Need To Be Migrated" },
];

const getSubscriptionTransactions = async (
  transactionId,
  type = "creation",
  couponCode = null
) => {
  const response = await http.get(
    `subscription-${type}-transactions/${transactionId}`
  );
  return response;
};
const addNonAppFeature = async (
  transactionID,
  featureStepId,
  type = "creation"
) => {
  const { data } = await http.post(
    `/subscription-${type}-transactions/${transactionID}/non-application-features/${featureStepId}`
  );
  return data;
};
const removeNonAppFeature = async (
  transactionId,
  featureStepId,
  type = "creation"
) => {
  const { data } = await http.delete(
    `/subscription-${type}-transactions/${transactionId}/non-application-features/${featureStepId}`
  );
  return data;
};
const updateQuotaAppFeature = async (
  transactionID,
  featureStepId,
  additionalCount,
  type = "creation"
) => {
  const { data } = await http.put(
    `/subscription-${type}-transactions/${transactionID}/quota-features/${featureStepId}?quota=${additionalCount}`
  );
  return data;
};
const addModuleFeature = async (
  transactionID,
  featureStepId,
  type = "creation"
) => {
  const { data } = await http.post(
    `/subscription-${type}-transactions/${transactionID}/module-features/${featureStepId} `
  );
  return data;
};

const removeModuleFeature = async (
  transactionID,
  featureStepId,
  type = "creation"
) => {
  const { data } = await http.delete(
    `/subscription-${type}-transactions/${transactionID}/module-features/${featureStepId} `
  );
  return data;
};

const searchForUrl = async (url) => {
  return await http.get(
    `/subscriber-identifier/valid?subscriberIdentifier=${url}`
  );
};

const changeSubscriptionUrl = async (subscriptionId, url) => {
  return await http.put(
    `/subscriptions/${subscriptionId}/subscriber-identifier?subscriberIdentifier=${url}`
  );
};

const applyDiscountCoupon = async (transactionId, discountCoupon) => {
  const { data: transaction, status } = await http.put(
    `/subscription-creation-transactions/${transactionId}/discount-coupon-code?discountCode=${discountCoupon}`
  );
  return { transaction, status };
};

const getPromocodes = async (id) => {
  return await http.get(`/service-plans/${id}/promocodes`);
};
const getDiscountCoupons = async (id) => {
  return await http.get(`/service-plans/${id}/discount-coupons`);
};

const getCurrentCompFeatures = async (subscriptionId) => {
  return await http.get(`/subscriptions/${subscriptionId}/comp-features`);
};
const getAvailableCompFeatures = async (servicePlanId) => {
  return await http.get(
    `/service-plans/${servicePlanId}/possible-comp-features`
  );
};
const updateSubscriptionCompFeatures = async (
  subscriptionId,
  planFeatureId,
  value
) => {
  return await http.put(
    `/subscriptions/${subscriptionId}/comp-features/${planFeatureId}?value=${value}`
  );
};
export default {
  getAllSubscriptions,
  getSubscriptionDetails,
  createSubscription,
  updateSubscription,
  removeSubscription,
  stopSubscription,
  getAllServicePlans,
  getServicePlanDetails,
  getPlanFeatures,
  postFreeDays,
  changeSubscriptionStatus,
  changeBillingCycleType,
  getStatus,
  statusEnums,
  createSubscriptionTransaction,
  // transaction
  getSubscriptionTransactions,
  addNonAppFeature,
  removeNonAppFeature,
  updateQuotaAppFeature,
  addModuleFeature,
  removeModuleFeature,
  searchForUrl,
  changeSubscriptionUrl,
  applyDiscountCoupon,
  getPromocodes,
  getDiscountCoupons,
  getCurrentCompFeatures,
  getAvailableCompFeatures,
  updateSubscriptionCompFeatures,
  getAllServices,
};
