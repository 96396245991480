export const copyData = async (data) => {
  try {
    navigator.clipboard.writeText(data);
    return await navigator.clipboard.readText();
  } catch (err) {
    console.log("from value", err);
  }
};

export const generateId = () => {
  const placeholder = [1e7] + -1e3 + -4e3 + -8e3 + -1e11;
  const uid = () => {
    const id = crypto.getRandomValues(new Uint8Array(1));
    return (uid ^ (id[0] & (15 >> (uid / 4)))).toString(16);
  };
  return placeholder.replace(/[018]/g, uid);
  // return "orderId-123456"
};

export const prepareQueryFilters = (filters) => {
  const params = filters
    .map(
      (filter) => `${Object.keys(filter)[0]}=${filter[Object.keys(filter)[0]]}`
    )
    .join("&");
  return filters.length ? `?${params}` : "";
};

export function getCurrency() {
  switch (getCountry()) {
    case "Egypt":
      return "جنيه";
    case "KSA":
      return "ر.س";
    default:
      return "ر.س";
  }
}
export function getCurrencyEnum() {
  switch (getCountry()) {
    case "Egypt":
      return 1;
    case "KSA":
      return 0;
    default:
      return 0;
  }
}
export function getCountry() {
  const { accounts } = useMsal();
  return accounts.value[0]?.idTokenClaims.country;
}

export const Currencies = [
  { en: "SAR", ar: "ر.س" },
  { en: "USD", ar: "دولار" },
  { en: "EGP", ar: "جنيه" },
];

export function getBillingCycleType(type) {
  switch (type) {
    case 1:
      return "شهرية";
    case 3:
      return "ربع سنوية";
    case 6:
      return "نصف سنوية";
    case 12:
      return "سنوية";
    case 24:
      return "عامين";
  }
}

export function getPaymentMethodType(e) {
  const arr = [
    { en: "CreditCard", ar: "بطاقة إئتمان" },
    { en: "CreditCardAlias", ar: "الاسم المستعار لبطاقة الائتمان" },
    { en: "Wiretransfer", ar: "" },
    { en: "WiretransferToVirtualAccount", ar: "" },
    { en: "Cash", ar: "" },
    { en: "Check", ar: "" },
    { en: "Sadad", ar: "" },
    { en: "Others", ar: "" },
    { en: "DirectPayment", ar: "" },
  ];
  return arr[e].en;
}
