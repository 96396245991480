<template>
  <div class="subscriptions-table">
    <table>
      <thead>
        <tr>
          <th>المستخدم</th>
          <th>الوصف</th>
          <th>وقت العملية</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">company name</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">company name</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">company name</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">company name</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">nfs1234</skeleton-text></td>
          <td><skeleton-text effect="wave">company name</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    SkeletonText,
  },
};
</script>

<style lang="scss">
</style>