<template>
  <div class="create-subscription container mb-3">
    <div :class="`grid ${Object.keys(selectedPlan).length ? 'grid-1x2' : ''}`">
      <main class="box">
        <header class="box-header flex-between flex-center-y">
          <h3 class="box-title">اشتراك جديد</h3>
          <a href="#" @click="$router.back()" class="btn-icon">
            <i class="fas fa-arrow-right"></i>
            رجوع
          </a>
        </header>
        <CreateSubscriptionForm
          @onSelectPlan="setSelectedPlan"
          @submitFunc="(func) => (submitFormFunc = func)"
        />
      </main>
      <aside v-if="Object.keys(selectedPlan).length">
        <SelectedServicePlanCard :selectedPlan="selectedPlan" />
      </aside>
    </div>
  </div>
</template>

<script>
import CreateSubscriptionForm from "@/components/subscriptions/CreateSubscriptionForm.vue";
import SelectedServicePlanCard from "@/components/subscriptions/SelectedServicePlanCard.vue";
export default {
  components: {
    CreateSubscriptionForm,
    SelectedServicePlanCard,
  },
  props: {
    role: String,
  },
  data() {
    return {
      submitFormFunc: null,
      formData: {},
      selectedPlan: {},
    };
  },
  mounted() {
    if (
      !["Manager", "CS_Onboarding_Specialist", "Administrator"].includes(
        this.role
      )
    ) {
      this.$router.replace("/not-authed");
    }
  },
  methods: {
    getFormData(formData) {},
    submitForm() {
      const { valid, formData } = this.submitFormFunc();
    },
    setSelectedPlan(plan) {
      this.selectedPlan = plan;
    },
  },
};
</script>