<template>
  <div class="subscriptions">
    <div class="container">
      <!-- <div class="subscriptions__header flex-between">
        <div class="flex-between">
          <button
            class="btn btn-success"
            ref="ActiveSubscription"
            :disabled="dataLoading"
            @click="getData('')"
          >
            المفعلة
          </button>
          <div class="subscriptions__header-connected-btns">
            <button
              class="btn btn-success"
              ref="NewSubscriptions"
              :disabled="dataLoading"
              @click="getData('')"
            >
              الجديدة
            </button>
          </div>

          <button
            class="btn btn-icon btn-info"
            ref="all"
            :disabled="dataLoading"
            @click="getData()"
          >
            عرض الجميع
          </button>
        </div>
        <div class="flex-between">
          <vue-excel-xlsx
            v-if="['Manager', 'Accountant', 'Administrator'].includes(role)"
            class="btn btn-success"
            :data="subscriptions"
            :columns="columns"
            :file-name="'الاشتراكات'"
            :file-type="'xlsx'"
            :sheet-name="dataTitle"
          >
            <i class="far fa-file-times"></i>
          </vue-excel-xlsx>
        </div>
      </div> -->
      <div class="filter-field mb-2 mt-3">
        <h4>بحث:</h4>
        <input type="search" v-model="searchingParam" />
        <button class="btn btn-info mr-2" @click="handleSearch">بحث</button>
      </div>
      <audit-trail-table :data="data" :dataLoading="dataLoading" />
    </div>
  </div>
</template>

<script>
import AuditTrailTable from "@/components/audit-trail/AuditTrailTable.vue";
import AuditTrailService from "@/services/AuditTrailService";
import { formatDate, subtractDates, getDateTime } from "@/util/date";
export default {
  components: {
    AuditTrailTable,
  },
  props: {
    role: String,
  },
  data() {
    return {
      allAuditTrailData: [],
      data: [],
      dataLoading: false,
      dataTitle: "",
      searchingParam: null,
      columns: [
        {
          label: "المستخدم",
          field: "UserName",
        },
        {
          label: "معرف الاشتراك",
          field: "Id",
        },
        {
          label: "إسم الشركة",
          field: "NameIdentifier",
        },
        {
          label: "إسم الباقة",
          field: "TenantName",
        },
        {
          label: "تاريخ الإبتداء",
          field: "TenantId",
        },
        {
          label: "تاريخ الإنتهاء",
          field: "Description",
        },
        {
          label: "الحالة",
          field: "TimeStamp",
        },
      ],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(filter = "") {
      this.dataLoading = true;
      // const filters = [
      //   { pageIndex: 0 },
      //   { pageCount: 0 },
      //   { orderBy: "" },
      //   { ascending: true },
      // ];
      // this below for styleing active filters buttons
      // const ref = filter ? filter : "all";
      // Object.keys(this.$refs).forEach(
      //   (btn) => (this.$refs[btn].style.boxShadow = "")
      // );
      // this.$refs[ref].style.boxShadow = "inset 0px 0px 7px 0px #00000090";
      // this.dataTitle = this.$refs[ref].textContent;
      try {
        const response = await AuditTrailService.getAllAuditTrail(filter);
        if (response && response.status == 200) {
          this.allAuditTrailData = response.data.map((d) => ({
            ...d,
            Description: d.Description.ArabicValue,
            TimeStamp: getDateTime(d.TimeStamp),
          }));
          this.data = this.allAuditTrailData;
          this.dataLoading = false;
        } else {
          this.dataLoading = false;
        }
      } catch (error) {
        this.dataLoading = false;
      }
    },
    // filterSubscriptions(status = "") {
    //   this.subscriptions = this.allSubscriptions.filter((sub) => {
    //     if (status == "newest") {
    //       const daysFromStart = subtractDates(sub.StartDate - getNowDate());
    //       return daysFromStart < 30 ? sub : false;
    //     } else if (status == "active") {
    //       return sub.Status == "Active";
    //     } else if (status == "semi-finished") {
    //       const daysToEnd = subtractDates(getNowDate() - sub.EndBillingDate);
    //       return daysToEnd < 30 ? sub : false;
    //     } else if (status == "unpaid") {
    //       // return sub.Status == "Unpaid"; مش عارف اعملها ايه
    //     } else if (status == "stoped") {
    //       // return sub.Status == "Stoped";
    //       const daysToEnd = subtractDates(getNowDate() - sub.EndBillingDate);
    //       return daysToEnd < 30 ? sub : false;
    //     } else if (status == "trial") {
    //       return sub.ServicePlan.TrialPlan;
    //     }
    //     // return sub;
    //   });
    // },
    async handleSearch() {
      const searchParam = this.searchingParam.toLowerCase();
      if (searchParam) {
        this.dataLoading = true;
        try {
          const response = await AuditTrailService.searchingFor(searchParam);
          if (response && response.status == 200) {
            this.allAuditTrailData = response.data.map((d) => ({
              ...d,
              Description: d.Description.ArabicValue,
              TimeStamp: getDateTime(d.TimeStamp),
            }));
            this.data = this.allAuditTrailData;
            this.dataLoading = false;
          } else {
            this.dataLoading = false;
          }
        } catch (error) {
          this.dataLoading = false;
        }
      } else {
        this.data = this.allAuditTrailData;
      }
    },
  },
};
</script>

<style lang="scss">
</style>