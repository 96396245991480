<template>
  <div class="subscriptions-table">
    <template v-if="!isLoading">
      <table-lite
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :total="totalRecordCount"
        :is-static-mode="true"
        :sortable="sortable"
        :pageOptions="[
          { value: 25, text: 25 },
          { value: 50, text: 50 },
          { value: 75, text: 75 },
        ]"
        @do-search="$emit('doSearch')"
        @row-clicked="(row) => $emit('subscription-clicked', row)"
        @is-finished="tableLoadingFinish"
        :messages="messages"
        :class="totalRecordCount > 10 ? 'has-pagination' : ''"
      >
      </table-lite>
    </template>
    <products-report-table-loading v-else />
  </div>
</template>

<script>
import { formatDate } from "@/util/date";
import TableLite from "vue3-table-lite";
import ProductsReportTableLoading from "./ProductsReportTableLoading.vue";

export default {
  components: {
    TableLite,
    ProductsReportTableLoading,
  },
  props: {
    subscriptions: Array,
    dataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataId: "",
      isLoading: this.dataLoading,
      columns: [
        {
          label: "المعرف",
          field: "Id",
          sortable: true,
          isKey: true,
        },
        {
          label: "المنتج",
          field: "Product",
          sortable: true,
        },
        {
          label: "الباقة",
          field: "BundleType",
          sortable: true,
        },
        {
          label: "تاريخ التسجيل",
          field: "StartDate",
          sortable: true,
        },
        {
          label: "بريد المستخدم",
          field: "Email",
          sortable: true,
        },
        {
          label: "رقم الهاتف",
          field: "MobileNumber",
          sortable: false,
        },
        {
          label: "الشركة",
          field: "SubscriberName",
          sortable: true,
        },
        {
          label: "النشاط",
          field: "BusinessDomain",
          sortable: true,
        },
        {
          label: "طريقةالدفع",
          field: "PaymentMethod",
          sortable: true,
        },
        {
          label: "المبلغ المدفوع",
          field: "Amount",
          sortable: true,
        },
        {
          label: "العملة",
          field: "Currency",
          sortable: true,
        },
        {
          label: "الخطة",
          field: "Plan",
          sortable: true,
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "Number",
        sort: "desc",
      },
      messages: {
        pagingInfo: "عرض {0}-{1} من {2}",
        pageSizeChangeLabel: "عدد الصفوف:",
        gotoPageLabel: "الذهاب للصفحة:",
        noDataAvailable: "لا يوجد اي بيانات",
      },
    };
  },
  watch: {
    dataLoading(newVal) {
      this.isLoading = newVal;
    },
    subscriptions(newVal) {
      this.setRows(newVal);
    },
  },
  mounted() {
    this.setRows(this.subscriptions);
  },
  methods: {
    setRows(subscriptions) {
      this.rows = subscriptions;
      this.totalRecordCount = subscriptions.length;
      this.isLoading = false;
    },
    tableLoadingFinish(elements) {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
</style>