<template>
  <FormKit
    type="form"
    id="subscriptionForm"
    ref="subscriptionForm"
    :actions="false"
    v-model="formData"
    #default="{ state }"
  >
    <h3 class="c-danger" v-if="!state.valid && submited">
      من فضلك املاء البيانات بشكل صحيح
    </h3>

    <div class="form-section">
      <h3 class="form-section__title">بيانات الأشتراك</h3>
      <FormKit
        type="select"
        label="الباقة"
        name="servicePlan"
        v-model="servicePlan"
        placeholder="اختر الباقة"
        :options="servicePlans"
        validation="required"
        @change="setSelectedPlan"
      />
      <!-- pormotion code field -->
      <div class="my-2" v-if="selectedPlan">
        <template
          v-if="
            Object.keys(selectedPlan).length && selectedPlan.HasPromotionCode
          "
        >
          <div class="loading inline-loading" v-if="loadingPromos"></div>
          <template v-else>
            <label for="promocodes">الرمز الترويجي</label>
            <select id="promocodes" class="mt-1" v-model="promotionCode">
              <option v-for="pc in promoCodes" :key="pc.Id" :value="pc.Code">
                {{ pc.Code }}
              </option>
            </select>
          </template>
        </template>
      </div>
    </div>
    <create-client-form
      :hasSubmitBtn="false"
      :loading="loading"
      @handleSubmit="(formSubmitCB) => (submitClientForm = formSubmitCB)"
    />
    <div class="text-left mt-2">
      <!-- <button class="btn btn-success mx-2 mb-2" @click.prevent="submitForms">
        اشتراك قياسي
      </button> -->
      <button
        class="btn btn-warning mx-2 mb-2"
        @click.prevent="customSubscription"
      >
        اشتراك
      </button>
    </div>
  </FormKit>
  <div class="loader loader-page-layout" v-if="loading"></div>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import ClientService from "@/services/ClientService";
import CreateClientForm from "@/components/clients/CreateClientForm.vue";
import { getNowDate } from "@/util/date";
export default {
  components: { CreateClientForm },
  emits: ["onSelectPlan", "submitFunc"],
  data() {
    return {
      servicePlans: [],
      allPlans: [],
      selectedPlan: {},
      client: null,
      servicePlan: null,
      promotionCode: null,
      customizable: false,
      formData: {},
      submited: false,
      submitClientForm: null,
      loading: false,
      loadingPromos: false,
      promoCodes: false,
    };
  },
  async mounted() {
    this.loading = true;
    const response = await SubscriptionService.getAllServicePlans();
    if (response && response.status == 200) {
      this.loading = false;
      this.allPlans = response.data;
      this.servicePlans = this.allPlans
        .filter((c) => c.Status == 1)
        .map((c) => {
          return {
            label: c.Name.ArabicValue,
            value: c.Id,
          };
        });
    } else {
      this.$toast.error("حدث خطأ ما أثناء العثور على الباقات!");
    }
    this.statusEnums = SubscriptionService.statusEnums;
    this.$emit("submitFunc", () => this.validateForm());
  },
  methods: {
    async setSelectedPlan() {
      this.loading = true;
      this.selectedPlan = this.allPlans.find((p) => p.Id == this.servicePlan);
      if (this.selectedPlan.HasPromotionCode) {
        this.loadingPromos = true;
        try {
          const response = await SubscriptionService.getPromocodes(
            this.servicePlan
          );
          if (response && response.status == 200) {
            this.promoCodes = response.data;
            this.loadingPromos = false;
          } else {
            this.loadingPromos = false;
          }
        } catch (error) {
          this.loadingPromos = false;
        }
      }
      try {
        const response = await SubscriptionService.getPlanFeatures(
          this.servicePlan
        );
        if (response && response.status == 200) {
          this.loading = false;
          this.selectedPlan.PlanFeatures = response.data;
          this.$emit("onSelectPlan", this.selectedPlan);
        } else {
          this.loading = false;
          this.$toast.error("فشل في عرض مميزات الباقة!");
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async customSubscription() {
      if (this.selectedPlan.HasPromotionCode && !this.promotionCode) {
        return this.$toast.error("من فضلك اختر رمز ترويجي!");
      }
      const { valid: clientFormValid, formData: clientFormData } =
        this.submitClientForm();
      if (clientFormValid) {
        this.loading = true;
        try {
          const response = await ClientService.createClient(clientFormData);
          if (response && response.status == 201) {
            this.createTransaction(response.data.Tenant.Id);
          } else {
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
        }
      }
    },
    async createTransaction(subscriberId) {
      const valid = this.$refs["subscriptionForm"].node.context.state.valid;
      if (valid) {
        // send request to the back end
        const params = [{ subscriberId }, { servicePlanId: this.servicePlan }];
        if (this.promotionCode) params.push({ promoCode: this.promotionCode });
        this.loading = true;
        try {
          const response =
            await SubscriptionService.createSubscriptionTransaction(params);
          if (response.status == 201) {
            this.$router.push(
              `/create-subscription/${this.$route.params.id}/${response.data.Id}`
            );
            this.loading = false;
          } else {
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
        }
        this.loading = false;
      }
    },
    async createClient() {},
    standardSubscription() {},
    async submitForms() {
      if (this.selectedPlan.HasPromotionCode && !this.promotionCode) {
        return this.$toast.error("من فضلك اختر رمز ترويجي!");
      }
      const ClientForm = this.submitClientForm();
      const valid = this.$refs["subscriptionForm"].node.context.state.valid;
      if (ClientForm.valid && valid) {
        this.loading = true;
        const params = [{ servicePlanId: this.servicePlan }];
        if (this.promotionCode) params.push({ promoCode: this.promotionCode });
        try {
          const response = await SubscriptionService.createSubscription(
            params,
            ClientForm.formData
          );
          if (response && response.status == 201) {
            this.loading = false;
            this.$router.push(`/subscriptions/${response.data.Id}`);
          }
        } catch (error) {
          this.$toast.error("حدث خطأ ما!");
          this.loading = false;
        }
      }
    },
    validateForm() {
      // this.$formkit.submit("clientForm");
      const valid = this.$refs["subscriptionForm"].node.context.state.valid;
      this.submited = true;
      return {
        valid,
        formData: { ...this.formData },
      };
    },
    getNowDate() {
      return getNowDate();
    },
  },
};
</script>

<style>
.formkit-wrapper label {
  margin-bottom: 1rem;
  display: block;
}
</style>