import http from "./httpService";
import { prepareQueryFilters } from "@/util/utilities";
import axios from "axios";
import { msalInstance } from "@/authConfig";

// enum BillingCycleType
//     {
//         Monthly = 1,
//         Quarterly = 3,
//         BiYearly = 6,
//         Yearly = 12
//     }
const getAllClients = async (filter) => {
  return await http.get(`/subscribers?filter=${filter}`);
};
const getClientsFor = async (ServiceId) => {
  console.log("this is serviceId:>", ServiceId);
  return await axios.get(`${process.env.VUE_APP_API_BASE_URL}/subscribers`, {
    headers: {
      ServiceId,
      NameIdentifier: msalInstance.getAllAccounts()[0]?.localAccountId,
    },
  });
};

const filterClients = async (filters) => {
  // const filters = {
  // showTrialPlanOnly: boolean
  // showPaidPlanOnly: boolean
  // businessDomainName: string
  // registerationDateFrom: date
  // registerationDateTo: date
  // billingCycleType: enum
  // };
  const queries = prepareQueryFilters(filters);
  return await http.get(`/subscribers${queries}`);
};

const getClientDetails = async (id, includeServicePlan = true) => {
  const { data: ClientDetails } = await http.get(
    `/subscribers/${id}?includeServicePlan=${includeServicePlan}`
  );
  return ClientDetails;
};

const getAuditTrail = async (id, pI = 0, pC = 25) => {
  const { data: auditTrail } = await http.get(
    `/subscribers/${id}/audit-trail?pageIndex=${pI}&pageCount=${pC}`
  );
  return auditTrail;
};

const createClient = async (client) => {
  const response = await http.post(`/subscribers`, client);
  return response;
};

const getBillingRecords = async (id) => {
  const { data: BillingRecords } = await http.get(
    `/subscribers/${id}/billing-records`
  );
  console.log("getBillingRecords repsonse: ", BillingRecords);
  return BillingRecords;
};

const depositBalance = async (
  clientId,
  balance,
  PaymentMethodType,
  TransactionId
) => {
  const response = await http.put(`/subscribers/${clientId}/balance`, {
    // details
    TransactionId,
    PaymentMethodType,
    Description: "",
    AmountToDeposit: balance,
  });
  return response;
};

const getAllCountries = async (includeChildRegions = false) => {
  return await http.get(
    `/account/countries?includeChildRegions=${includeChildRegions}`
  );
};
const getCities = async (CountryCode) => {
  return await http.get(`/cities?CountryCode=${CountryCode}`);
};
export default {
  getAllClients,
  filterClients,
  getClientDetails,
  getAuditTrail,
  createClient,
  getBillingRecords,
  depositBalance,
  getAllCountries,
  getCities,
  getClientsFor,
};
