<template>
  <nav-bar />
  <router-view :role="role" />
</template>

<script>
import NavBar from "@/components/common/NavBar.vue";
import { useMsal } from "@/composition-api/useMsal";
import UserService from "@/services/UserService";
export default {
  components: {
    NavBar,
  },
  setup() {
    const { instance, accounts } = useMsal();
    return { instance, accounts };
  },
  data() {
    return {
      role: null,
      loading: true,
    };
  },
  watch: {
    accounts(to) {
      UserService.getProfile().then((res) => {
        this.role = UserService.getRole(res.data.Roles[0]?.Name);
      });
    },
  },
  mounted() {
    if (this.accounts[0]) {
      UserService.getProfile().then((res) => {
        this.role = UserService.getRole(res.data.Roles[0]?.Name);
      });
    }
  },
};
</script>
