<template>
  <div class="subscriptions-table">
    <table>
      <thead>
        <tr>
          <th>المعرف</th>
          <th>المنتج</th>
          <th>باقة مجانية</th>
          <th>تاريخ التسجيل</th>
          <th>بريد المستخدم</th>
          <th>رقم الهاتف</th>
          <th>إسم الشركة</th>
          <th>النشاط</th>
          <th>طريقة الدفع</th>
          <th>المبلغ المدفوع</th>
          <th>العملة</th>
          <th>الخطة</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><skeleton-text effect="wave">1</skeleton-text></td>
          <td><skeleton-text effect="wave">eirady</skeleton-text></td>
          <td><skeleton-text effect="wave">trail</skeleton-text></td>
          <td><skeleton-text effect="wave">package name</skeleton-text></td>
          <td><skeleton-text effect="wave">code or number</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">05012345</skeleton-text></td>
          <td><skeleton-text effect="wave">visa</skeleton-text></td>
          <td><skeleton-text effect="wave">2500</skeleton-text></td>
          <td><skeleton-text effect="wave">SAR</skeleton-text></td>
          <td><skeleton-text effect="wave">annual</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">2</skeleton-text></td>
          <td><skeleton-text effect="wave">eirady</skeleton-text></td>
          <td><skeleton-text effect="wave">trail</skeleton-text></td>
          <td><skeleton-text effect="wave">packagname</skeleton-text></td>
          <td><skeleton-text effect="wave">copuon</skeleton-text></td>
          <td><skeleton-text effect="wave">05012345</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">direct payment</skeleton-text></td>
          <td><skeleton-text effect="wave">2500</skeleton-text></td>
          <td><skeleton-text effect="wave">SAR</skeleton-text></td>
          <td><skeleton-text effect="wave">monthly</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">3</skeleton-text></td>
          <td><skeleton-text effect="wave">eirady</skeleton-text></td>
          <td><skeleton-text effect="wave">trail</skeleton-text></td>
          <td><skeleton-text effect="wave">package name</skeleton-text></td>
          <td><skeleton-text effect="wave">code or number</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">05012345</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">visa</skeleton-text></td>
          <td><skeleton-text effect="wave">2500</skeleton-text></td>
          <td><skeleton-text effect="wave">SAR</skeleton-text></td>
          <td><skeleton-text effect="wave">monthly</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">4</skeleton-text></td>
          <td><skeleton-text effect="wave">eirady</skeleton-text></td>
          <td><skeleton-text effect="wave">paid</skeleton-text></td>
          <td><skeleton-text effect="wave">packagname</skeleton-text></td>
          <td><skeleton-text effect="wave">copuon</skeleton-text></td>
          <td><skeleton-text effect="wave">05012345</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">visa</skeleton-text></td>
          <td><skeleton-text effect="wave">2500</skeleton-text></td>
          <td><skeleton-text effect="wave">SAR</skeleton-text></td>
          <td><skeleton-text effect="wave">annual</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">5</skeleton-text></td>
          <td><skeleton-text effect="wave">eirady</skeleton-text></td>
          <td><skeleton-text effect="wave">trail</skeleton-text></td>
          <td><skeleton-text effect="wave">package name</skeleton-text></td>
          <td><skeleton-text effect="wave">code or number</skeleton-text></td>
          <td><skeleton-text effect="wave">05012345</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">20/20/2022</skeleton-text></td>
          <td><skeleton-text effect="wave">direct payment</skeleton-text></td>
          <td><skeleton-text effect="wave">2500</skeleton-text></td>
          <td><skeleton-text effect="wave">SAR</skeleton-text></td>
          <td><skeleton-text effect="wave">monthly</skeleton-text></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    SkeletonText,
  },
};
</script>

<style lang="scss">
</style>