<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>
            <FormKit type="checkbox" name="terms" outer-class="$reset" />
          </th>
          <th>#</th>
          <th>التاريخ</th>
          <th>رقم الفاتورة</th>
          <th>رقم الاشتراك</th>
          <th>الوصف</th>
          <th>المبلغ</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <skeleton-text effect="wave"> wo </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
        </tr>
        <tr>
          <td>
            <skeleton-text effect="wave"> wo </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
        </tr>
        <tr>
          <td>
            <skeleton-text effect="wave"> wo </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
        </tr>
        <tr>
          <td>
            <skeleton-text effect="wave"> wo </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> abigsingleword </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
          <td>
            <skeleton-text effect="wave"> word </skeleton-text>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: { SkeletonText },
  props: {
    data: Array,
  },
  data() {
    return {
      rows: {},
      all: false,
    };
  },
};
</script>


<style lang="scss" scoped>
table {
  position: relative;
  z-index: 2;
  font-size: 1.8rem;
}
thead {
  position: relative;
  z-index: 3;
  color: #525c6b;
  font-weight: bold;
  box-shadow: 0px 3px 6px #00000029;
}

td {
  color: #96a4af;
}

.formkit-outer {
  margin: 0;
}
</style>