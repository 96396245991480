<template>
  <div class="configure-subscription">
    <!-- loading template -->
    <template v-if="!features">
      <feature-card-group-loading />
      <section class="vcp--expanded">
        <header class="vcp__header">
          <div class="vcp__header-title">مزايا إضافية</div>
        </header>
        <div class="vcp__body">
          <div class="vcp__body-content">
            <section class="package-specs__section">
              <package-spec-card-loading />
              <package-spec-card-loading />
              <package-spec-card-loading />
              <package-spec-card-loading />
            </section>
          </div>
        </div>
      </section>
      <section class="vcp--expanded">
        <header class="vcp__header">
          <div class="vcp__header-title">الخدمات الإضافية</div>
        </header>
        <div class="vcp__body">
          <div class="vcp__body-content">
            <feature-card-group-loading />
          </div>
        </div>
      </section>
      <section class="vcp--expanded">
        <header class="vcp__header">
          <div class="vcp__header-title">خدمات آخرى</div>
        </header>
        <div class="vcp__body">
          <div class="vcp__body-content">
            <feature-card-group-loading />
          </div>
        </div>
      </section>
    </template>

    <!-- billing cycle options  -->

    <select
      class="mb-2"
      v-if="transaction.ServicePlan"
      @change="changeBillingCycleType"
    >
      <template
        v-for="{ BillingCycleType, isActive } in transaction.ServicePlan
          .BillingCycleOptions"
        :key="BillingCycleType"
      >
        <option
          v-if="isActive"
          :value="BillingCycleType"
          :selected="BillingCycleType == transaction.SelectedBillingCycleType"
        >
          {{ getBillingCycleType(BillingCycleType) }}
        </option>
      </template>
    </select>

    <!-- first steps section -->
    <feature-card-group
      :features="features"
      :loadingUpdate="loadingUpdate"
      @update="updateFeature"
      :FeaturesGroup="FeaturesGroup1"
      :currency="currency"
      :cycleType="transaction.SelectedBillingCycleType"
    />
    <!-- section one -->
    <section
      class="vcp--expanded"
      v-if="FeaturesGroup2 && FeaturesGroup2.length > 0"
    >
      <header class="vcp__header">
        <div class="vcp__header-title">مزايا إضافية</div>
      </header>
      <div class="vcp__body">
        <div class="vcp__body-content">
          <feature-card-group
            :features="features"
            :loadingUpdate="loadingUpdate"
            @update="updateFeature"
            :FeaturesGroup="FeaturesGroup2"
            :currency="currency"
            :cycleType="transaction.SelectedBillingCycleType"
          />
        </div>
      </div>
    </section>
    <!-- section two -->
    <section
      class="vcp--expanded"
      v-if="FeaturesGroup3 && FeaturesGroup3.length > 0"
    >
      <header class="vcp__header">
        <div class="vcp__header-title">الخدمات الإضافية</div>
      </header>
      <div class="vcp__body">
        <div class="vcp__body-content">
          <feature-card-group
            :features="features"
            :loadingUpdate="loadingUpdate"
            @update="updateFeature"
            :FeaturesGroup="FeaturesGroup3"
            :currency="currency"
            :cycleType="transaction.SelectedBillingCycleType"
          />
        </div>
      </div>
    </section>
    <!-- section three  -->
    <section
      class="vcp--expanded"
      v-if="FeaturesGroup4 && FeaturesGroup4.length > 0"
    >
      <header class="vcp__header">
        <div class="vcp__header-title">خدمات آخرى</div>
      </header>
      <div class="vcp__body">
        <div class="vcp__body-content">
          <feature-card-group
            :features="features"
            :loadingUpdate="loadingUpdate"
            @update="updateFeature"
            :FeaturesGroup="FeaturesGroup4"
            :currency="currency"
            :cycleType="transaction.SelectedBillingCycleType"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FeatureCardGroup from "./FeatureCardGroup.vue";
import FeatureCardGroupLoading from "./FeatureCardGroupLoading.vue";
import PackageSpecCardLoading from "./PackageSpecCardLoading.vue";
import SubscriptionService from "@/services/SubscriptionService";
import { Currencies } from "@/util/utilities";
export default {
  components: {
    FeatureCardGroup,
    FeatureCardGroupLoading,
    PackageSpecCardLoading,
  },
  props: {
    transactionId: Number,
  },
  data() {
    return {
      transaction: {},
      features: null,
      quotaApplicationFeatures: null,
      nonApplicationFeatures: null,
      QuotaNonAppFeatures: null,
      moduleFeatures: null,
      FeaturesGroup1: null,
      FeaturesGroup2: null,
      FeaturesGroup3: null,
      FeaturesGroup4: null,
      loadingUpdate: true,
      showLoadingScreen: false,
      loadingItems: [],
      currency: null,
      subscriptionType: "creation",
    };
  },
  watch: {
    transaction(newVal) {
      if (!this.transaction.ServicePlan) {
        this.transaction.ServicePlan =
          this.transaction.Subscription.ServicePlan;
      }
    },
  },
  mounted() {
    if (this.$route.path.includes("configure"))
      this.subscriptionType = "configuration";
    this.subscriptionTransactions();
  },
  methods: {
    getBillingCycleType(type) {
      switch (type) {
        case 1:
          return "شهري";
        case 3:
          return "ربع سنوي";
        case 6:
          return "نصف سنوي";
        case 12:
          return "سنوي";
        case 24:
          return "عامين";
      }
    },
    async changeBillingCycleType(e) {
      try {
        const response = await SubscriptionService.changeBillingCycleType(
          this.transactionId,
          e.target.value
        );
        if (response && response.status == 200) {
          this.setTransactionConfigs(response.data);
          this.$toast.success("تم تغيير فترة الاشتراك");
        } else {
          this.$toast.error("عفواً هناك مشكلة ما!");
        }
      } catch (error) {
        this.$toast.error("عفواً هناك مشكلة ما!");
      }
    },
    async subscriptionTransactions() {
      try {
        const response = await SubscriptionService.getSubscriptionTransactions(
          this.transactionId,
          this.subscriptionType
        );
        if (response && response.status) {
          this.setTransactionConfigs(response.data);
        } else {
          this.$router.back();
        }
      } catch (error) {
        this.$router.back();
      }
    },
    setTransactionConfigs(transaction) {
      this.transaction = transaction;
      const { ServicePlan } = this.transaction;
      if (!ServicePlan) {
        this.transaction.ServicePlan =
          this.transaction.Subscription.ServicePlan;
      }
      this.currency = Currencies[this.transaction.ServicePlan.Currency].ar;

      this.emitData();
      this.setFeatures(this.transaction.SubscriptionSteps);
    },
    setFeatures(SubscriptionSteps) {
      // Module Features
      this.moduleFeatures = this.getAppFeatureType(SubscriptionSteps, 0).filter(
        (feature) => feature.InitialQuotaPack == 0
      );
      // Non Application Features
      this.nonApplicationFeatures = this.getAppFeatureType(
        SubscriptionSteps,
        2
      ).filter((feature) => feature.InitialQuotaPack == 0);

      // Quota Application Feature
      this.quotaApplicationFeatures = this.getAppFeatureType(
        SubscriptionSteps,
        3
      );
      // Quota Non Application Feature
      this.QuotaNonAppFeatures = this.getAppFeatureType(SubscriptionSteps, 4);
      // set array of all features
      this.features = [
        ...this.QuotaNonAppFeatures, // 4
        ...this.quotaApplicationFeatures, //3
        ...this.nonApplicationFeatures, //2
        ...this.moduleFeatures, //0
      ];
      // set every group of features
      this.FeaturesGroup1 = this.getFeatureGroup(0, 1000);
      this.FeaturesGroup2 = this.getFeatureGroup(1001, 2000);
      this.FeaturesGroup3 = this.getFeatureGroup(2001, 3000);
      this.FeaturesGroup4 = this.getFeatureGroup(3001, 4000);
      this.loadingUpdate = false;
      this.emitData();
    },
    updateFeature({ type, Id, value }) {
      if (type == 0) {
        this.updateModuleFeature(Id, value, this.subscriptionType);
      } else if (type == 2) {
        this.updateNonAppFeature(Id, value, this.subscriptionType);
      } else {
        this.updateQuotaAppFeature(Id, value, this.subscriptionType);
      }
    },
    async updateNonAppFeature(Id, isAdded, subscriptionType) {
      this.loadingUpdate = true;
      this.emitData();
      const transactionId = this.transaction.Id;
      const update = isAdded
        ? SubscriptionService.addNonAppFeature
        : SubscriptionService.removeNonAppFeature;
      sessionStorage.removeItem("transaction");
      this.transaction = await update(transactionId, Id, subscriptionType);
      this.loadingUpdate = false;
      this.emitData();
      this.setFeatures(this.transaction.SubscriptionSteps);
    },
    async updateQuotaAppFeature(Id, additionalCount, subscriptionType) {
      this.loadingUpdate = true;
      this.emitData();
      const transactionId = this.transaction.Id;
      sessionStorage.removeItem("transaction");
      this.transaction = await SubscriptionService.updateQuotaAppFeature(
        transactionId,
        Id,
        additionalCount,
        subscriptionType
      );
      this.loadingUpdate = false;
      this.setFeatures(this.transaction.SubscriptionSteps);
    },
    async updateModuleFeature(Id, isAdded, subscriptionType) {
      this.loadingUpdate = true;
      this.emitData();

      const transactionId = this.transaction.Id;
      const updateFeature = isAdded
        ? SubscriptionService.addModuleFeature
        : SubscriptionService.removeModuleFeature;
      sessionStorage.removeItem("transaction");
      this.transaction = await updateFeature(
        transactionId,
        Id,
        subscriptionType
      );
      this.loadingUpdate = false;
      this.emitData();

      this.setFeatures(this.transaction.SubscriptionSteps);
    },
    getAppFeatureType(arr, type) {
      return arr
        .filter((item) => item.AppFeatureType == type)
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    getFeatureGroup(from, to) {
      return this.features
        .filter((item) => item.DisplayOrder >= from && item.DisplayOrder <= to)
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    },
    emitData() {
      this.$emit("getData", {
        transaction: this.transaction,
        features: this.features,
        loadingUpdate: this.loadingUpdate, // to be changed
        disabled: this.disabled,
        subscriptionType: this.subscriptionType,
        enableDicountInput: this.enableDicountInput,
      });
    },
  },
};
</script>

<style>
</style>