import { msalInstance, loginRequest } from "../authConfig";
import {
  InteractionType,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";

import store from "@/store";

export function registerGuard(router) {
  router.beforeEach(async (to, from) => {
    if (to.meta.requiresAuth) {
      const request = {
        ...loginRequest,
        redirectStartPage: to.fullPath,
      };
      const shouldProceed = await isAuthenticated(
        msalInstance,
        InteractionType.Redirect,
        request
      );
      // console.log("this is shouldProceed:>", shouldProceed);
      return shouldProceed || msalInstance.logoutRedirect();
    }

    return true;
  });
}

// export function authRoleGuard(router) {
//   router.beforeEach(async (to, from, next) => {
//     console.log("this is to:>", to);
//     const routeAuthRoles = to.meta.authorizedRoles;
//     const userRole = "USER";
//     console.log("this is routeAuthRoles:>", routeAuthRoles);
//     // authorizedRoles
//     if (to.meta.requiresAuth) {
//       if (routeAuthRoles?.some((role) => role == userRole)) {
//         console.log("yes user is admin");
//         console.log("this is router:>", router);
//       } else {
//         router.replace("/not-authed");
//       }
//     }
//     next();
//   });
// }

export async function isAuthenticated(instance, interactionType, loginRequest) {
  // If your application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance
    .handleRedirectPromise()
    .then(async () => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const { idTokenClaims, name, tenantId, localAccountId } = accounts[0];
        // }
        return true;
        // if (idTokenClaims.newUser && !localStorage.getItem("signed")) {
        //   const user = {
        //     Email: idTokenClaims.emails[0],
        //     NameIdentifier: localAccountId,
        //     UserName: name,
        //     CompanyName: idTokenClaims.extension_CompanyName,
        //     Mobile: "0" + idTokenClaims.extension_PhoneNumber,
        //     CountryCode: idTokenClaims.country.slice(0, 2).toUpperCase(),
        //     Tinumber: tenantId,
        //   };
        //   const profile = await Service.signUp(user);
        //   localStorage.setItem("signed", true);
        //   return profile;
        // } else {
        //   return true;
        // }
      }

      // User is not signed in and attempting to access protected route. Sign them in.
      // the code below to redirect user to the login form direct
      // uncomment it if you don't want user to choose sign in options
      // if (interactionType === InteractionType.Popup) {
      //   return instance
      //     .loginPopup(loginRequest)
      //     .then(() => {
      //       return true;
      //     })
      //     .catch(() => {
      //       return false;
      //     });
      // } else if (interactionType === InteractionType.Redirect) {
      return instance
        .loginRedirect(loginRequest)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
      // }

      return false;
    })
    .catch(() => {
      return false;
    });
}
