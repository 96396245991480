<template>
  <div class="plan-form-page container">
    <section class="plan-form-section">
      <header class="plan-form-section__header">
        <h3>بيانات الباقة</h3>
      </header>
      <div class="plan-form-section__body">
        <!-- name field -->
        <div class="field">
          <label for="name">اسم الباقة</label>
          <input type="text" id="name" v-model="plan.Name.ArabicValue" />
        </div>

        <!-- checkboxs row -->
        <div class="fields-row">
          <div class="field">
            <label class="checkbox-label">
              <span>باقة مجانية</span>
              <div class="custom-checkbox">
                <input type="checkbox" v-model="plan.FreePlan" />
                <i class="far fa-check"></i>
              </div>
            </label>
          </div>
          <div class="field">
            <label class="checkbox-label">
              <span>نشر على موقع دفاتر</span>
              <div class="custom-checkbox">
                <input type="checkbox" v-model="plan.Published" />
                <i class="far fa-check"></i>
              </div>
            </label>
          </div>
          <div class="field">
            <label class="checkbox-label">
              <span>قابله للاشتراك</span>
              <div class="custom-checkbox">
                <input type="checkbox" v-model="plan.Subscribeable" />
                <i class="far fa-check"></i>
              </div>
            </label>
          </div>
        </div>
        <h4>إضافة موظفين</h4>
        <div class="grid grid-1x3">
          <package-spec-card
            :countNum="3"
            name="المستخدمين"
            :additionalPrice="30"
            additionalName="مستخدم إضافي"
          />
          <package-spec-card
            :countNum="3"
            name="الموظفين"
            :additionalPrice="12"
            additionalName="مستخدم إضافي"
          />
          <package-spec-card
            :countNum="3"
            name="موظفي الخدمة الذاتية"
            :additionalPrice="12"
            additionalName="مستخدم إضافي"
          />
        </div>
      </div>
    </section>
    <section class="plan-form-section">
      <div class="plan-form-section__body">
        <div class="plan-form__tabs-section">
          <tabs-component
            :options="{ useUrlFragment: false }"
            nav-item-class="nav-item"
          >
            <tab-component name="محتوي الباقة">
              <div>
                <vue-collapsible-panel-group>
                  <vue-collapsible-panel>
                    <template #title>
                      <header>
                        <h3 class="mb-0">دفتر الحسابات</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="plan-form__content-box">
                        <div class="field">
                          <label class="checkbox-label">
                            <span>قيد يومية</span>
                            <div class="custom-checkbox">
                              <input type="checkbox" />
                              <i class="far fa-check"></i>
                            </div>
                          </label>
                        </div>
                        <div class="field">
                          <label class="checkbox-label">
                            <span>ضرائب</span>
                            <div class="custom-checkbox">
                              <input type="checkbox" />
                              <i class="far fa-check"></i>
                            </div>
                          </label>
                        </div>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                  <vue-collapsible-panel :expanded="false">
                    <template #title>
                      <header>
                        <h3 class="mb-0">دفتر المبيعات</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x2 subscription-details__client">
                        <p>content of the accordion</p>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                  <vue-collapsible-panel :expanded="false">
                    <template #title>
                      <header>
                        <h3 class="mb-0">دفتر المبيعات</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x2 subscription-details__client">
                        <p>content of the accordion</p>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                  <vue-collapsible-panel :expanded="false">
                    <template #title>
                      <header>
                        <h3 class="mb-0">دفتر المشتريات</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x2 subscription-details__client">
                        <p>content of the accordion</p>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                  <vue-collapsible-panel :expanded="false">
                    <template #title>
                      <header>
                        <h3 class="mb-0">دفتر المخزون</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x2 subscription-details__client">
                        <p>content of the accordion</p>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                </vue-collapsible-panel-group>
              </div>
            </tab-component>
            <tab-component name="الاضافات">
              <div>
                <vue-collapsible-panel-group>
                  <vue-collapsible-panel>
                    <template #title>
                      <header>
                        <h3 class="mb-0">الإضافات الإختيارية المدفوعة</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x3">
                        <package-spec-card
                          :countNum="3"
                          name="نقاط بيعدفاتر الاوف لاين"
                          :additionalPrice="1200"
                          additionalName="نقطة بيع"
                          moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
                        />
                        <package-spec-card
                          :countNum="3"
                          name="شركات إضافية"
                          :additionalPrice="1200"
                          additionalName="نقطة بيع"
                          moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
                        />
                        <package-spec-card
                          :countNum="3"
                          name="شركات إضافية"
                          :additionalPrice="1200"
                          additionalName="نقطة بيع"
                          moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
                        />
                        <package-spec-card
                          :countNum="3"
                          name="مساحة تخزين إضافية"
                          :additionalPrice="1200"
                          additionalName="نقطة بيع"
                          moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
                        />
                        <package-spec-card
                          :countNum="3"
                          name="تجارة إلكترونية"
                          :additionalPrice="1200"
                          additionalName="نقطة بيع"
                          moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
                        />
                        <package-spec-card
                          :countNum="3"
                          name="تجارة إلكترونية"
                          :additionalPrice="1200"
                          additionalName="نقطة بيع"
                          moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
                        />
                      </div>
                    </template>
                  </vue-collapsible-panel>
                  <vue-collapsible-panel :expanded="false">
                    <template #title>
                      <header>
                        <h3 class="mb-0">الدعم الفني الإضافي</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x2 subscription-details__client">
                        <p>content of the accordion</p>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                  <vue-collapsible-panel :expanded="false">
                    <template #title>
                      <header>
                        <h3 class="mb-0">الدعم الفني الإضافي</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x2 subscription-details__client">
                        <p>content of the accordion</p>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                  <vue-collapsible-panel :expanded="false">
                    <template #title>
                      <header>
                        <h3 class="mb-0">الدعم الفني الإضافي</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x2 subscription-details__client">
                        <p>content of the accordion</p>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                  <vue-collapsible-panel :expanded="false">
                    <template #title>
                      <header>
                        <h3 class="mb-0">الدعم الفني الإضافي</h3>
                      </header>
                    </template>
                    <template #content>
                      <div class="grid grid-1x2 subscription-details__client">
                        <p>content of the accordion</p>
                      </div>
                    </template>
                  </vue-collapsible-panel>
                </vue-collapsible-panel-group>
              </div>
            </tab-component>
            <tab-component name="الملخص والتكلفة">
              <vue-collapsible-panel-group>
                <vue-collapsible-panel>
                  <template #title>
                    <header>
                      <h3 class="mb-0">دفتر الحسابات</h3>
                    </header>
                  </template>
                  <template #content>
                    <div>
                      <!-- punch of checkbox features and its price with edit button -->
                    </div>
                  </template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>
                    <header>
                      <h3 class="mb-0">دفتر المخزون</h3>
                    </header>
                  </template>
                  <template #content>
                    <div class="grid grid-1x2 subscription-details__client">
                      <p>content of the accordion</p>
                    </div>
                  </template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>
                    <header>
                      <h3 class="mb-0">دفتر المشتريات</h3>
                    </header>
                  </template>
                  <template #content>
                    <div class="grid grid-1x2 subscription-details__client">
                      <p>content of the accordion</p>
                    </div>
                  </template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>
                    <header>
                      <h3 class="mb-0">دفتر الموارد البشرية</h3>
                    </header>
                  </template>
                  <template #content>
                    <div class="grid grid-1x2 subscription-details__client">
                      <p>content of the accordion</p>
                    </div>
                  </template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
            </tab-component>
          </tabs-component>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PlansService from "@/services/PlansService";
import PackageSpecCard from "@/components/subscriptions/PackageSpecCard.vue";
export default {
  components: { PackageSpecCard },
  data() {
    return {
      plan: {
        Name: { ArabicValue: null },
        Description: {
          ArabicValue: null,
        },
        FreePlan: false,
        Published: false,
        Subscribeable: false,
      },
    };
  },
  mounted() {
    const route = this.$route;
    if (route.path != "/create-plan") {
      const id = route.params.id;
      this.getPlanDetails(id);
    }
  },
  methods: {
    async getPlanDetails(planId) {
      this.plan = await PlansService.getPlanDetails(planId);
    },
  },
};
</script>

<style lang="scss">
</style>