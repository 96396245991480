<template>
  <div class="subscription mb-3">
    <div class="container grid grid3-1">
      <div class="subscription-details__box box">
        <header class="box-header">
          <h3>تفاصيل الاشتراك</h3>
        </header>
        <div class="box-body">
          <section class="subscription-details__section grid grid3-1">
            <!-- details col -->
            <div>
              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>رقم الاشتراك</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    two words here
                  </skeleton-text>
                  <p v-else>{{ subscription.Number }}</p>
                </div>
              </div>
              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>اسم الباقة</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    two words
                  </skeleton-text>
                  <p v-else>{{ subscription.Name }}</p>
                </div>
              </div>
              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>تاريخ الابتداء</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    two words
                  </skeleton-text>
                  <p v-else>{{ formateDate(subscription.StartBillingDate) }}</p>
                </div>
              </div>
              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>تاريخ الانتهاء</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    two words
                  </skeleton-text>
                  <p v-else>{{ formateDate(subscription.EndBillingDate) }}</p>
                </div>
              </div>
              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>سعر التجديد</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    two words
                  </skeleton-text>
                  <p v-else>
                    {{ subscription.ExpectedNextBillNetTotal }}
                    <template v-if="subscription.ServicePlan">
                      {{ getCurrency(subscription.ServicePlan) }}
                    </template>
                  </p>
                </div>
              </div>
              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>الحالة</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    two words
                  </skeleton-text>
                  <p v-else style="position: relative">
                    <template v-if="Object.keys(subscription).length">
                      {{ getStatus(subscription.Status) }}
                      <div
                        class="loader inline-loader"
                        v-if="subscription.Status == 6"
                      ></div>
                    </template>
                  </p>
                </div>
              </div>
              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>رابط التطبيق</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    more than one word its url
                  </skeleton-text>
                  <a
                    :href="subscription.Url"
                    target="_blank"
                    style="white-space: nowrap"
                    v-else
                  >
                    {{ subscription.Url }}
                  </a>
                </div>
              </div>

              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>اسم المستخدم</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    two words
                  </skeleton-text>
                  <p v-else>
                    <template v-if="subscription.ServiceAdminCredentials">
                      {{ subscription.ServiceAdminCredentials.EmailId }}
                    </template>
                  </p>
                </div>
              </div>

              <div class="subscription-details__section-item">
                <div class="subscription-details__section-item-header">
                  <h4>مزايا الاشتراك</h4>
                </div>
                <div class="subscription-details__section-item-body">
                  <skeleton-text v-if="loadingData" effect="wave">
                    more than one word its url
                  </skeleton-text>
                  <a
                    @click="showAdvanModal = true"
                    v-else
                    class="link c-primary"
                  >
                    عرض المزايا
                  </a>
                </div>
              </div>
            </div>

            <!-- duration col -->
            <div class="text-center" v-if="!isOutOfService">
              <skeleton-block
                v-if="loadingData"
                tag="div"
                width="170px"
                height="170px"
                borderRadius="50%"
                effect="wave"
              />

              <radial-progress
                v-else
                :progress="
                  !isSubscriptionActive
                    ? 100
                    : (subscription.RemainingDays / 360) * 100
                "
                :angle="-90"
                :color="
                  !isSubscriptionActive && !isOutOfService ? '#000' : '#009c94'
                "
                emptyColor="#DDDDDD"
                :size="170"
                :thickness="17"
                emptyThickness="17"
                animation="reverse 700 400"
                :gap="0"
                line="butt"
              >
                <template #legend-caption>
                  <template v-if="!isSubscriptionActive && !isOutOfService">
                    تم إيقافه
                  </template>
                  <template v-else>
                    <span class="days__number">
                      {{ subscription.RemainingDays }}
                    </span>

                    <div class="days__day">يوم</div>
                  </template>
                </template>
              </radial-progress>
              <template
                v-if="['Accountant', 'Manager', 'Administrator'].includes(role)"
              >
                <div class="activate-btn" v-if="loadingData">
                  <skeleton-block
                    tag="div"
                    width="170px"
                    height="30px"
                    effect="wave"
                  />
                </div>
                <div class="activate-btn" v-else>
                  <button
                    v-if="!isSubscriptionActive && !isOutOfService"
                    class="btn btn-success"
                    :disabled="loadingData"
                    @click="showStopSubscriptionModal"
                  >
                    {{ subscriptionStatus }}
                  </button>
                </div>
              </template>
            </div>
          </section>
          <section
            class="subscription-details__section subscription-details__features"
            v-if="features && features.length > 0"
          >
            <h3 class="mt-3">الإضافات</h3>
            <!-- <div class="key">
            </div>
            <div class="val">
              <a @click="showAdvanModal = true" class="link c-primary"
                >عرض المزايا</a
              >
            </div> -->
            <div class="hr"></div>
            <div class="grid grid-1x4 mt-5">
              <template v-for="feature in features" :key="feature.Id">
                <div class="feature" v-if="!feature.IsHidden">
                  <div class="feature-name">
                    <Popper :content="feature.Name.ArabicValue" hover arrow>
                      <i class="fas fa-info-circle"></i>
                    </Popper>
                    {{ feature.Name.ArabicValue }}
                  </div>
                  <div class="feature-info">
                    <!-- <span v-if="feature.">
                      <i class="fas fa-check"></i>
                    </span> -->
                    <span v-if="feature.IsUnlimitedQuota">
                      <i class="far fa-infinity"></i>
                    </span>
                    <span
                      v-if="
                        feature.AppFeatureType !== 0 &&
                        feature.AppFeatureType !== 2
                      "
                    >
                      {{ feature.AddionalQuotaPacks }}
                    </span>
                    <span v-else>
                      <i class="fas fa-check c-success"></i>
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </section>
          <section
            class="subscription-details__section subscription-details__features"
            v-if="currentCompFeatures && currentCompFeatures.length"
          >
            <h3 class="mt-3">المزايا المجانية</h3>
            <!-- <div class="key">
            </div>
            <div class="val">
              <a @click="showAdvanModal = true" class="link c-primary"
                >عرض المزايا</a
              >
            </div> -->
            <div class="hr"></div>
            <div class="grid grid-1x4 mt-5">
              <template
                v-for="{ Count, Feature } in currentCompFeatures"
                :key="Feature.Id"
              >
                <div class="feature">
                  <div class="feature-name">
                    <Popper :content="Feature.Name.ArabicValue" hover arrow>
                      <i class="fas fa-info-circle"></i>
                    </Popper>
                    {{ Feature.Name.ArabicValue }}
                  </div>
                  <div class="feature-info">
                    <span>
                      {{ Count }}
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </section>
          <vue-collapsible-panel-group>
            <vue-collapsible-panel :expanded="false">
              <template #title> معلومات العميل </template>
              <template #content>
                <div
                  class="grid grid-1x2 subscription-details__client"
                  v-if="Object.keys(client).length"
                >
                  <div class="client-info">
                    <!-- client info  -->
                    <div class="client-info__item">
                      <div class="client-info__item-key">
                        <i class="fas fa-user"></i>
                        <span>الاسم</span>
                      </div>
                      <div class="client-info__item-value">
                        {{ client.Users[0].Name }}
                      </div>
                    </div>
                    <div class="client-info__item">
                      <div class="client-info__item-key">
                        <i class="far fa-building"></i>
                        <span>اسم الشركة</span>
                      </div>
                      <div class="client-info__item-value">
                        {{ client.Name }}
                        <router-link
                          :to="`/clients/${client.Id}`"
                          class="btn edit-email__btn"
                        >
                          <i class="fas fa-external-link-square-alt"></i>
                        </router-link>
                      </div>
                    </div>
                    <div class="client-info__item">
                      <div class="client-info__item-key">
                        <i class="far fa-envelope"></i>
                        <span>البريد الالكتروني</span>
                      </div>
                      <div class="client-info__item-value">
                        {{ client.Users[0].Email }}
                      </div>
                    </div>
                    <div class="client-info__item">
                      <div class="client-info__item-key">
                        <i class="fas fa-mobile-alt"></i>
                        <span>الجوال</span>
                      </div>
                      <div class="client-info__item-value">
                        {{ client.Users[0].MobileNumber }}
                      </div>
                    </div>
                    <!-- //client info -->
                  </div>
                  <div class="client-balance flex">
                    <h3 class="fs-26 ml-2">الرصيد :</h3>
                    <h3
                      :class="`fs-26 ${
                        client.Balance < 0 ? 'c-danger' : 'c-success'
                      }`"
                    >
                      {{ client.Balance }}
                      ر.س
                    </h3>
                  </div>
                </div>
              </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <template #title> تاريخ الإشتراك </template>
              <template #content>
                <!-- here is the content -->
                <table>
                  <thead>
                    <tr>
                      <th style="width: 15%; padding-right: 1rem">
                        تاريخ الاشتراك
                      </th>
                      <th>الوصف</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="{
                        Id,
                        Date,
                        Description,
                      } in subscription.SubscriptionHistory"
                      :key="Id"
                    >
                      <td style="width: 15%; padding-right: 1rem">
                        {{ formateDate(Date) }}
                      </td>
                      <td>{{ Description.ArabicValue }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
              <template #title>الفوترة</template>
              <template #content>
                <!-- some content -->
                <invoices-table :invoices="invoices" />
              </template>
            </vue-collapsible-panel>
          </vue-collapsible-panel-group>
        </div>
      </div>
      <div
        class="subscription-actions box"
        v-if="subscription.Account == 'Migrate'"
      >
        <header class="box-header">
          <h3>العمليات</h3>
        </header>
        <div class="box-body">
          <h4 style="line-height: 1.5">
            هذا الاشتراك يحتاج تحديث من اجل تمكين ادارته
            <br />
            <a
              class="link mt-1"
              href="https://businessclouds.dafater.biz/app.html#List/Engineering%20Service%20Request"
              target="_blank"
            >
              تواصل مع ادارة تمكين المنتج
            </a>
          </h4>
        </div>
      </div>
      <template
        v-if="
          [
            'Manager',
            'Marketer',
            'Accountant',
            'CS_Account_Manager',
            'CS_Onboarding_Specialist',
            'Administrator',
          ].includes(role)
        "
      >
        <div
          class="subscription-actions box"
          v-if="
            isSubscriptionActive &&
            !isOutOfService &&
            subscription.Account !== 'Migrate'
          "
        >
          <header class="box-header">
            <h3>العمليات</h3>
          </header>
          <div class="box-body">
            <button
              v-if="['Manager', 'Marketer', 'Administrator'].includes(role)"
              class="btn btn-warning btn-icon"
              :disabled="loadingData"
              @click="configureSubscription"
            >
              <i class="fas fa-star"></i>
              اضافة مميزات مدفوعة
            </button>
            <button
              v-if="['Manager', 'Administrator'].includes(role)"
              class="btn btn-warning btn-icon"
              :disabled="loadingData"
              @click="
                () =>
                  $router.push(`/complimentary-features/${$route.params.id}`)
              "
            >
              <i class="fas fa-star"></i>
              اضافة مميزات مجانية
            </button>
            <button
              v-if="['Accountant', 'Administrator'].includes(role)"
              class="btn btn-success btn-icon"
              :disabled="loadingData"
              @click="expandSubscriptionModalCB(expandSubscription, true)"
            >
              <i class="fas fa-plus"></i>
              زيادة مدة الاشتراك
            </button>
            <button
              v-if="
                [
                  'Manager',
                  'CS_Account_Manager',
                  'CS_Onboarding_Specialist',
                  'Administrator',
                ].includes(role)
              "
              class="btn btn-info btn-icon"
              :disabled="loadingData || isEiradService"
              @click="changeSubscriptionUrlModalCB(null, true)"
            >
              <i class="fas fa-link"></i>
              تغيير رابط الاشتراك
            </button>
            <button
              v-if="['Accountant', 'Manager', 'Administrator'].includes(role)"
              class="btn btn-danger btn-icon"
              :disabled="loadingData"
              @click="showStopSubscriptionModal"
            >
              <i class="fas fa-ban"></i>
              {{ subscriptionStatus }}
            </button>
            <button
              v-if="['Manager', 'Accountant', 'Administrator'].includes(role)"
              class="btn btn-danger btn-icon"
              :disabled="loadingData"
              @click="cancelSubscriptionModalCB(cancelSubscription, true)"
            >
              <i class="fas fa-times"></i>
              إلغاء الاشتراك
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
  <!-- change subscription url modal  -->
  <manage-modal
    :hasFooter="false"
    @modalStatus="(cb) => (changeSubscriptionUrlModalCB = cb())"
    class="change-subscription-url-modal text-center"
  >
    <template #header> تغير رابط الاشتراك </template>
    <template #body>
      <!-- here is the content  -->
      <change-subscription-url
        @changed="
          () => {
            changeSubscriptionUrlModalCB(null, false);
            getSubscriptionDetails($route.params.id);
            $toast.success('تم تغيير الرابط بنجاح');
          }
        "
      />

      <button
        class="btn btn-danger mx-1"
        @click="changeSubscriptionUrlModalCB(null, false)"
      >
        إلغاء
      </button>
    </template>
  </manage-modal>
  <!-- ./ end change subscription url modal  -->

  <manage-modal
    :hasFooter="false"
    @modalStatus="(cb) => (configureSubscriptionModalCB = cb())"
    class="configure-subscription-modal"
  >
    <template #header> تغير الباقة و اضافة مميزات </template>
    <template #body>
      <configure-subscription-form :selectedService="1" />
      <!-- here is configure  -->
      <div class="configure-subscription-actions">
        <button class="btn btn-success" @click="updateSubscription">
          إصدار فاتورة
        </button>
        <button
          class="btn btn-danger"
          @click="configureSubscriptionModalCB(null, false)"
        >
          إلغاء
        </button>
      </div>
    </template>
  </manage-modal>
  <manage-modal
    @modalStatus="(cb) => (expandSubscriptionModalCB = cb())"
    class="expand-subscription-modal"
  >
    <template #header> زيادة مدة الاشتراك </template>
    <template #body>
      <div class="form-group">
        <label for="duration">مده الاشتراك بـ الايام</label>
        <div class="grid grid-3/1">
          <input type="text" v-model="durationField" />
          <div class="action">
            <button class="btn btn-success" @click="durationField += 1">
              <i class="fas fa-plus"></i>
            </button>
            <button class="btn btn-success" @click="durationField -= 1">
              <i class="fas fa-minus"></i>
            </button>
          </div>
        </div>
        <div class="flex mt-2">
          <div>مقترحات</div>
          <a
            class="badge badge-success"
            v-for="duration in durations"
            :key="duration.value"
            @click="durationField = duration.value"
            >{{ duration.name }}</a
          >
        </div>
      </div>
    </template>
  </manage-modal>
  <manage-modal @modalStatus="(cb) => (changeSubscriptionStatusModalCB = cb())">
    <template #header> {{ subscriptionStatus }} الأشتراك </template>
    <template #body>
      <p>هل أنت متأكد من {{ subscriptionStatus }} الأشتراك؟</p>
    </template>
  </manage-modal>
  <!-- cancel subscription modal -->
  <manage-modal @modalStatus="(cb) => (cancelSubscriptionModalCB = cb())">
    <template #header> إلغاء الأشتراك </template>
    <template #body>
      <p>هل أنت متأكد من إلغاء الأشتراك؟</p>
    </template>
  </manage-modal>
  <!-- ./ end cancel subscription modal -->
  <!-- <manage-modal @modalStatus="(cb) => (startSubscriptionModalCB = cb())">
    <template #header> تفعيل الأشتراك </template>
    <template #body>
      <p>هل أنت متأكد من تفعيل الأشتراك؟</p>
    </template>
  </manage-modal> -->
  <!-- features modal -->
  <m-modal
    class="modal-advan"
    v-show="showAdvanModal"
    @close="showAdvanModal = false"
    hasHeaderEsc
  >
    <!-- here should put features -->
    <template #header> مزايا الاشتراك</template>
    <template #body>
      <table>
        <thead>
          <tr>
            <th>رابط الاشتراك</th>
            <th>الوصف</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="subscription.ServicePlan">
            <template v-for="feature in subscription.ServicePlan.PlanFeatures">
              <!-- <template v-for="feature in subscription.EntitledFeatures"> -->
              <tr
                v-if="feature.AppFeatureType !== 1 && feature.InitialQuotaPack"
                :key="feature.id"
              >
                <td>{{ feature.Name.ArabicValue }}</td>
                <td
                  v-if="
                    feature.AppFeatureType == 0 || feature.AppFeatureType == 2
                  "
                >
                  <i class="fas fa-check-circle c-success"></i>
                </td>
                <td v-else>
                  <!-- <i class="fal fa-repeat c-info"></i> -->
                  <!-- {{ feature.Quota - feature.ConsumedQuotas }} وحدات -->
                  {{ feature.InitialQuotaPack }} وحدات
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </m-modal>
  <!-- <invoice-details-modal
    @modalStatus="(cb) => (invoiceDetailsModalCB = cb())"
    @close="invoiceDetailsModalCB(null, false)"
    :invoice="invoice"
  /> -->
  <div class="loader loader-page-layout" v-if="loadingTransaction"></div>
  <div class="loader" v-if="loading"></div>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import ClientService from "@/services/ClientService";
import ConfigureSubscriptionForm from "@/components/subscriptions/ConfigureSubscriptionForm.vue";
import ChangeSubscriptionUrl from "@/components/subscriptions/ChangeSubscriptionUrl.vue";
import ManageModal from "@/components/common/ManageModal.vue";
import MModal from "@/components/common/MModal.vue";
// import InvoiceDetailsModal from "@/components/invoice/InvoiceDetailsModal.vue";
import InvoicesTable from "@/components/common/InvoicesTable.vue";
import { formatDate } from "@/util/date";
import Popper from "vue3-popper";
import { SkeletonText, SkeletonBlock } from "skeleton-elements/vue";
import { Currencies } from "@/util/utilities";

export default {
  components: {
    ManageModal,
    MModal,
    ConfigureSubscriptionForm,
    ChangeSubscriptionUrl,
    InvoicesTable,
    // InvoiceDetailsModal,
    Popper,
    SkeletonText,
    SkeletonBlock,
  },
  props: {
    role: String,
  },
  data() {
    return {
      loadingData: false,
      loading: false,
      loadingTransaction: false,
      subscription: {},
      client: {},
      subscriptionStatus: "إيقاف",
      isSubscriptionActive: false,
      isOutOfService: false,
      configureSubscriptionModalCB: null,
      changeSubscriptionStatusModalCB: null,
      changeSubscriptionUrlModalCB: null,
      // startSubscriptionModalCB: null,
      expandSubscriptionModalCB: null,
      cancelSubscriptionModalCB: null,
      showAdvanModal: false,
      durationField: 0,
      durations: [
        {
          name: "شهر",
          value: 30,
        },
        {
          name: "شهرين",
          value: 60,
        },
        {
          name: "3 أشهر",
          value: 90,
        },
        {
          name: "6 أشهر",
          value: 180,
        },
        {
          name: "سنة",
          value: 365,
        },
      ],
      features: null,
      invoiceDetailsModalCB: null,
      changePrintModalCB: null,
      invoices: [],
      invoice: {},
      currentCompFeatures: null,
      isEiradService: this.$store.state.serviceId == 3,
    };
  },
  mounted() {
    this.subscription.Status = 0;
    this.getSubscriptionDetails(this.$route.params.id);
    this.getCurrentCompFeatures();
  },
  methods: {
    async configureSubscription() {
      this.loadingTransaction = true;
      try {
        const response =
          await SubscriptionService.createSubscriptionTransaction(
            [{ subscriptionId: this.subscription.Id }],
            "configuration"
          );
        if (response && response.status == 200) {
          this.$router.push(
            `/configure-subscription/${this.client.Id}/${response.data.Id}`
          );
        } else {
          this.$toast.error("نأسف هناك خطأ ما!");
          this.loadingTransaction = false;
        }
      } catch (error) {
        this.loadingTransaction = false;
      }
    },
    async getSubscriptionDetails(id) {
      this.loadingData = true;
      const filters = [
        { includeServicePlan: true },
        { includeEntitledFeatures: true },
        { includeSubscriber: true },
        { includeBillingRecords: true },
        { includeCloudInstance: true },
        { includeSubscriptionHistory: true },
      ];
      const subscription = await SubscriptionService.getSubscriptionDetails(
        id,
        filters
      );
      const client = await ClientService.getClientDetails(
        subscription.Subscriber.Id
      );
      this.client = client;
      this.subscription = subscription;
      this.invoices = subscription.BillingRecords.map((invoice) => ({
        ...invoice,
        Description: invoice.Description.ArabicValue,
      }));

      this.subscription.Name = subscription.ServicePlan.Name.ArabicValue;
      const status = this.subscription.Status;
      if (status == 2 || status == 8) {
        this.isSubscriptionActive = true;
      } else if (status == 4) {
        this.isSubscriptionActive = false;
      } else {
        this.isOutOfService = true;
      }
      this.subscriptionStatus = this.isSubscriptionActive ? "إيقاف" : "تفعيل";
      this.features = this.subscription.EntitledFeatures.filter(
        (item) => item.AddionalQuotaPacks && item.AppFeatureType != 1
      );
      this.loadingData = false;
    },
    updateSubscription() {
      this.configureSubscriptionModalCB(null, false);
    },
    async expandSubscription() {
      this.loading = true;
      const response = await SubscriptionService.postFreeDays(
        this.$route.params.id,
        this.durationField
      );
      if (response.status == 200) {
        this.expandSubscriptionModalCB(null, false);
        this.getSubscriptionDetails(this.$route.params.id);
        this.$toast.success("تم تمديد مدة الاشتراك بنجاح.");
        this.loading = false;
      } else {
        this.$toast.error("حدث خطأ ما!");
        this.loading = false;
      }
    },
    showStopSubscriptionModal() {
      this.changeSubscriptionStatusModalCB(this.confirmStopSubscription, true);
    },
    async cancelSubscription() {
      // here is the logic of cancel
      this.loading = true;
      try {
        const response = await SubscriptionService.removeSubscription(
          this.$route.params.id
        );
        if (response && response.status == 200) {
          this.cancelSubscriptionModalCB(null, false);
          this.subscription = {};
          this.getSubscriptionDetails(this.$route.params.id);
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.$toast.error("حدث خطأ ما!");
        this.loading = false;
      }
    },
    async confirmStopSubscription() {
      this.loading = true;
      const subscriptionState = this.subscription.Status;
      const state =
        subscriptionState <= 3 ||
        (subscriptionState > 7 && subscriptionState < 13)
          ? "suspend"
          : "activate";
      const response = await SubscriptionService.changeSubscriptionStatus(
        this.$route.params.id,
        state
      );
      if (response.status == 200) {
        this.changeSubscriptionStatusModalCB(null, false);
        this.getSubscriptionDetails(this.$route.params.id);
        this.$toast.success(`تم ${this.subscriptionStatus} الاشتراك بنجاح.`);
        this.loading = false;
      } else {
        this.$toast.error("حدث خطأ ما!");
        this.loading = false;
      }
    },
    formateDate(date) {
      return formatDate(date);
    },
    getDuration(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    getStatus(code) {
      return SubscriptionService.getStatus(code, "ar");
    },
    // changeStopSubscriptionModalStatus(cb) {
    //   this.changeStopSubscriptionModalCB = cb();
    // },
    // changeStopSubscriptionModalStatus(cb) {
    //   this.changeStopSubscriptionModalCB = cb();
    // },
    async getCurrentCompFeatures() {
      this.loading = true;
      try {
        const response = await SubscriptionService.getCurrentCompFeatures(
          this.$route.params.id
        );
        if (response && response.status == 200) {
          this.loading = false;
          this.currentCompFeatures = response.data.sort(
            (a, b) => a.DisplayOrder - b.DisplayOrder
          );
        } else {
          this.loading = false;
          this.$toast.error("هناك خطأ ما!");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("هناك خطأ ما!");
      }
    },
    getCurrency(ServicePlan) {
      return Currencies[ServicePlan.Currency].ar;
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  --popper-theme-background-color-hover: #ddddddd5;
  --popper-theme-background-color: #dddddd80;
  --popper-theme-padding: 1rem 2rem;
}
:deep(.popper) {
  background: #ddddddd5;
  padding: 1rem 2rem;
  border-radius: 7px;
  color: #525732;
  font-size: 1.4rem;
  &:hover {
    background: #ddddddd5;
  }
  // font-weight: bold;
  // text-transform: uppercase;
}
:deep(.popper #arrow::before) {
  background: #ddddddd5;
  &:hover {
    background: #ddddddd5;
  }
}
th,
td {
  text-align: right;
}
.activate-btn {
  margin-top: 2rem;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 999;
  &::after {
    position: relative;
    z-index: 99;
    display: block;
    top: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }
}
.inline-loader {
  width: auto;
  height: auto;
  position: absolute;
  right: -5rem;
  left: auto;
  top: 50%;
}
</style>