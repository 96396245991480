<template>
  <div class="subscriptions-table">
    <table>
      <thead>
        <tr>
          <th>اسم الشركة</th>
          <th>الإسم</th>
          <th>البريد الالكتروني</th>
          <th>الجوال</th>
          <th>الرصيد</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><skeleton-text effect="wave">comapnyname</skeleton-text></td>
          <td><skeleton-text effect="wave">name</skeleton-text></td>
          <td><skeleton-text effect="wave">mail@email.com</skeleton-text></td>
          <td><skeleton-text effect="wave">0111123454</skeleton-text></td>
          <td><skeleton-text effect="wave">2500.00</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">comapnyname here</skeleton-text></td>
          <td><skeleton-text effect="wave">name of client</skeleton-text></td>
          <td>
            <skeleton-text effect="wave">some_mail@email.com</skeleton-text>
          </td>
          <td><skeleton-text effect="wave">0111123454</skeleton-text></td>
          <td><skeleton-text effect="wave">200.00</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">comapny here</skeleton-text></td>
          <td><skeleton-text effect="wave">client name</skeleton-text></td>
          <td><skeleton-text effect="wave">some_@email.com</skeleton-text></td>
          <td><skeleton-text effect="wave">0111123454</skeleton-text></td>
          <td><skeleton-text effect="wave">20075.00</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">comapnyname</skeleton-text></td>
          <td><skeleton-text effect="wave">name</skeleton-text></td>
          <td><skeleton-text effect="wave">mail@email.com</skeleton-text></td>
          <td><skeleton-text effect="wave">0111123454</skeleton-text></td>
          <td><skeleton-text effect="wave">2500.00</skeleton-text></td>
        </tr>
        <tr>
          <td><skeleton-text effect="wave">comapnyname here</skeleton-text></td>
          <td><skeleton-text effect="wave">name of client</skeleton-text></td>
          <td>
            <skeleton-text effect="wave">some_mail@email.com</skeleton-text>
          </td>
          <td><skeleton-text effect="wave">0111123454</skeleton-text></td>
          <td><skeleton-text effect="wave">200.00</skeleton-text></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { SkeletonText } from "skeleton-elements/vue";
export default {
  components: {
    SkeletonText,
  },
};
</script>

<style lang="scss">
</style>