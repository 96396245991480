<template>
  <div class="create-subscription container mb-3">
    <div :class="`grid ${Object.keys(selectedPlan).length ? 'grid-1x2' : ''}`">
      <main class="box">
        <header class="box-header flex-between flex-center-y">
          <h3 class="box-title" v-if="client">
            اشتراك جديد للعميل: {{ client.Users[0].Name }}
          </h3>
          <a href="#" @click="$router.back()" class="btn-icon">
            <i class="fas fa-arrow-right"></i>
            رجوع
          </a>
        </header>

        <FormKit
          type="form"
          id="subscriptionForm"
          ref="subscriptionForm"
          :actions="false"
          v-model="formData"
          #default="{ state }"
        >
          <h3 class="c-danger" v-if="!state.valid && submited">
            من فضلك املاء البيانات بشكل صحيح
          </h3>
          <div class="form-section">
            <h3 class="form-section__title">بيانات الأشتراك</h3>
            <FormKit
              type="select"
              label="الباقة"
              name="servicePlan"
              v-model="servicePlan"
              placeholder="اختر الباقة"
              :options="servicePlans"
              validation="required"
              @change="setSelectedPlan"
            />
            <!-- pormotion code field -->
            <div class="my-2" v-if="selectedPlan">
              <!-- <FormKit
                type="text"
                label="الرمز الترويجي"
                v-model="promotionCode"
                validation="required"
              /> -->
              <template
                v-if="
                  Object.keys(selectedPlan).length &&
                  selectedPlan.HasPromotionCode
                "
              >
                <div class="loading inline-loading" v-if="loadingPromos"></div>
                <template v-else>
                  <label for="promocodes">الرمز الترويجي</label>
                  <select id="promocodes" class="mt-1" v-model="promotionCode">
                    <option
                      v-for="pc in promoCodes"
                      :key="pc.Id"
                      :value="pc.Code"
                    >
                      {{ pc.Code }}
                    </option>
                  </select>
                </template>
              </template>
            </div>
            <!-- <div class="field my-2">
              <label class="checkbox-label flex">
                <span>اشتراك مخصص</span>
                <div class="custom-checkbox">
                  <input
                    type="checkbox"
                    v-model="customizable"
                    name="customizable"
                  />
                  <i class="far fa-check"></i>
                </div>
              </label>
            </div> -->
          </div>
        </FormKit>
        <!-- <button class="btn btn-success mA-2" @click="submitForm">
          اشتراك قياسي
        </button> -->
        <button class="btn btn-warning mA-2" @click="customSubscription">
          اشتراك
        </button>
      </main>
      <aside v-if="Object.keys(selectedPlan).length">
        <SelectedServicePlanCard :selectedPlan="selectedPlan" />
      </aside>
    </div>
  </div>
  <div class="loader loader-page-layout" v-if="loading"></div>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import SelectedServicePlanCard from "@/components/subscriptions/SelectedServicePlanCard.vue";
import { getNowDate } from "@/util/date";
import ClientService from "@/services/ClientService";
export default {
  components: { SelectedServicePlanCard },
  props: {
    role: String,
  },
  data() {
    return {
      servicePlans: [],
      allPlans: [],
      selectedPlan: {},
      client: null,
      servicePlan: null,
      customizable: false,
      promotionCode: null,
      formData: {},
      submited: false,
      loading: false,
      loadingPromos: false,
      promoCodes: false,
    };
  },
  watch: {},
  async mounted() {
    if (
      !["Manager", "CS_Onboarding_Specialist", "Administrator"].includes(
        this.role
      )
    ) {
      this.$router.replace("/not-authed");
    }
    this.loading = true;
    const plans = await SubscriptionService.getAllServicePlans();
    if (plans.status == 200) {
      this.loading = false;
      this.allPlans = plans.data;
      this.servicePlans = this.allPlans
        .filter((c) => c.Status == 1)
        .map((c) => {
          return {
            label: c.Name.ArabicValue,
            value: c.Id,
          };
        });
    } else this.loading = false;
    this.statusEnums = SubscriptionService.statusEnums;
    this.$emit("submitFunc", () => this.validateForm());
    this.client = await ClientService.getClientDetails(
      this.$route.params.id,
      false
    );
  },
  methods: {
    async setSelectedPlan() {
      this.loading = true;
      const selectedPlan = this.allPlans.find((p) => p.Id == this.servicePlan);
      if (selectedPlan.HasPromotionCode) {
        this.loadingPromos = true;
        try {
          const response = await SubscriptionService.getPromocodes(
            this.servicePlan
          );
          if (response && response.status == 200) {
            this.promoCodes = response.data;
            this.loadingPromos = false;
          } else {
            this.loadingPromos = false;
          }
        } catch (error) {
          this.loadingPromos = false;
        }
      }
      try {
        const response = await SubscriptionService.getPlanFeatures(
          this.servicePlan
        );
        if (response && response.status == 200) {
          this.loading = false;
          selectedPlan.PlanFeatures = response.data;
          this.$emit("onSelectPlan", this.selectedPlan);
        } else {
          this.loading = false;
          this.$toast.error("فشل في عرض مميزات الباقة!");
        }
      } catch (error) {
        this.loading = false;
      }
      this.selectedPlan = selectedPlan;
    },
    customSubscription() {
      if (this.selectedPlan.HasPromotionCode && !this.promotionCode) {
        return this.$toast.error("من فضلك اختر رمز ترويجي!");
      }
      this.customizable = true;
      this.submitForm();
    },
    async submitForm() {
      if (this.selectedPlan.HasPromotionCode && !this.promotionCode) {
        return this.$toast.error("من فضلك اختر رمز ترويجي!");
      }
      const valid = this.$refs["subscriptionForm"].node.context.state.valid;
      if (valid) {
        // send request to the back end
        const params = [
          { subscriberId: this.$route.params.id },
          { servicePlanId: this.servicePlan },
        ];
        if (this.promotionCode) params.push({ promoCode: this.promotionCode });
        this.loading = true;
        if (!this.customizable) {
          try {
            const response = await SubscriptionService.createSubscription(
              params
            );
            if (response.status == 201) {
              this.$router.push(`/subscriptions/${response.data.Id}`);
              this.loading = false;
            } else {
              this.loading = false;
            }
          } catch (error) {
            this.loading = false;
            this.$router.push(`/clients/${this.$route.params.id}`);
          }
        } else {
          try {
            const response =
              await SubscriptionService.createSubscriptionTransaction(params);
            if (response.status == 201) {
              this.$router.push(
                `/create-subscription/${this.$route.params.id}/${response.data.Id}`
              );
              this.loading = false;
            } else {
              this.loading = false;
            }
          } catch (error) {
            this.loading = false;
          }
        }
        this.loading = false;
      }
    },
    validateForm() {
      // this.$formkit.submit("clientForm");
      const valid = this.$refs["subscriptionForm"].node.context.state.valid;
      this.submited = true;
      return {
        valid,
        formData: { ...this.formData },
      };
    },
    getNowDate() {
      return getNowDate();
    },
  },
};
</script>

<style lang="scss">
#subscriptionForm {
  .field {
    display: inline-flex;
    label {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      // justify-content: ;
    }
  }
}
</style>