import { createRouter, createWebHistory } from "vue-router";

// Gaurds
import { registerGuard } from "./Guard";
import { authRoleGuard } from "./Guard";
import { registerTitleToPage } from "./Pages";

import Subscriptions from "@/pages/SubscriptionsPage";
import SubscriptionDetails from "@/pages/SubscriptionDetailsPage";
import ChangeSubscription from "@/pages/ChangeSubscriptionPage";
import Clients from "@/pages/ClientsPage";
import ClientDetails from "@/pages/ClientDetailsPage";
import CreateSubscription from "@/pages/CreateSubscriptionPage";
import CreateClientSubscriptionPage from "@/pages/CreateClientSubscriptionPage";
import CustomizeClientSubscriptionPage from "@/pages/CustomizeClientSubscriptionPage";
import ComplimentaryFeaturesPage from "@/pages/ComplimentaryFeaturesPage";
import Profile from "@/pages/ProfilePage";
import IndicationsPage from "@/pages/IndicationsPage";
import AuditTrailPage from "@/pages/AuditTrailPage";
import AllProductsReportPage from "@/pages/AllProductsReportPage";
import PlansPage from "@/pages/PlansPage";
import PlanFormPage from "@/pages/PlanFormPage";
import NotAuthed from "@/pages/NotAuthed";
import CreateClient from "@/pages/CreateClientPage";

import PartnersPage from "@/pages/PartnersPage";
import PartnerDetailsPage from "@/pages/PartnerDetailsPage";
import CreatePartnerPage from "@/pages/CreatePartnerPage";
import PartnersReportPage from "@/pages/PartnersReportPage";

const routes = [
  {
    path: "/",
    name: "Subscriptions",
    component: Subscriptions,
    meta: {
      requiresAuth: true,
      title: "Manage - الاشتراكات",
    },
  },
  {
    path: "/subscriptions/:id",
    name: "SubscriptionDetails",
    component: SubscriptionDetails,
    meta: {
      requiresAuth: true,
      title: "Manage - تفاصيل الاشتراك",
    },
  },
  {
    path: "/subscriptions/edit/:id",
    name: "ChangeSubscription",
    component: ChangeSubscription,
    meta: {
      requiresAuth: true,
      authorizedRoles: ["ADMIN"],
      title: "Manage - تغيير الاشتراك",
    },
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: {
      requiresAuth: true,
      title: "Manage - العملاء",
    },
  },
  {
    path: "/clients/:id",
    name: "ClientDetails",
    component: ClientDetails,
    meta: {
      requiresAuth: true,
      title: "Manage - تفاصيل العميل",
    },
  },
  {
    path: "/create-subscription",
    name: "CreateSubscription",
    component: CreateSubscription,
    meta: {
      requiresAuth: true,
      title: "Manage - إنشاء إشتراك جديد",
    },
  },
  {
    path: "/create-subscription/:id",
    name: "CreateClientSubscriptionPage",
    component: CreateClientSubscriptionPage,
    meta: {
      requiresAuth: true,
      title: "Manage - إنشاء إشتراك جديد للعميل",
    },
  },
  {
    path: "/create-subscription/:id/:transactionId",
    name: "CustomizeClientSubscriptionPage",
    component: CustomizeClientSubscriptionPage,
    meta: {
      requiresAuth: true,
      title: "Manage - إنشاء إشتراك جديد للعميل",
    },
  },
  {
    path: "/configure-subscription/:id/:transactionId",
    name: "ConfigureClientSubscriptionPage",
    component: CustomizeClientSubscriptionPage,
    meta: {
      requiresAuth: true,
      title: "Manage - تعديل إشتراك للعميل",
    },
  },
  {
    path: "/complimentary-features/:id",
    name: "ComplimentaryFeaturesPage",
    component: ComplimentaryFeaturesPage,
    meta: {
      requiresAuth: true,
      title: "Manage - إضافة مميزات مجانية",
    },
  },
  {
    path: "/create-client",
    name: "CreateClient",
    component: CreateClient,
    meta: {
      requiresAuth: true,
      title: "Manage - إنشاء عميل جديد",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      title: "Manage - حسابي",
    },
  },
  {
    path: "/indications",
    name: "Indications",
    component: IndicationsPage,
    meta: {
      requiresAuth: true,
      title: "Manage - المؤشرات",
    },
  },
  {
    path: "/audit-trail",
    name: "AuditTrail",
    component: AuditTrailPage,
    meta: {
      requiresAuth: true,
      title: "Manage - تقارير سجل العمليات",
    },
  },
  {
    path: "/products-report",
    name: "AllProductsReport",
    component: AllProductsReportPage,
    meta: {
      requiresAuth: true,
      title: "Manage - تقرير جميع المنتجات",
    },
  },
  {
    path: "/plans",
    name: "Plans",
    component: PlansPage,
    meta: {
      requiresAuth: true,
      authorizedRoles: ["ADMIN"],
      title: "Manage - الباقات",
    },
  },
  {
    path: "/create-plan",
    component: PlanFormPage,
    meta: {
      requiresAuth: true,
      authorizedRoles: ["ADMIN"],
      title: "Manage - أنشئ باقة",
    },
  },
  {
    path: "/edit-plan/:id",
    component: PlanFormPage,
    meta: {
      requiresAuth: true,
      authorizedRoles: ["ADMIN"],
      title: "Manage - عدل الباقة",
    },
  },
  {
    path: "/partners",
    name: "Partners",
    component: PartnersPage,
    meta: {
      requiresAuth: true,
      title: "Manage - الشركاء",
    },
  },
  {
    path: "/partners/:id",
    name: "PartnerDetails",
    component: PartnerDetailsPage,
    meta: {
      requiresAuth: true,
      title: "Manage - الشركاء",
    },
  },
  {
    path: "/create-partner",
    name: "CreatePartner",
    component: CreatePartnerPage,
    meta: {
      requiresAuth: true,
      title: "Manage - الشركاء",
    },
  },
  {
    path: "/edit-partner/:id",
    name: "EditPartner",
    component: CreatePartnerPage,
    meta: {
      requiresAuth: true,
      title: "Manage - الشركاء",
    },
  },
  {
    path: "/partners-report",
    name: "PartnersReport",
    component: PartnersReportPage,
    meta: {
      requiresAuth: true,
      title: "Manage - تقرير الشركاء",
    },
  },
  {
    path: "/not-authed",
    component: NotAuthed,
    meta: {
      title: "Manage - غير مسموح الصلاحية",
    },
  },
  {
    path: "/home",
    redirect: "/",
  },
  {
    path: "/subscriptions",
    redirect: "/",
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

registerGuard(router);
// authRoleGuard(router);
registerTitleToPage(router);

export default router;
