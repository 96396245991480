<template>
  <div class="client">
    <div class="container">
      <vue-collapsible-panel-group>
        <vue-collapsible-panel>
          <template #title>
            <header>
              <h3 class="c-white mb-0">معلومات العميل</h3>
            </header>
          </template>
          <template #content>
            <div class="grid grid-1x2 subscription-details__client">
              <div class="client-info">
                <!-- client info  -->
                <div class="client-info__item">
                  <div class="client-info__item-key">
                    <i class="fas fa-user"></i>
                    <span>الاسم</span>
                  </div>
                  <div class="client-info__item-value">
                    <template v-if="Object.keys(client).length > 0">{{
                      client.Users.Name
                    }}</template>
                    <skeleton-text effect="wave" v-else
                      >name of client</skeleton-text
                    >
                  </div>
                </div>
                <div class="client-info__item">
                  <div class="client-info__item-key">
                    <i class="far fa-building"></i>
                    <span>اسم الشركة</span>
                  </div>
                  <div class="client-info__item-value">
                    <template v-if="Object.keys(client).length > 0">{{
                      client.Name
                    }}</template>
                    <skeleton-text effect="wave" v-else
                      >name of company</skeleton-text
                    >
                  </div>
                </div>
                <div class="client-info__item">
                  <div class="client-info__item-key">
                    <i class="far fa-envelope"></i>
                    <span>البريد الالكتروني</span>
                  </div>
                  <div class="client-info__item-value flex-center-y">
                    <template v-if="Object.keys(client).length > 0">
                      {{ client.Users.Email }}
                      <!-- <button
                        class="btn edit-email__btn"
                        @click="
                          editEmailModalCB(
                            () => updateEmail(client.Users.Email),
                            true
                          )
                        "
                      >
                        <i class="fas fa-edit"></i>
                      </button> -->
                    </template>
                    <skeleton-text effect="wave" v-else
                      >email@email.com</skeleton-text
                    >
                  </div>
                </div>
                <div class="client-info__item">
                  <div class="client-info__item-key">
                    <i class="fas fa-mobile-alt"></i>
                    <span>الجوال</span>
                  </div>
                  <div class="client-info__item-value">
                    <template v-if="Object.keys(client).length > 0">
                      {{ client.Users.MobileNumber }}
                    </template>
                    <skeleton-text effect="wave" v-else
                      >0111111117</skeleton-text
                    >
                  </div>
                </div>
                <!-- //client info -->
              </div>
              <div class="client-balance-actions">
                <div class="client-balance">
                  <h3 class="client-balance__key fs-26 ml-2">الرصيد :</h3>
                  <h3
                    :class="`client-balance__value ${
                      client.Balance < 0 ? 'c-danger' : 'c-success'
                    }`"
                  >
                    <template v-if="Object.keys(client).length > 0">
                      {{ client.Balance }} ر.س
                    </template>
                    <skeleton-text effect="wave" v-else
                      >0111111117</skeleton-text
                    >
                  </h3>
                </div>
                <div class="client-actions flex">
                  <button
                    v-if="['Accountant', 'Administrator'].includes(role)"
                    class="btn btn-success"
                    @click="addBalanceModalCB(() => addBalance(balance), true)"
                  >
                    أضف رصيد
                  </button>
                  <router-link
                    v-if="
                      [
                        'Manager',
                        'CS_Onboarding_Specialist',
                        'Administrator',
                      ].includes(role)
                    "
                    :to="`/create-subscription/${$route.params.id}`"
                    class="btn btn-danger"
                  >
                    <i class="fas fa-plus-circle"></i>
                    اشتراك جديد
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </vue-collapsible-panel>
        <vue-collapsible-panel :expanded="false">
          <template #title>إشتراكات العميل</template>
          <template #content>
            <!-- some content -->
            <h4 class="mb-1">اشتراكات العميل</h4>
            <clients-subscriptions-table
              :subscriptions="client.Subscriptions"
              @subscription-clicked="
                (row) => $router.push({ path: `/subscriptions/${row.Id}` })
              "
            />
          </template>
        </vue-collapsible-panel>
        <vue-collapsible-panel :expanded="false">
          <template #title>الفوترة</template>
          <template #content>
            <!-- some content -->
            <h4 class="mb-1">الفواتير</h4>
            <invoices-table
              :invoices="invoices"
              :detailsModalCB="invoiceDetailsModalCB"
              :printModalCB="changePrintModalCB"
              @ShowInvoice="
                (invoice) => {
                  invoice = invoice;
                  invoiceDetailsModalCB(null, true);
                }
              "
            />
          </template>
        </vue-collapsible-panel>
        <vue-collapsible-panel :expanded="false">
          <template #title>طرق الدفع</template>
          <template #content>
            <!-- some content -->
            <h4 class="mb-1">طرق الدفع</h4>
            <span>نأسف لعدم وجود محتوى هنا!</span>
          </template>
        </vue-collapsible-panel>
        <vue-collapsible-panel
          :expanded="false"
          v-if="
            [
              'Manager',
              'Accountant',
              'Viewer',
              'CS_Account_Manager',
              'CS_Onboarding_Specialist',
              'Administrator',
            ].includes(role)
          "
        >
          <template #title>العمليات</template>
          <template #content>
            <h4 class="mb-1">العمليات</h4>
            <!-- some content -->
            <audit-table :audits="audits" :dataLoading="loadingAudit" />
          </template>
        </vue-collapsible-panel>
      </vue-collapsible-panel-group>
    </div>
  </div>
  <manage-modal @modalStatus="(cb) => (editEmailModalCB = cb())">
    <template #header> تحديث البريد الإلكتروني </template>
    <template #body>
      <div v-if="Object.keys(client).length > 0">
        <input type="text" v-model="client.Users.Email" />
      </div>
    </template>
  </manage-modal>
  <manage-modal
    @modalStatus="(cb) => (addBalanceModalCB = cb())"
    btnSuccess="اضف الرصيد"
    class="add-balance-modal"
  >
    <template #header> أضف رصيد </template>
    <template #body>
      <div class="form-group">
        <label for="balance">القيمة</label>
        <div class="grid grid-3/1">
          <input type="text" id="balance" v-model="balance" />
          <select name="currency" id="currency">
            <option value="0">ريال</option>
            <option value="1">جنيه</option>
            <option value="2">دولار</option>
          </select>
        </div>
      </div>

      <div class="form-group">
        <label for="payment_method">طريقة الدفع</label>
        <select v-model="paymentMethodType" id="payment_method">
          <option value="0">نقدا</option>
          <option value="1">شيك</option>
          <option value="2">حساب بنكي</option>
        </select>
      </div>
      <div
        class="form-group"
        v-if="paymentMethodType == 1 || paymentMethodType == 2"
      >
        <label for="transactionId">رقم العملية</label>
        <input type="text" v-model="transactionId" id="transactionId" />
      </div>
    </template>
  </manage-modal>
  <!-- /invoice details modal -->
  <invoice-details-modal
    @modalStatus="(cb) => (invoiceDetailsModalCB = cb())"
    @close="invoiceDetailsModalCB(null, false)"
    :invoice="invoice"
  />
  <div class="loader loader-page-layout" v-if="loading"></div>
</template>

<script>
import ClientService from "@/services/ClientService";
import ManageModal from "@/components/common/ManageModal.vue";
import ClientsSubscriptionsTable from "@/components/subscriptions/ClientsSubscriptionsTable.vue";
import InvoiceDetailsModal from "@/components/invoice/InvoiceDetailsModal.vue";
import InvoicesTable from "@/components/common/InvoicesTable.vue";
import { SkeletonText } from "skeleton-elements/vue";
import SubscriptionService from "@/services/SubscriptionService";
import { formatDate, getDateTime } from "@/util/date";
import { generateId } from "@/util/utilities";
import AuditTable from "@/components/clients/AuditTable.vue";
export default {
  components: {
    ManageModal,
    ClientsSubscriptionsTable,
    InvoicesTable,
    InvoiceDetailsModal,
    SkeletonText,
    AuditTable,
  },
  props: {
    role: String,
  },
  data() {
    return {
      client: {},
      loadingData: false,
      addBalanceModalCB: null,
      editEmailModalCB: null,
      invoiceDetailsModalCB: null,
      changePrintModalCB: null,
      balance: null,
      paymentMethodType: null,
      invoices: null,
      invoice: {},
      transactionId: null,
      audits: null,
      loadingAudit: false,
      loading: false,
    };
  },
  mounted() {
    this.getClient();
    this.getBillingRecords();
    this.getAuditTrail();
  },
  methods: {
    async getAuditTrail() {
      this.loadingAudit = true;
      const audits = await ClientService.getAuditTrail(this.$route.params.id);
      this.audits = audits.map((a) => ({
        ...a,
        Description: a.Description.ArabicValue,
        TimeStamp: getDateTime(a.TimeStamp),
      }));
      if (audits) {
        this.loadingAudit = false;
      }
    },
    async getClient() {
      this.loadingData = true;
      const client = await ClientService.getClientDetails(
        this.$route.params.id
      );
      client.Users = client.Users[0];
      this.client = client;
      if (this.client.Balance < 0) {
        this.balance = Math.abs(this.client.Balance);
      }
    },
    async addBalance(amount) {
      if (this.paymentMethodType == null || !amount) return;
      if (
        (this.paymentMethodType == 1 || this.paymentMethodType == 2) &&
        !this.transactionId
      )
        return;
      this.addBalanceModalCB(null, false);
      const TransactionId = this.transactionId
        ? this.transactionId
        : generateId();
      // add balance to client
      this.loading = true;
      try {
        const response = await ClientService.depositBalance(
          this.$route.params.id,
          amount,
          this.paymentMethodType,
          TransactionId
        );
        if (response.status == 200) {
          this.client = {};
          this.loading = false;
          this.getClient();
          this.$toast.success("تم إضافة الرصيد بنجاح");
        } else {
          this.loading = false;
          this.$toast.error("نأسف هناك خطأ ما");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("نأسف هناك خطأ ما");
      }
    },
    updateEmail(email) {
      this.editEmailModalCB(null, false);
      // update email in client
      // const {status} = await ClientService.updateEmail(this.client.id, email);
    },
    async getBillingRecords() {
      const billingRecords = await ClientService.getBillingRecords(
        this.$route.params.id
      );
      this.invoices = billingRecords.map((invoice) => {
        invoice.Description = invoice.Description.ArabicValue;
        invoice.Date = formatDate(invoice.Date);
        return invoice;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-email {
  &__btn {
    padding: 0.5rem;
    margin: 0 1rem;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.add-balance-modal {
}
</style>