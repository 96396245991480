const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
function formatDate(date) {
  if (date && date.includes("/")) return date;
  const d = new Date(date);
  return [
    padTo2Digits(d.getDate()),
    padTo2Digits(d.getMonth() + 1),
    d.getFullYear(),
  ].join("/");
}

function getNowDate() {
  const d = new Date();
  return [
    padTo2Digits(d.getMonth() + 1),
    padTo2Digits(d.getDate()),
    d.getFullYear(),
  ].join("-");
}
function subtractDates(date1, date2) {
  const diffTime = Math.abs(new Date(date2) - new Date(date1));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}
const getDateTime = (timestamp) => {
  let u = new Date(timestamp);
  console.log("this is datetime:>", u);
  return (
    u.getUTCFullYear() +
    "-" +
    ("0" + u.getUTCMonth()).slice(-2) +
    "-" +
    ("0" + u.getUTCDate()).slice(-2) +
    " " +
    " " +
    ("0" + u.getUTCHours()).slice(-2) +
    ":" +
    ("0" + u.getUTCMinutes()).slice(-2) +
    ":" +
    ("0" + u.getUTCSeconds()).slice(-2)
  );
};

export { formatDate, getNowDate, subtractDates, getDateTime };
