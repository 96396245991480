<template>
  <div class="configure-subscription">
    <!-- <select>
      <option
        v-for="(service, i) in services"
        :value="i"
        :key="i"
        :selected="service.Id == selectedService"
      >
        {{ service.Name }}
      </option>
    </select> -->
    <div class="grid grid-1x2">
      <package-spec-card
        :countNum="3"
        name="المستخدمين"
        :additionalPrice="12"
        additionalName="مستخدم إضافي"
      >
        <div class="mt-2">
          <a @click="addUsersModalCB(addUsers('id'), true)" class="link ml-2">
            <i class="fas fa-plus-square ml-1"></i>
            <span>إضافة مستخدم مجاني</span>
          </a>
          <a @click="addDiscountModalCB(addDiscount('id'), true)" class="link">
            <i class="fas fa-badge-percent ml-1"></i>
            <span>إضافة خصم</span>
          </a>
        </div>
      </package-spec-card>
      <package-spec-card
        :countNum="3"
        name="الموظفين"
        :additionalPrice="12"
        additionalName="مستخدم إضافي"
      >
        <div class="mt-2">
          <a @click="addUsersModalCB(addUsers('id'), true)" class="link ml-2">
            <i class="fas fa-plus-square ml-1"></i>
            <span>إضافة مستخدم مجاني</span>
          </a>
          <a @click="addDiscountModalCB(addDiscount('id'), true)" class="link">
            <i class="fas fa-badge-percent ml-1"></i>
            <span>إضافة خصم</span>
          </a>
        </div>
      </package-spec-card>
      <package-spec-card
        :countNum="3"
        name="موظفي الخدمة الذاتية"
        :additionalPrice="12"
        additionalName="مستخدم إضافي"
      >
        <div class="mt-2">
          <a @click="addUsersModalCB(addUsers('id'), true)" class="link ml-2">
            <i class="fas fa-plus-square ml-1"></i>
            <span>إضافة مستخدم مجاني</span>
          </a>
          <a @click="addDiscountModalCB(addDiscount('id'), true)" class="link">
            <i class="fas fa-badge-percent ml-1"></i>
            <span>إضافة خصم</span>
          </a>
        </div>
      </package-spec-card>
    </div>
    <vue-collapsible-panel-group>
      <vue-collapsible-panel :expanded="false">
        <template #title> الإضافات الإختيارية المدفوعة </template>
        <template #content>
          <section class="package-specs__section grid grid-1x2">
            <package-spec-card
              :countNum="3"
              name="نقاط بيع دفاتر الاوف لاين"
              :additionalPrice="1200"
              additionalName="نقطة بيع"
              moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
            />
            <package-spec-card
              :countNum="3"
              name="شركات إضافية"
              :additionalPrice="1200"
              additionalName="نقطة بيع"
              moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
            />
            <package-spec-card
              :countNum="3"
              name="مساحة تخزين إضافية"
              :additionalPrice="1200"
              additionalName="نقطة بيع"
              moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
            />
            <package-spec-card
              :countNum="3"
              name="تجارة إلكترونية"
              :additionalPrice="1200"
              additionalName="نقطة بيع"
              moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
            />
          </section>
        </template>
      </vue-collapsible-panel>
      <vue-collapsible-panel :expanded="false">
        <template #title> الإضافات الإختيارية المدفوعة </template>
        <template #content>
          <section class="package-specs__section grid grid-1x2">
            <package-spec-card
              :countNum="3"
              name="الدعم الهاتفي"
              :additionalPrice="1200"
              additionalName="نقطة بيع"
              moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
            />
          </section>
        </template>
      </vue-collapsible-panel>
      <vue-collapsible-panel :expanded="false">
        <template #title> الخدمات الإحترافية </template>
        <template #content>
          <section class="package-specs__section grid grid-1x2">
            <package-spec-card
              :countNum="3"
              name="الدعم الهاتفي"
              :additionalPrice="1200"
              additionalName="نقطة بيع"
              moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
            />
            <package-spec-card
              :countNum="3"
              name="الدعم الهاتفي"
              :additionalPrice="1200"
              additionalName="نقطة بيع"
              moreInfo="الحصول على الدعم الفني عن طريق التواصل مع احد مسئولي خدمة العملاء على الأرقام المسجلة"
            />
          </section>
        </template>
      </vue-collapsible-panel>
    </vue-collapsible-panel-group>
  </div>
  <manage-modal
    @modalStatus="(cb) => (addUsersModalCB = cb())"
    class="add-users-modal"
  >
    <template #header> إضافة مستخدم مجاني </template>
    <template #body>
      <div style="text-align: center; width: 100%">
        <div
          class="form-group"
          style="min-width: 32rem; display: inline-block; margin: 0"
        >
          <select name="number">
            <option value="">العدد</option>
            <option value="5">5</option>
            <option value="10">10</option>
          </select>
        </div>
      </div>
    </template>
  </manage-modal>
  <manage-modal
    @modalStatus="(cb) => (addDiscountModalCB = cb())"
    class="add-users-modal"
  >
    <template #header> إضافة خصم </template>
    <template #body>
      <div style="text-align: center; width: 100%">
        <div
          class="form-group"
          style="min-width: 32rem; display: inline-block; margin: 0"
        >
          <div class="grid grid-3/1">
            <input type="text" v-model="discount" />
            <select v-model="discountDuration">
              <option value="">مدة الخصم</option>
              <option value="1">30</option>
              <option value="2">60</option>
              <option value="3">90</option>
              <option value="4">180</option>
              <option value="5">365</option>
            </select>
          </div>
        </div>
      </div>
    </template>
  </manage-modal>
</template>

<script>
import PackageSpecCard from "@/components/subscriptions/PackageSpecCard.vue";
import ManageModal from "@/components/common/ManageModal.vue";
export default {
  components: {
    PackageSpecCard,
    ManageModal,
  },
  props: {
    selectedService: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      addUsersModalCB: null,
      addDiscountModalCB: null,
      services: [
        {
          Id: 1,
          Name: "إشتراك شهري",
        },
        {
          Id: 2,
          Name: "إشتراك شهري",
        },
      ],
    };
  },
  methods: {
    addUsers(id) {
      this.addUsersModalCB(null, false);
    },
    addDiscount(id) {
      this.addDiscountModalCB(null, false);
    },
  },
};
</script>

<style lang="scss" scoped>
select {
  font-size: 2.9rem;
  font-weight: bold;
  padding: 1rem 3rem;
  margin-bottom: 3.5rem;
  line-height: 1.7;
  border-radius: 10px;
}
</style>