<script>
export default {
  name: "MModal",
  props: {
    hasFooter: {
      type: Boolean,
      default: true,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    btnSuccess: {
      type: String,
      default: "موافق",
    },
    btnCancel: {
      type: String,
      default: "إلغاء",
    },
    hasHeaderEsc: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    //should put close in click out of it
    //by adding window listener
  },
  mounted() {
    document.addEventListener("keydown", this.escape);
  },
  unmounted() {
    document.removeEventListener("keydown", this.escape);
  },
  methods: {
    escape(e) {
      e.key == "Escape" ? this.close() : null;
    },
  },
};
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <header class="modal__header" v-if="hasHeader">
          <slot name="header"> عنوان المودال الافتراضي! </slot>
          <a
            class="modal__close-btn"
            v-if="hasHeaderEsc"
            @click="$emit('close')"
          >
            <i class="fas fa-times"></i>
          </a>
        </header>

        <section class="modal__body">
          <slot name="body"> محتوي المودال الافتراضي! </slot>
        </section>

        <footer class="modal__footer" v-if="hasFooter && !hasHeaderEsc">
          <slot name="footer">
            <button @click="$emit('agree')" class="btn btn-success">
              {{ btnSuccess }}
            </button>
            <button @click="$emit('close')" class="btn btn-outline">
              {{ btnCancel }}
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
