import { createStore } from "vuex";

export default createStore({
  state: {
    serviceId: sessionStorage.getItem("activeServiceId")
      ? sessionStorage.getItem("activeServiceId")
      : 1,
  },
  mutations: {
    switchService(state, serviceId) {
      state.serviceId = serviceId;
    },
  },
  actions: {},
  getters: {},
  modules: {},
});
