<template>
  <div class="container">
    <div class="grid grid-1">
      <MmrChart />
    </div>
  </div>
</template>

<script>
import MmrChart from "@/components/charts/MmrChart.vue";
export default {
  components: { MmrChart },
};
</script>
