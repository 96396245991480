<template>
  <!-- <button
    v-bind="$attrs"
    @mouseenter="(e) => e.target.classList.add('active')"
    @mouseleave="(e) => e.target.classList.remove('active')"
  >
    <slot>label</slot>
    <i class="fas fa-sort-down"></i>
    <ul class="dropdown-list">
      <li v-for="item in items" :key="item.value" :class="`btn-${listType}`">
        <a href="#" @click.prevent="$emit('selected', item.value)">{{
          item.label
        }}</a>
      </li>
    </ul>
  </button> -->
  <select
    v-bind="$attrs"
    @input="$emit('update:modelValue', $event.target.value)"
    :value="$attrs.modelValue"
  >
    <option value="" selected :class="`option-${listType}`">
      <slot>label</slot>
    </option>
    <option
      v-for="item in items"
      :key="item.value"
      :class="`option-${listType}`"
      :value="item.value"
    >
      {{ item.label }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    items: Array,
    listType: String,
  },
  mounted() {},
  methods: {
    activateDropdown(e) {
      e.target.classList.add("active");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/abstracts/variables";
button {
  position: relative;
  &.active {
    .dropdown-list {
      display: block;
    }
  }
}
.dropdown-list {
  list-style: none;
  display: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  z-index: 9999;
  li {
    padding: 0.5rem 1rem;
  }
}
.dropdown-select {
  -webkit-appearance: none;
  appearance: none;
  // background: none;
  text-align: right;
  display: inline-block;
  min-width: fit-content;
  option {
    margin: 40px;
    // background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    &.option-success {
      background-color: $color-success;
    }
    &.option-warning {
      background-color: $color-warning;
    }
    &.option-danger {
      background-color: $color-danger;
    }
  }
}
</style>