<template>
  <div class="create-partner container mb-6">
    <div class="flex-between mb-4">
      <h2>إضافة شريك</h2>
      <div>
        <button class="btn btn-success" @click="submitPartnerForm">
          حفظ الشريك
        </button>
      </div>
    </div>
    <create-partner-form
      :hasSubmitBtn="false"
      :loading="loading"
      :partnerData="partnerData"
      @handleSubmit="(formSubmitCB) => (submitPartnerForm = formSubmitCB)"
    />
    <div class="plans mt-5">
      <h3 class="sub-heading">جدول الباقات ونسب الخصم</h3>
      <table>
        <thead>
          <tr>
            <th style="width: 15%; padding-right: 1rem">نوع الباقة</th>
            <th>سعر الباقة/سنوياً</th>
            <th>مبلغ الخصم</th>
            <th>نسبة الخصم %</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="{ Id, Name } in servicePlans" :key="Id">
            <td style="width: 15%; padding-right: 1rem">{{ name }}</td>
            <td>{{ Name }}</td>
            <td>
              <input type="text" class="" placeholder="Enter Value" />
            </td>
            <td>
              <input type="text" class="" placeholder="Enter %" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="plans mt-5">
      <h3 class="sub-heading">استخراج الكود الخاص بالشريك</h3>
      <div class="coupon">
        <button class="btn btn-icon btn-outline btn-outline--success">
          <i class="fas fa-fingerprint"></i>
          تحميل الكود
        </button>
        <div class="coupon-field">
          <input type="text" v-model="couponCode" ref="couponCode" />
          <button class="btn btn-icon" @click="copyCoupon">
            <i class="fad fa-copy"></i>
            نسخ الكود
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreatePartnerForm from "@/components/partners/CreatePartnerForm.vue";
export default {
  components: { CreatePartnerForm },
  data() {
    return {
      submitPartnerForm: null,
      couponCode: "SCOxe#21",
      plans: [
        {
          Id: 1,
          name: "Go",
          price: 600,
        },
        {
          Id: 2,
          name: "Plus",
          price: 900,
        },
        {
          Id: 3,
          name: "Elite",
          price: 1200,
        },
      ],
      partnerData: {
        Name: "mohamed moniem",
        CompanyName: "moniem",
        Email: "m.abdelmoniem@dafater.sa",
        Phone: "01111798451",
        from: new Date().toISOString().split("T")[0],
        to: new Date().toISOString().split("T")[0],
      },
      allPlans: [],
      servicePlans: [],
    };
  },

  methods: {
    getPartnerDetails() {},
    copyCoupon() {
      const element = this.$refs.couponCode;
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>