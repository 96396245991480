<template>
  <div class="container flex-col text-center">
    <label for="url" class="c-primary mb-0 mt-3 fw-n fs-18"
      >تغيير رابط الاشتراك</label
    >
    <div class="form-group flex-center">
      <span class="domain">dafater.biz.</span>
      <input
        type="text"
        id="url"
        style="
          width: 30%;
          min-width: 35rem;
          margin: 3rem auto;
          padding-right: 12rem;
        "
        v-model="url"
      />
      <button class="btn btn-success" type="button" @click="handleSearch">
        ابحث
      </button>
    </div>
    <div class="flex-center flex-col">
      <div class="loader inline-loading my-1" v-if="loading"></div>
      <div v-if="!loading && exist" class="c-danger my-1">
        عفواً هذا الاسم مستخدم!
      </div>
      <div v-if="!loading && searched && !exist" class="c-success my-1">
        هذا الاسم متاح!
      </div>

      <!-- to be changed searched to valid url -->
    </div>
  </div>
  <button
    class="btn btn-info mx-1"
    :disabled="loading || !searched || exist"
    @click="applyUrl"
  >
    تفعيل الرابط
  </button>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";

export default {
  data() {
    return {
      url: null,
      exist: false,
      searched: false,
      loading: false,
    };
  },
  mounted() {},
  methods: {
    async handleSearch() {
      if (this.loading || !this.url) return;

      this.loading = true;
      this.searched = true;
      try {
        const response = await SubscriptionService.searchForUrl(this.url);
        if (response.data) {
          this.loading = false;
          this.exist = false;
        } else {
          this.loading = false;
          this.exist = true;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async applyUrl() {
      if (((this.loading || !this.searched) && this.exist) || !this.url) return;
      this.loading = true;
      this.searched = false;
      try {
        const response = await SubscriptionService.changeSubscriptionUrl(
          this.$route.params.id,
          this.url
        );
        if (response && response.status == 200) {
          this.loading = false;
          this.$emit("changed");
        } else {
          this.loading = false;
          this.exist = true;
        }
      } catch (error) {
        this.loading = false;
        this.exist = true;
      }
    },
  },
};
</script>

<style>
.domain {
  padding: 1rem;
  background: #efefef;
  margin-left: -12rem;
  z-index: 999;
}
</style>