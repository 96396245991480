<template>
  <div class="create-subscription container mb-3">
    <div class="grid grid3-1">
      <main class="box">
        <header class="box-header flex-between flex-center-y">
          <h3 class="box-title">
            اشتراك جديد الباقة --
            <template v-if="transaction">
              {{ transaction.ServicePlan.Name.ArabicValue }} -- للعميل:
              {{ transaction.Subscriber.Name }}
            </template>
          </h3>
          <a href="#" @click="$router.back()" class="btn-icon">
            <i class="fas fa-arrow-right"></i>
            رجوع
          </a>
        </header>
        <CustomizeClientSubscriptionForm
          :transactionId="$route.params.transactionId"
          @getData="(d) => setData(d)"
        />
      </main>
      <aside v-if="transaction">
        <CustomizeSubscriptionSummaryCard
          :transaction="transaction"
          :features="features"
          :loadingUpdate="loadingUpdate"
          :disabled="disabled"
          :subscriptionType="subscriptionType"
          :enableDicountInput="enableDicountInput"
          @applyCoupon="applyDiscountCoupon"
          @subscribe="subscribe"
        />
      </aside>
    </div>
  </div>
  <div class="loader loader-page-layout" v-if="loadingSpinner"></div>
</template>

<script>
import CustomizeClientSubscriptionForm from "@/components/subscriptions/CustomizeClientSubscriptionForm.vue";
import CustomizeSubscriptionSummaryCard from "@/components/subscriptions/CustomizeSubscriptionSummaryCard.vue";
import SubscriptionService from "@/services/SubscriptionService";

export default {
  components: {
    CustomizeClientSubscriptionForm,
    CustomizeSubscriptionSummaryCard,
  },
  props: {
    role: String,
  },
  data() {
    return {
      transaction: null,
      features: null,
      loadingUpdate: false,
      disabled: null,
      subscriptionType: null,
      enableDicountInput: null,
      loadingSpinner: true,
    };
  },
  watch: {
    transaction(to, from) {
      if (from == null) this.loadingSpinner = false;
    },
  },
  mounted() {
    if (
      ![
        "Manager",
        "Marketer",
        "CS_Onboarding_Specialist",
        "Administrator",
      ].includes(this.role)
    ) {
      this.$router.replace("/not-authed");
    }
  },
  methods: {
    setData(data) {
      this.transaction = data.transaction;
      this.features = data.features;
      this.loadingUpdate = data.loadingUpdate;
      this.disabled = data.disabled;
      this.subscriptionType = data.subscriptionType;
      this.enableDicountInput = this.subscriptionType == "creation";
    },
    async subscribe() {
      if (!this.transaction.GrandTotal && this.subscriptionType !== "creation")
        return;
      this.loadingSpinner = true;
      const params = [{ transactionId: this.transaction.Id }];
      const httpMethod =
        this.subscriptionType == "creation"
          ? SubscriptionService.createSubscription
          : SubscriptionService.updateSubscription;
      try {
        const response = await httpMethod(params);
        if (response && (response.status == 201 || response.status == 200)) {
          this.$router.push(`/subscriptions/${response.data.Id}`);
          this.loadingSpinner = false;
        } else {
          this.loadingSpinner = false;
        }
      } catch (error) {
        this.loadingSpinner = false;
        this.$router.push(`/clients/${this.$route.params.id}`);
      }
    },
    async applyDiscountCoupon(discountCoupon) {
      this.loadingSpinner = true;
      try {
        const { transaction } = await SubscriptionService.applyDiscountCoupon(
          this.transaction.Id,
          discountCoupon
        );
        this.transaction = transaction;
      } catch (error) {
        if (error.status == 404)
          this.$toast.error("نأسف هناك خطأ ما، الرمز الذي ادخلته غير موجود!");
      }
      this.loadingSpinner = false;
    },
  },
};
</script>

<style>
</style>