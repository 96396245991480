<template>
  <div class="subscriptions-table">
    <template v-if="!isLoading">
      <table-lite
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :total="totalRecordCount"
        :is-static-mode="true"
        :sortable="sortable"
        :pageOptions="[
          { value: 25, text: 25 },
          { value: 50, text: 50 },
          { value: 75, text: 75 },
        ]"
        @do-search="$emit('doSearch')"
        @is-finished="tableLoadingFinish"
        :messages="messages"
        :class="totalRecordCount > 10 ? 'has-pagination' : ''"
      >
      </table-lite>
    </template>
    <partners-table-loading v-else />
  </div>
  <manage-modal @modalStatus="(cb) => (removePromoConfirmModalCB = cb())">
    <template #header> حذف الكود </template>
    <template #body>
      <div>هل انت متأكد من حذف الكود الترويجي</div>
    </template>
  </manage-modal>
  <div class="loader loader-page-layout" v-if="removeLoading"></div>
</template>

<script>
import TableLite from "vue3-table-lite";
import PartnersTableLoading from "./PartnersTableLoading.vue";
import ManageModal from "@/components/common/ManageModal.vue";
import PartnerService from "@/services/PartnerService";

export default {
  components: {
    TableLite,
    PartnersTableLoading,
    ManageModal,
  },
  props: {
    partners: Array,
    dataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      removePromoConfirmModalCB: null,
      dataId: "",
      isLoading: this.dataLoading,
      removeLoading: false,
      columns: [
        {
          label: "المعرف",
          field: "Id",
          sortable: true,
          isKey: true,
        },
        {
          label: "اسم الشريك",
          field: "PartnerName",
          sortable: true,
        },
        {
          label: "اسم الشركة",
          field: "CompanyName",
          sortable: true,
        },
        {
          label: "البريد الإلكتروني",
          field: "Email",
          sortable: true,
        },
        {
          label: "رقم الجوال",
          field: "Phone",
          sortable: true,
        },
        {
          label: "الكود الترويجي",
          field: "Code",
          sortable: false,
        },
        {
          label: "تعديل/حذف",
          field: "actions",
          display: function (row) {
            return (
              '<div class="flex-center"><button data-id="' +
              row.Id +
              '" class="is-rows-el delete-btn icon-btn ml-1"><i class="fas fa-trash c-danger"></i></button><button data-id="' +
              row.Id +
              '" class="is-rows-el edit-btn icon-btn ml-1"><i class="fas fa-pen "></i></button></div>'
            );
          },
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "Number",
        sort: "desc",
      },
      messages: {
        pagingInfo: "عرض {0}-{1} من {2}",
        pageSizeChangeLabel: "عدد الصفوف:",
        gotoPageLabel: "الذهاب للصفحة:",
        noDataAvailable: "لا يوجد اي بيانات",
      },
    };
  },
  watch: {
    dataLoading(newVal) {
      this.isLoading = newVal;
    },
    partners(newVal) {
      this.setRows(newVal);
    },
  },
  mounted() {
    this.setRows(this.partners);
  },
  methods: {
    setRows(partners) {
      this.rows = partners;
      this.totalRecordCount = partners.length;
      this.isLoading = false;
    },
    async removePromo(id) {
      this.isLoading = true;
      this.removeLoading = true;
      let hasissue = false;
      const servicePlans = this.partners.find(
        (p) => p.Id == id
      ).PromotionCodeServicePlans;
      servicePlans.forEach(async (p, i) => {
        const unassignResponse = await PartnerService.unAssignServicePlan(
          id,
          p.ServicePlan_Id
        );
        if (unassignResponse && unassignResponse.status == 200) {
          if (!hasissue && i == servicePlans.length - 1) {
            this.delete(id);
          }
        } else {
          hasissue = true;
        }
      });
    },
    async delete(id) {
      const removeResponse = await PartnerService.removePartner(id);
      if (removeResponse && removeResponse.status == 200) {
        this.removePromoConfirmModalCB(null, false);
        const filteredPartners = this.partners.filter((p) => p.Id !== id);

        this.setRows(filteredPartners);
        this.removeLoading = false;
        this.$router.go();
      }
    },
    tableLoadingFinish(elements) {
      this.isLoading = false;
      this.loadingData = false;
      elements.forEach((element) => {
        if (element.classList.contains("delete-btn")) {
          element.addEventListener("click", (e) => {
            this.removePromoConfirmModalCB(
              () => this.removePromo(element.dataset.id),
              true
            );
          });
        }
        if (element.classList.contains("edit-btn")) {
          element.addEventListener("click", (e) => {
            this.$router.push(`/edit-partner/${element.dataset.id}`);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>