<template>
  <m-modal
    v-show="isModalVisible"
    @agree="acceptCB()"
    @close="isModalVisible = false"
    :btnSuccess="btnSuccess"
    :btnCancel="btnCancel"
  >
    <template #header>
      <slot name="header"> عنوان المودال الافتراضي! </slot>
    </template>
    <template #body>
      <slot name="body"> محتوي المودال الافتراضي! </slot>
    </template>
  </m-modal>
</template>

<script>
import MModal from "@/components/common/MModal.vue";
export default {
  components: { MModal },
  props: {
    btnSuccess: {
      type: String,
      default: "موافق",
    },
    btnCancel: {
      type: String,
      default: "إلغاء",
    },
  },
  mounted() {
    this.$emit("modalStatus", this.controlModalStatus);
  },
  data() {
    return {
      isModalVisible: false,
      acceptCB: null,
    };
  },
  methods: {
    controlModalStatus() {
      return (acceptCB, isShow) => {
        this.acceptCB = acceptCB;
        this.isModalVisible = isShow;
      };
    },
  },
};
</script>

<style lang="scss">
</style>