<template>
  <div class="clients">
    <div class="container">
      <div class="my-1">
        <div class="filters my-2 flex-between clients__header">
          <div class="filters-buttons clients__header-connected-btns">
            <button
              class="btn btn-info"
              ref="all"
              :disabled="dataLoading"
              @click="getClients()"
            >
              كل العملاء
            </button>
            <button
              class="btn btn-success"
              ref="TrialSubscribers"
              :disabled="dataLoading"
              @click="getClients('TrialSubscribers')"
            >
              العملاء التجريبين
            </button>
            <button
              class="btn btn-warning"
              ref="Customers"
              :disabled="dataLoading"
              @click="getClients('Customers')"
            >
              الحسابات
            </button>
          </div>
          <div>
            <router-link
              to="/create-client"
              v-if="
                [
                  'Manager',
                  'CS_Onboarding_Specialist',
                  'Administrator',
                ].includes(role)
              "
            >
              <button class="btn btn-icon btn-danger">
                <i class="fas fa-plus-circle"></i>
                عميل جديد
              </button>
            </router-link>
            <vue-excel-xlsx
              v-if="
                ['Manager', 'Accountant', 'Marketer', 'Administrator'].includes(
                  role
                )
              "
              class="btn btn-success"
              :data="clients"
              :columns="columns"
              :file-name="'العملاء'"
              :file-type="'xlsx'"
              :sheet-name="'العملاء'"
            >
              <i class="far fa-file-times"></i>
            </vue-excel-xlsx>
          </div>
        </div>
        <div class="filter-field">
          <h4>بحث:</h4>
          <input type="search" v-model="searchingParam" @keyup="handleSearch" />
        </div>
      </div>
      <clients-table
        :clients="clients"
        :dataLoading="dataLoading"
        @client-clicked="(row) => $router.push({ path: `/clients/${row.Id}` })"
      />
    </div>
  </div>
</template>

<script>
import ClientsTable from "@/components/clients/ClientsTable.vue";
import ClientService from "@/services/ClientService";

export default {
  components: {
    ClientsTable,
  },
  props: {
    role: String,
  },
  data() {
    return {
      clients: [],
      allClients: [],
      dataLoading: false,
      searchingParam: null,
      columns: [
        {
          label: "اسم الشركة",
          field: "CompanyName",
        },
        {
          label: "الإسم",
          field: "Name",
          sortable: true,
        },
        {
          label: "البريد الالكتروني",
          field: "Email",
        },
        {
          label: "الجوال",
          field: "Phone",
        },
        {
          label: "الرصيد",
          field: "Balance",
          // dataFormat: this.priceFormat,
        },
      ],
    };
  },
  mounted() {
    this.getClients();
  },
  methods: {
    async getClients(filter = null) {
      this.dataLoading = true;
      //
      const ref = filter ? filter : "all";
      Object.keys(this.$refs).forEach(
        (btn) => (this.$refs[btn].style.boxShadow = "")
      );
      this.$refs[ref].style.boxShadow = "inset 0px 0px 7px 0px #00000090";
      this.dataTitle = this.$refs[ref].textContent;
      //
      const { data: clients } = await ClientService.getAllClients(filter);
      this.allClients = clients.map((client) => {
        if (!client["Users"][0]) return client;
        return {
          ...client,
          Name: client["Users"][0].Name,
          Email: client["Users"][0].Email,
          Phone: client["Users"][0].MobileNumber,
          CompanyName: client["Users"][0].Tenant.Name,
        };
      });
      this.clients = this.allClients;
      this.dataLoading = false;
    },
    handleSearch(e) {
      const searchParam = e.target.value.toLowerCase();
      if (searchParam) {
        this.clients = this.allClients.filter((client) => {
          const hasNumber = client.Subscriptions.some((s) =>
            s.Number.toLowerCase().includes(searchParam)
          );
          return (
            client.Name?.toLowerCase().includes(searchParam) ||
            client.Email?.toLowerCase().includes(searchParam) ||
            client.Phone?.toLowerCase().includes(searchParam) ||
            client.CompanyName?.toLowerCase().includes(searchParam) ||
            client.Subscriptions.some((s) =>
              s.Number.toLowerCase().includes(searchParam)
            )
          );
        });
      } else {
        this.clients = this.allClients;
      }
    },
    priceFormat(value) {
      const currency = "ر.س";
      return `${currency} ` + value;
    },
  },
};
</script>

<style lang="scss">
.filters-buttons .btn {
  font-size: 1.5rem;
}
</style>