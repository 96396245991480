<template>
  <div class="chart-box">
    <div class="chart-box__header">
      <div class="chart-box__title">MMR</div>
      <div class="chart-box__filter">
        <select name="mmr" id="mmr">
          <option value="current">السنة الحالية</option>
        </select>
      </div>
    </div>
    <div class="chart-box__body">
      <LineChart v-bind="doughnutChartProps" />
    </div>
    <div class="chart-box__footer"></div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { LineChart, useDoughnutChart } from "vue-chart-3";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";

Chart.register(...registerables);
export default {
  components: { LineChart },
  setup() {
    const dataValues = ref([
      30, 70, 150, 250, 100, 100, 40, 40, 90, 200, 125, 93,
    ]);
    const dataLabels = ref([
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]);
    const toggleLegend = ref(true);

    const data = computed(() => ({
      labels: dataLabels.value,
      datasets: [
        {
          data: dataValues.value,
          backgroundColor: [
            "#77CEFF",
            "#0079AF",
            "#123E6B",
            "#97B0C4",
            "#A5C8ED",
          ],
        },
      ],
    }));

    const options = computed(() => ({
      scales: {
        myScale: {
          type: "logarithmic",
          position: toggleLegend.value ? "left" : "right",
        },
      },
      plugins: {
        legend: {
          position: toggleLegend.value ? "top" : "bottom",
        },
        title: {
          display: true,
          text: "Chart.js Doughnut Chart",
        },
      },
    }));

    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData: data,
      options,
    });
    return {
      data,
      options,
      doughnutChartRef,
      doughnutChartProps,
    };
  },
};
</script>


<style lang="scss">
.chart-box {
  border: 1px solid #00000024;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #00000024;

  &__header {
    background-color: #f7f7f7;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 3rem;
  }
  &__title {
    color: #575756;
    font-weight: bold;
    font-size: 1.4rem;
  }
  &__filter {
    select {
      background-color: transparent;
      font-size: 1.2rem;
      padding: 1rem 4rem;
      padding-right: 2rem;
      border: 0;
    }
  }
  &__body {
    padding: 2rem;
  }
  &__footer {
  }
}
</style>