<template>
  <div class="create-subscription container mb-3">
    <div class="">
      <main class="box">
        <header class="box-header flex-between flex-center-y">
          <h3 class="box-title">المميزات المضافة</h3>
        </header>
        <section class="box-body grid grid-1x2">
          <template v-if="currentCompFeatures && currentCompFeatures.length">
            <div
              class="package-spec__card my-1"
              v-for="{ Feature: { Id, Name }, Count } in currentCompFeatures"
              :key="Id"
            >
              <div class="w100">
                <div class="flex-between">
                  <div class="package-spec__info">
                    <h4 class="package-spec__name">{{ Name.ArabicValue }}</h4>
                  </div>
                  <div class="package-spec__counter" style="direction: ltr">
                    {{ Count }} x
                  </div>
                </div>
                <!-- <p class="mt-2">
                  {{ Description.ArabicValue }}
                   x
                </p> -->
              </div>
            </div>
          </template>
          <div class="" v-else><h3>لا يوجد اي مميزات تمت إضافتها</h3></div>
        </section>
        <header class="box-header flex-between flex-center-y">
          <h3 class="box-title">المميزات المتاح إضافتها</h3>
        </header>
        <section class="box-body grid grid-1x2">
          <div
            class="package-spec__card my-1"
            v-for="{ Id, Name, Description } in availableCompFeatures"
            :key="Id"
          >
            <div class="w100">
              <div class="flex-between">
                <div class="package-spec__info">
                  <h4 class="package-spec__name">{{ Name.ArabicValue }}</h4>
                </div>
                <div class="package-spec__counter">
                  <input
                    type="number"
                    class="counter"
                    min="0"
                    :ref="`counter_${Id}`"
                    @change="(e) => changeFeatureValue(Id, e.target.value)"
                  />
                </div>
              </div>
              <p class="mt-2">{{ Description.ArabicValue }}</p>
            </div>
          </div>
        </section>
        <div class="text-left">
          <button
            class="btn btn-success mA-1"
            @click="applyFeatureConfirmModalCB(applyCompFeatures, true)"
          >
            إضافة
          </button>
        </div>
      </main>
    </div>
  </div>
  <manage-modal @modalStatus="(cb) => (applyFeatureConfirmModalCB = cb())">
    <template #header> إضافة مزايا مجانية </template>
    <template #body>
      <div>
        هل انت متأكد من إضافة التالي
        <div v-for="id in Object.keys(updatedValues)" :key="id">
          {{ availableCompFeatures.find((f) => f.Id == id).Name.ArabicValue }}
          <template v-if="currentCompFeatures.some((f) => f.Feature.Id == id)">
            <!-- {{
              currentCompFeatures.find((f) => f.Feature.Id == id).Feature.Name
                .ArabicValue
            }} -->

            {{ currentCompFeatures.find((f) => f.Feature.Id == id).Count }}
            >>
            <!-- {{ currentCompFeatures.find((f) => f.Feature.Id == id).Count }} -->
          </template>
          {{ updatedValues[id] }}
        </div>
      </div>
    </template>
  </manage-modal>
  <div class="loader loader-page-layout" v-if="loading"></div>
</template>

<script>
import SubscriptionService from "@/services/SubscriptionService";
import ManageModal from "@/components/common/ManageModal.vue";
export default {
  components: {
    ManageModal,
  },
  props: {
    role: String,
  },
  data() {
    return {
      loading: false,
      currentCompFeatures: null,
      availableCompFeatures: null,
      subscription: null,
      updatedValues: {},
      applyFeatureConfirmModalCB: null,
    };
  },
  mounted() {
    if (!["Manager", "Administrator"].includes(this.role)) {
      this.$router.replace("/not-authed");
    }
    this.loadPageData();
  },
  methods: {
    loadPageData() {
      this.getCurrentSubscription();
      this.getCurrentCompFeatures();
    },
    async getCurrentSubscription() {
      this.loading = true;
      try {
        const subscriptionDetails =
          await SubscriptionService.getSubscriptionDetails(
            this.$route.params.id
          );
        if (subscriptionDetails) {
          this.subscription = subscriptionDetails;
          this.getAvailableCompFeatures(subscriptionDetails.ServicePlan.Id);
        } else {
          this.loading = false;
          this.$toast.error("هناك خطأ ما!");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("هناك خطأ ما!");
      }
    },
    async getCurrentCompFeatures() {
      this.loading = true;
      try {
        const response = await SubscriptionService.getCurrentCompFeatures(
          this.$route.params.id
        );
        if (response && response.status == 200) {
          this.loading = false;
          this.currentCompFeatures = response.data.sort(
            (a, b) => a.DisplayOrder - b.DisplayOrder
          );
        } else {
          this.loading = false;
          this.$toast.error("هناك خطأ ما!");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("هناك خطأ ما!");
      }
    },
    async getAvailableCompFeatures(servicePlanId) {
      this.loading = true;
      try {
        const response = await SubscriptionService.getAvailableCompFeatures(
          servicePlanId
        );
        if (response && response.status == 200) {
          this.loading = false;
          this.availableCompFeatures = response.data;
        } else {
          this.loading = false;
          this.$toast.error("هناك خطأ ما!");
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("هناك خطأ ما!");
      }
    },
    changeFeatureValue(featureId, value) {
      this.updatedValues[featureId] = value;
    },
    applyCompFeatures() {
      this.loading = true;
      try {
        Object.keys(this.updatedValues).forEach(async (key, i) => {
          const response =
            await SubscriptionService.updateSubscriptionCompFeatures(
              this.subscription.Id,
              key,
              this.updatedValues[key]
            );
          if (response && response.status == 200) {
            this.loading = false;
            this.applyFeatureConfirmModalCB(null, false);
            Object.keys(this.updatedValues).forEach(
              (id) => (this.$refs[`counter_${id}`][0].value = "")
            );
            this.loadPageData();
            this.updatedValues = {};
          } else {
            this.loading = false;
            this.$toast.error("هناك خطأ ما!");
          }
        });
      } catch (error) {
        this.loading = false;
        this.$toast.error("هناك خطأ ما!");
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.package-spec {
  &__card {
    border-radius: 10px;
    background-color: #f6f6f6;
    display: flex;
    justify-content: space-between;
    padding: 4rem 3rem;
  }
  &__counter {
    width: 30%;
    max-width: 8rem;
  }
}
</style>
