<template>
  <div class="container">
    <h1>إنشاء عميل</h1>
    <create-client-form
      :hasSubmitBtn="true"
      :loading="loading"
      @handleSubmit="(formData) => submitForm(formData)"
    />
  </div>
</template>

<script>
import CreateClientForm from "@/components/clients/CreateClientForm.vue";
import ClientService from "@/services/ClientService";
export default {
  components: { CreateClientForm },
  props: {
    role: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (
      !["Manager", "CS_Onboarding_Specialist", "Administrator"].includes(
        this.role
      )
    ) {
      this.$router.replace("/not-authed");
    }
  },
  methods: {
    async submitForm(fdCb) {
      const { valid, formData } = fdCb();
      if (valid) {
        this.loading = true;
        try {
          const response = await ClientService.createClient(formData);
          if (response && response.status == 201) {
            this.loading = false;
            this.$router.push(`/clients/${response.data.Tenant.Id}`);
          }
        } catch (error) {
          this.loading = false;
        }
      }
    },
  },
};
</script>
