<template>
  <div class="subscriptions-table">
    <template v-if="!isLoading">
      <table-lite
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :total="totalRecordCount"
        :is-static-mode="true"
        :sortable="sortable"
        :pageOptions="[
          { value: 25, text: 25 },
          { value: 50, text: 50 },
          { value: 75, text: 75 },
        ]"
        @do-search="$emit('doSearch')"
        @row-clicked="(row) => $emit('subscription-clicked', row)"
        @is-finished="tableLoadingFinish"
        :messages="messages"
        :class="totalRecordCount > 10 ? 'has-pagination' : ''"
      >
      </table-lite>
    </template>
    <subscriptions-table-loading v-else />
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import SubscriptionsTableLoading from "./SubscriptionsTableLoading.vue";
import { formatDate } from "@/util/date";
import SubscriptionService from "@/services/SubscriptionService";
export default {
  components: {
    TableLite,
    SubscriptionsTableLoading,
  },
  props: {
    subscriptions: Array,
    dataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataId: "",
      isLoading: this.dataLoading,
      columns: [
        {
          label: "رقم الاشتراك",
          field: "Number",
          sortable: true,
          isKey: true,
        },
        {
          label: "معرف الاشتراك",
          field: "Id",
          sortable: true,
        },
        {
          label: "إسم الباقة",
          field: "ServicePlanName",
          sortable: true,
        },
        {
          label: "تاريخ الإبتداء",
          field: "StartDate",
          sortable: true,
        },
        {
          label: "تاريخ الإنتهاء",
          field: "EndBillingDate",
          sortable: true,
        },
        {
          label: "الحالة",
          field: "Status",
          sortable: true,
        },
      ],
      rows: [],
      totalRecordCount: 0,
      sortable: {
        order: "Number",
        sort: "asc",
      },
      messages: {
        pagingInfo: "عرض {0}-{1} من {2}",
        pageSizeChangeLabel: "عدد الصفوف:",
        gotoPageLabel: "الذهاب للصفحة:",
        noDataAvailable: "لا يوجد اي بيانات",
      },
    };
  },
  watch: {
    dataLoading(newVal) {
      this.isLoading = newVal;
    },
    subscriptions(newVal) {
      this.setRows(newVal);
    },
  },
  mounted() {
    this.setRows(this.subscriptions);
  },
  methods: {
    setRows(subscriptions) {
      this.rows = subscriptions.map((subscription) => {
        subscription.StartDate = subscription.StartDate
          ? formatDate(subscription.StartDate)
          : "___";
        subscription.EndBillingDate = subscription.EndBillingDate
          ? formatDate(subscription.EndBillingDate)
          : "___";
        subscription.Status =
          typeof subscription.Status == "number"
            ? SubscriptionService.getStatus(subscription.Status, "ar")
            : subscription.Status;
        subscription.ServicePlanName = subscription.ServicePlanName
          ? subscription.ServicePlanName
          : subscription.ServicePlan.Name.ArabicValue;
        return subscription;
      });
      this.totalRecordCount = subscriptions.length;
      this.isLoading = false;
    },
    tableLoadingFinish(elements) {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
</style>